import React, { useState, useEffect } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select, TimePicker } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import apiCalls from "../../api/apiCalls";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {  useParams } from 'react-router-dom';

const CustomerInterationTab = (props) => {

    const [form] = Form.useForm();  // Initialize the form instance
    const emailExist = false;
    const dateFormat = "DD/MM/YYYY";
    const [isLoading, setIsLoading] = useState(false);
    const loginInfo = useSelector(state => state);
    let { serviceId } = useParams();
    const [srvReqID, setSrvReqID] = useState(0);
    const [previousInteractionDetails, setPreviousInteractionDetails] = useState([]);
    
    const DispositionLU = [
        { label: 'Contacted', value: 'contacted' },
        { label: 'Not Contacted', value: 'notContacted' },
        { label: 'Wrong No', value: 'wrongNo' },
        { label: 'Switched Off', value: 'switchedOff' },
        { label: 'Not Reachable', value: 'notReachable' },
    ];


    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const featuredatedisabled = (current) => {
        return current && current < dayjs().startOf("day");
    };

    const handleDate = () => {}

    const validatePhoneNumber = (_, value) => {
        if (emailExist) {
          return Promise.reject("Mobile number already exists");
        } else if (value && !/^[6-9]\d{9}$/.test(value)) {
          return Promise.reject("Mobile number should start with 6,7,8 or 9 and must be 10 digits");
        } else if (
          value &&
          !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value)
        ) {
          return Promise.reject("Invalid mobile number");
        } else {
          return Promise.resolve();
        }
    };

    useEffect(() => {
      //getPOSIndividualData()
    }, []);

    const getPOSIndividualData = async () => {
      try {
        setIsLoading(true);
    
        const val = await apiCalls.getPOSIndividualData(serviceId);
        const srvReqIDFromData = val?.data?.srvReqID; // Extract srvReqID from val.data
    
        // Update state and form values with srvReqID
        setSrvReqID(srvReqIDFromData);
       // form.setFieldsValue({ srvReqID: srvReqIDFromData });
       //setPreviousInteractionDetails(val);
       setFormFieldsData(val);
      }
      catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors appropriately
      } finally {
        setIsLoading(false);
      }
    };

    const setFormFieldsData = (val) => {
      if (val?.data) {
        const fieldMapping = {
          'Comp_Call_Time': 'callTime',
          'Comp_Contact_Number': 'contactNumber',
          'Comp_Disposition': 'disposition',
          'Comp_Interaction_Details': 'description',
          'Comp_Next_Interaction_Date': 'nextInteractionDate',
        };
    
        const formValues = {};
        const previousInteractions = [];
    
        val?.data?.serviceRequestTransectionData?.forEach((ele) => {
          const fieldName = fieldMapping[ele.tagName];
          if (fieldName) {
            formValues[fieldName] = ele.tagValue;
          }
        });
    
        form.setFieldsValue(formValues);
    
        // Populate previous interactions
        // Assuming 'previousInteractionDetails' should be populated from the response
        // This assumes your API response has a field like `previousInteractions` which needs to be set
        setPreviousInteractionDetails(val?.data?.previousInteractions || []);
      }
    };
    

    const handleKeyDown = (pattern, e) => {
        const key = e.key;
        let specialCharacterRegex = '';

        if (pattern === 'numbersOnly') {
            const inputValue = e.target.value;
            if (inputValue.includes('.')) {
                specialCharacterRegex = /^[0-9]$/; 
            } else {
                specialCharacterRegex = /^[0-9.]$/;
            }
        } else if (pattern === 'charactersOnly') {
            specialCharacterRegex = /^[a-zA-Z0-9]$/;
        } else if (pattern === 'alphabatesOnly') {
            specialCharacterRegex = /^[a-zA-Z]$/;
        } else if (pattern === "decimalOnly") {
            const inputValue = e.target.value;
            if (inputValue.includes('.')) {
                specialCharacterRegex = /^[0-9]$/; 
            } else {
                specialCharacterRegex = /^[0-9.]$/;
            }
        }

        if (key === 'Backspace' || key.startsWith('Arrow')) {
            return;
        }

        if (!specialCharacterRegex.test(key)) {
            e.preventDefault();
        }
    };

    const getTransactionData = (values) => {
        return [
            {
                "Status": "Create",
                "TagName": "Comp_Call_Time",
                "TagValue": values.callTime
            },
            {
                "Status": "Create",
                "TagName": "Comp_Contact_Number",
                "TagValue": values.contactNumber
            },
            {
                "Status": "Create",
                "TagName": "Comp_Disposition",
                "TagValue": values.disposition
            },
            {
                "Status": "Create",
                "TagName": "Comp_Interaction_Details",
                "TagValue": values.description
            },
            {
                "Status": "Create",
                "TagName": "Comp_Next_Interaction_Date",
                "TagValue": values.nextInteractionDate
            }
        ];
    };

    useEffect(()=>{
        getClientEnquiry()
      },[])
      
      const getClientEnquiry = ()=>{
        setIsLoading(true);
            let obj = {
              clientNumber:  props?.data?.identifiers?.po_ClientID
            
        };
        let response = apiCalls.getClientEnquiry(obj,loginInfo?.userProfileInfo?.profileObj?.allRoles[0]?.employeeID);
        response
          .then((val) => {
            if (val?.data) {
              const res = val?.data?.responseBody;   
            form.setFieldsValue({
              'contactNumber': res?.rmblphone,
              'whatsAppNo':  res?.rmblphone,
              'emailId': res?.rinternet
            });
      
              setIsLoading(false);
            } else {
              setIsLoading(false);
            //   message.error({
            //     content:
            //       val?.data?.responseBody?.errormessage ||
            //       "Something went wrong please try again!",
            //     className: "custom-msg",
            //     duration: 2,
            //   });
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }


    const handleMiscSave = () => {
        form.validateFields().then((values) => {
            const formData = form.getFieldsValue();
            const obj = {
                SrvReqRefNo: serviceId,
                SrvReqID : srvReqID,
                TransactionData: getTransactionData(formData) || [],
                ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
                RequestDateTime: "2024-01-10T00:00:00",
                CustSignDateTime: "2024-01-10T00:00:00"
            };

            apiCalls.genericAPI(obj).then((val) => {
                if (val?.data?.srvReqRefNo) {
                    // Handle successful response
                    setFormFieldsData(val)
                }
            });
        }).catch((error) => {
            console.log("Validation failed:", error);
        });
    };

  

    return (
        <Form form={form} className="customer-interaction-form" layout="vertical">
            <Row gutter={[16, 16]} className='mb-16'>
                <Col xs={24} sm={24} md={9} lg={9} xxl={9} className='mb-16'>
                    <Form.Item
                        label="Call Time"
                        name="callTime"
                        className="inputs-label mb-0"
                        rules={[
                            {
                                required: true,
                                message: "Select Call Time",
                            },
                        ]}
                    >
                        <TimePicker use12Hours format="h:mm: A" className="cust-input" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xxl={9} className='mb-16'>
                    <Form.Item
                        label="Contact Number"
                        name="contactNumber"
                        className="inputs-label mb-0"
                        rules={[
                            {
                                required: true,
                                message: "Enter Contact Number",
                            },
                            {
                                validator: validatePhoneNumber,
                            },
                        ]}
                    >
                        <Input
                            placeholder="Contact Number"
                            className="cust-input"
                            maxLength="10"
                            minLength="10"
                            onKeyDown={(e) => handleKeyDown("numbersOnly", e)}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xxl={9} className='mb-16'>
                    <Form.Item
                        label="Disposition"
                        name="disposition"
                        className="inputs-label mb-0"
                    >
                        <Select
                            className="cust-input calltype-select"
                            placeholder="Select Disposition"
                            options={DispositionLU}
                            filterOption={filterOption}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={23} lg={23} xxl={23} className='mb-16'>
                    <Form.Item
                        label="Interaction Details"
                        name="description"
                        className="inputs-label mb-0"
                        rules={[
                            {
                                required: true,
                                message: "Enter Description",
                            },
                        ]}
                    >
                        <TextArea
                            rows={4}
                            maxLength={1000}
                            placeholder="Interaction Details"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xxl={9} className='mb-16'>
                    <Form.Item
                        label={"Next Interaction Date*"}
                        name="nextInteractionDate"
                        className="inputs-label mb-0"
                        rules={[
                            {
                                required: true,
                                message: "Select Date",
                            },
                        ]}
                    >
                        <DatePicker
                            allowClear={false}
                            style={{ width: "100%" }}
                            className="cust-input"
                            format={dateFormat}
                            handleDate={handleDate}
                            disabledDate={featuredatedisabled}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={24} lg={24} xxl={24}>
                    <div className="contact-details-btn">
                        <Button
                            type="primary"
                            className="primary-btn"
                            htmlType="submit"
                            onClick={handleMiscSave}
                        >
                            Save
                        </Button>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={23} lg={23} xxl={23}>
                    <div className="reuirement">
                        <table className="responsive-table">
                            <thead>
                                <tr>
                                    <th>Call Time</th>
                                    <th>Disposition</th>
                                    <th>Contact No</th>
                                    <th>Interaction Details</th>
                                    <th>Next Interaction Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {previousInteractionDetails?.map((item, ind) => (
                                    <tr key={ind + 1}>
                                        <td>{item.callTime}</td>
                                        <td>{item.Disposition}</td>
                                        <td>{item.contactNo}</td>
                                        <td>{item.interactionDetails}</td>
                                        <td>{item.nextInteractionDate}</td>
                                    </tr>
                                ))}
                                {previousInteractionDetails?.length === 0 && (
                                    <tr>
                                        <td colSpan="5">
                                            <div className="text-center">
                                                <span>No data available</span>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

export default CustomerInterationTab;