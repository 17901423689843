import React, { useState, useEffect, useRef } from "react";
import Assistance from "../../assets/images/handshake.png";
import { Collapse, Spin, Tooltip, Drawer, Progress, message, Modal, Button, Checkbox,Space, Table } from "antd";
import moment from 'moment';
import apiCalls from "../../api/apiCalls";
import NumberFormat from "react-number-format";

import { policyDetailsObj } from "../../reducers/policyDetailsReducer";
import { useLocation } from "react-router-dom";
import CloseIcon from "../../assets/images/close-icon.png";
import TableFormat from "../../utils/TableFormat";
import { connect,useSelector } from "react-redux";

import {
  LAST5_OPEN_TICKETS,
  PAYMENT_TRANSACTIONS,
  PAYMENT_TRANSACTIONS_DATA,
} from "../../config";
import TicketsOpenPopup from "../TicketsOpenPopup";

const Widgets = (props) => {
    const { Panel } = Collapse;
    const loggedUser = useSelector(state => state?.userProfileInfo?.profileObj);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const { state } = useLocation();
    const [allDetailsOpen, setAllDetailsOpen] = useState(false);
    const [showLastPayments, setShowLastPayments] = useState(false);
    const [showOpenTickets, setShowOpenTickets] = useState(false);
    const [faqOpen, setFaqOpen] = useState(false);
    const [ticketsData, setTicketsData] = useState([]);
    const [CALL_TyPES, setCALL_TyPES] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [requirementModalLoader, setRequirementLoader] = useState(false);
    const [requestModeLU, setRequestModeLU] = useState([]);
    const [clientRoleLU, setClientRoleLU] = useState([]);
    const [ticketsLoader, setTicketsLoader] = useState(false);
    const [bankAccTypeLU, setBankAccTypeLU] = useState([]);
    const [requestReceivedViaLU, setRequestReceivedViaLU] = useState([]);
    const [premiumStatus, setPremiumStatus] = useState("");
    const [policyStatus, setPolicyStatus] = useState("");
    const [cursorPortalLU, setCursorPortalLU] = useState([]);
    const [websitePortalLU, setWebsitePortalLU] = useState([]);
    const [callRelatedActionLU, setCallRelatedActionLU] = useState([]);
    const [customerQueryLU, setCustomerQueryLU] = useState([]);
    const [panUpdateLU, setPanUpdateLU] = useState([]);
    const [processNameLU, setProcessNameLU] = useState([]);
    const [BirthdayIcon, setBirthdayIcon] = useState(false);
    const [sisLU, setSISLU] = useState([]);
    const [isVerifiedCheck, setIsVerifiedCheck] = useState(false);
    const isCheckedButton = state && state.isAdvanceVerifiedCheck;
    const [details,setDetails]=useState([]);
    const [assistance, setAssistance] = useState([]);
    const [showTotalPages,setShowTotalpages] = useState(null);
  
    const [assistanceOpen, setAssistanceOpen] = useState(false);
    const [CallTypeId, setCallTypeId] = useState("");
    const [SubTypeId, setSubTypeId] = useState("");
    const [isShowAllTicketsData, setIsShowAllTicketsData] = useState(false);
    const [isSelectedTicketsData, setIsSelectedTicketsData] = useState({});
    const [isTicketsPOSObj, setIsTicketsPOSObj] = useState({});
    const [isChecked, SetIsChecked] = useState(false);
    const [checkedValues, setCheckedValues] = useState([]);
    const [isOpenTicket,setIsOpenTicket]=useState(false);
    const [serviceId,setServiceId]=useState('');
    const [isTicketsData,setIsTicketsData]=useState([]);
    const [Verifiedpopup, setVerifiedPopup] = useState(false);
    const [ShowUplodedDocs, setShowUplodedDocs] = useState(false);
    const [SelectedSubTypeVal, setSelectedSubTypeVall]=useState('');
  
    const [Address, setAddress] = useState('');
    const [Relation, setRelation] = useState('');
    const [PosData, setPosData]= useState('');
    const [ClientName, setClientName] = useState('');
    const [isPayoutModal,setIsPayoutModal] = useState(false);
    const [payoutModalData,setpayoutModalData] = useState([]);
    const [isPayoutModalLoading, setIsPayoutModalLoading] = useState(false);

    const relationship = {
        'AP':'Appointee',
        'AU':'Aunt  ',
        'BR':'Brother    ',
        'BU':'Business   ',
        'CH':'Contingent Policy Holder   ',
        'DA':'Daughter    ',
        'EX':'Executor   ',
        'FA':'Father      ',
        'GD':'Granddaughter            ',
        'GF':'Grandfather',
        'GM':'Grandmother',
        'GS':'Grandson',
        'HU':'Husband',
        'MO':'Mother',
        'NE':'Nephew',
        'NI':'Niece',
        'O':'Other',
        'OT':'Other',
        'SI':'Sister',
        'SO':'Son',
        'TR':'Trustee ',
        'UL':'Uncle',
        'WI':'Wife',
      }

    //   const defaultColumns = [
    //     {
    //         title: "Ticket No",
    //         dataIndex: "serviceNo",
    //         key: 'serviceNo',
    //       },
    //     {
    //       title: "Ticket No",
    //       dataIndex: "serviceNo",
    //       key: 'serviceNo',
    //     },
    //     {
    //       title: "Call Log Date",
    //       dataIndex: "date",
    //       showSorterTooltip: false,
    //       sorter: {
    //         compare: (a, b) => moment.utc(a.date).diff(moment.utc(b.date)),
    //       },
    //      render: (_, record) => (
    //       <Space size="middle">
    //       { moment.utc(record.date).local().format("DD/MM/YYYY hh:mm A")}
    //       </Space>
    //     ),
    //     },
    //     {
    //       title: "Policy Number",
    //       dataIndex: "policyNo",
    //       key: 'policyNo',
    //       showSorterTooltip: false,
    //       sorter: {
    //         compare: (a, b) => a.policyNo - b.policyNo,
    //       },
    //     },
    //     {
    //       title: "Call Type",
    //       dataIndex: "callTypeName",
    //       key: 'callTypeName',
    //     },
    //     {
    //       title: "Sub Type",
    //       dataIndex: "subTypeName",
    //       key: 'subTypeName',
    //     },
    //     {
    //       title: "Status",
    //       dataIndex: "status",
    //       key: 'status',
    //     },
    //     // {
    //     //   title: "Mode",
    //     //   dataIndex: "",
    //     //   key: '',
    //     // },
    //     {
    //       title: "Customer Name",
    //       dataIndex: "proposerName",
    //       key: 'proposerName',
    //     },
    //     {
    //       title: "Ageing",
    //       dataIndex: "ageing",
    //       key: 'ageing',
    //     },
    //     // {
    //     //   title: "Logged by",
    //     //   dataIndex: "createdByRef",
    //     //   key: 'createdByRef',
    //     // }
    //   ];

    //   const columns = defaultColumns?.map((col) => {
    //     if (!col.editable) {
    //       return col;
    //     }
    //     return {
    //       ...col,
    //       onCell: (record) => ({
    //         record,
    //         editable: col.editable,
    //         dataIndex: col.dataIndex,
    //         title: col.title,
    //         //handleSave,
    //       }),
    //     };
    //   });

    const getAssistanceDetails = () => {
        if (!props?.selectedCallTypeId || !props?.selectedSubTypeId) {
          return
        }
        setIsLoading(true);
        let response = apiCalls.getAssistanceDetails(props?.selectedCallTypeId, props?.selectedSubTypeId,loggedUser?.role);
        response
          .then((val) => {
            setAssistance(val.data)
            setIsLoading(false);
            setAssistanceOpen(true)
          })
          .catch((err) => {
            setIsLoading(false);
            message.destroy()
            message.error({
              content: response?.data?.responseBody?.errormessage || "Something went wrong please try again!",
              className: "custom-msg",
              duration: 2,
            });
          });
      };

      const convertDate = (inputDate) => {
        const formattedDate = moment(inputDate, 'YYYYMMDD').format('DD/MM/YYYY');
        return formattedDate;
      };
      const Assistancee = () => {
        getAssistanceDetails();
      }
      const getPayoutDetailsEnquiry = () => {
        // if (!props?.selectedCallTypeId || !props?.selectedSubTypeId) {
        //     return
        //   }
        let modalData =[]
        setIsPayoutModal(true);
        setIsPayoutModalLoading(true);
        let response = apiCalls.getPayoutDetailsEnquiry(state?.policyNo || props?.store?.policyDetails?.policyDetailsObj?.identifiers?.policyNo);
        response.then((val) => {
          if(val?.data?.responseBody?.errorcode=="0"){
            modalData.push(val?.data?.responseBody);
            setpayoutModalData(modalData);
            setIsPayoutModalLoading(false);
          }
          else {
            setIsPayoutModalLoading(false);
            message.destroy();
            message.error({
              content: response?.data?.responseBody?.errormessage || "Something went wrong please try again!",
              className: "custom-msg",
              duration: 2,
            });
          }
          })
          .catch((err) => {
            setIsPayoutModalLoading(false);
            message.destroy();
            message.error({
              content: response?.data?.responseBody?.errormessage || "Something went wrong please try again!",
              className: "custom-msg",
              duration: 2,
            });
          });
      };

    const handleAllDetails = () => {
        setAllDetailsOpen(true);
      };
    
      const dms = ()=>{
        const url =  process.env.REACT_APP_DMS_API_URL + `/omnidocs/WebApiRequestRedirection?Application=BPMPOLENQ&cabinetName=FG&sessionIndexSet=false&DataClassName=Future_Generali&DC.Application_no=${props?.store?.policyDetails?.policyDetailsObj?.identifiers?.applicationNo}`;
      window.open(url, '_blank');
      }
      const handleLastPayments = () => {
        setShowLastPayments(true);
        setShowOpenTickets(false);
      };
      const handleOpenTickets = () => {
        setShowOpenTickets(true);
        setShowLastPayments(false);
        getLastOpenTicketsData();
        setIsShowAllTicketsData(false);
        setIsTicketsPOSObj({});
        // let url = "/policydetails";
        // window.open(url, '_blank');
      };
      const onClose = () => {
        setAllDetailsOpen(false);
        setFaqOpen(false);
      };
      const handlePaymentsCLose = () => {
        setShowLastPayments(false);
      };
      const handleOpenTicketsCLose = () => {
        setShowOpenTickets(false);
        setIsShowAllTicketsData(false);
        setIsTicketsPOSObj({});
        setIsSelectedTicketsData({});
      };
      const getLastOpenTicketsData = () => {
        //setTicketsLoader(true);
        setIsLoading(true);
        let response = apiCalls.getLastOpenTickets(state?.policyNo || props?.store?.policyDetails?.policyDetailsObj?.identifiers?.policyNo);
        response
          .then((val) => {
            const updatedDataArray = val?.data.map(item => {
              if (item.status === "REJECTED") {
                return { ...item, status: "CLOSED WITH REQUIREMENTS" };
              } else if (item.status === "PENDING") {
                return { ...item, closedDate: "-" };
              }
              return item;
            });
            setTicketsData(updatedDataArray);
            // setTicketsLoader(false);
            setIsLoading(false);
          })
          .catch((err) => {
            // setTicketsLoader(false);
            setIsLoading(false);
            message.destroy()
            message.error({
              content: response?.data?.responseBody?.errormessage || "Something went wrong please try again!",
              className: "custom-msg",
              duration: 2,
            });
          });
      };
    
      const getDetails=async(serialNum)=>{
        setIsLoading(true);
        const  val = await apiCalls.getTicketDetails(serialNum);
        if(val?.data){
          setIsLoading(false);
          setDetails(val?.data)
          
        }else{
          message.destroy();
          message.error({
            content: val?.data?.responseHeader?.message||"Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
      }
     const getPOSIndividualData=async(data)=>{
    
       setIsLoading(true);
       const  val = await apiCalls.getPOSIndividualData(data);
       if(val?.data){
         setIsLoading(false);
         setDetails(val?.data)
    
       }else{
         message.destroy();
         message.error({
           content: val?.data?.responseHeader?.message||"Something went wrong please try again!",
           className: "custom-msg",
           duration: 2,
         });
       }
     }
    
      const handleTickectNoLink = (slectedRow) => {   
        setCallTypeId(slectedRow?.callType);
        setSubTypeId(slectedRow?.subType);
        // setIsShowAllTicketsData(true);
        setIsOpenTicket(true);
        setServiceId(slectedRow?.serviceNo)
        getPOSIndividualData(slectedRow?.serviceNo);
        setIsTicketsData(slectedRow);
        setIsLoading(true)
        setTimeout(()=>{
          getDetails(slectedRow?.serviceNo)
        },1000)
        setIsLoading(false)
        // setShowOpenTickets(false);
        // setIsSelectedTicketsData(slectedRow);
        // setIsTicketsPOSObj({
        //   serialNo: slectedRow?.serviceNo, isPOS: true, policyNo: state?.policyNo || props?.store?.searchPolicyData?.policyNo,
        //   dob: state?.dob ? state?.dob.includes('/') ? state?.dob : convertDate(state?.dob) :
        //     props?.store?.searchPolicyData?.dob ? props?.store?.searchPolicyData?.dob.includes('/') ? props?.store?.searchPolicyData?.dob : convertDate(props?.store?.searchPolicyData?.dob) : null
        // })
      }
      const handleClose=()=>{
        setIsOpenTicket(false);
      }
      const handleTicketSubmit=()=>{
        setIsOpenTicket(false);
      }
    return (
        <>
            <div className="widgets-start">
                <aside className="fixed-nav active">
                    <div className="widgets-1">
                        <div className="item">
                            <Tooltip
                                placement="leftTop"
                                title="Click here for Transection Documents"
                            >
                                <a id="pay-premium" onClick={() => setShowUplodedDocs(true)}>
                                <i className="bi bi-file-text"></i>
                                <p>Transaction Documents</p>
                                </a>
                            </Tooltip>
                        </div>

                        <div className="item">
                            <Tooltip
                                placement="leftTop"
                                title="Click here for FAQ Details"
                            >
                                <a id="pay-premium" onClick={() => Assistancee()}>
                                <img src={Assistance} alt="" className="m-auto"></img>
                                <p>Assistance</p>
                                </a>
                            </Tooltip>
                        </div>
                        <div className="item">
                            <Tooltip
                                placement="leftTop"
                                title="Click here for Payments"
                            >
                                <a id="pay-premium" onClick={() => handleLastPayments()}>
                                <i className="bi bi-currency-rupee"></i>
                                <p>Renewal Payments</p>
                                </a>
                            </Tooltip>
                        </div>
                        <div className="item">
                            <Tooltip
                                placement="leftTop"
                                title="Click here for Open Tickets"
                            >
                                <a id="pay-premium">
                                <i className="bi bi-ticket" onClick={() => handleOpenTickets()}></i>
                                <p>Tickets</p>
                                </a>
                            </Tooltip>
                        </div>
                    </div>

                    <div className="widgets">
                        <div className="item">
                            {/* <Tooltip placement="leftTop" title="Click here for DMS"> */}
                            <a id="pay-premium"  onClick={() => dms()}>
                            <i className="bi bi-file-text"></i>
                            <p>DMS</p>
                            </a>
                            {/* </Tooltip> */}
                        </div>
                        {/* } */}

                        <div className="item">
                            <Tooltip
                                placement="leftTop"
                                title="Click here for Payments"
                            >
                                <a id="pay-premium" title="Click here for Payouts" onClick={() => getPayoutDetailsEnquiry()}>
                                    <i className="bi bi-currency-rupee"></i>
                                    <p>Payout</p>
                                </a>
                            </Tooltip>
                        </div>

                        <div className="item">
                    <Tooltip placement="leftTop" title="Click here for Profile">
                      <a id="pay-premium" onClick={() => handleAllDetails()}>
                        <Progress type="circle" size={35} percent={75} />
                        <p>Profile</p>
                      </a>
                    </Tooltip>
                  </div>

                        <div className="item">
                            <Tooltip
                                placement="leftTop"
                                title="Click here for Payments"
                            >
                                <a id="pay-premium">
                                    <i className="bi bi-currency-rupee"></i>
                                    <p>xxxx</p>
                                </a>
                            </Tooltip>
                        </div>

                    </div>
                </aside>
            </div>

            <Drawer
        title="Profile"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={allDetailsOpen}
        maskClosable={false}
        closeIcon={
          <Tooltip title="Close">
            <img src={CloseIcon} alt="" />
          </Tooltip>
        }
      >
        <div>
          <table className="table table-bodered Profile">

            <tbody>
              <tr>
                <td>NPS Score</td>
                <td>5</td>
              </tr>
              <tr>
                <td>Customer Sentiment</td>
                <td></td>
              </tr>


              <tr>
                <td colSpan={2} className="text-center"> <b>Near Future Event </b></td>

              </tr>
              <tr>
                <td>PO DOB</td>
                <td>	23-01-2024</td>
              </tr>
              <tr>
                <td>Renewal Due Date</td>
                <td>	23-01-2024</td>
              </tr>

              <tr>
                <td>Policy payout</td>
                <td>	Survival Benefit</td>
              </tr>
              <tr>
                <td>Loan (If active)</td>
                <td>No</td>
              </tr>

              <tr>
                <td colSpan={2} className="text-center"> <b> Customer Profile</b></td>

              </tr>

              <tr>
                <td>CKYC Status</td>
                <td>Registered</td>
              </tr>

              <tr>
                <td>PAN</td>
                <td>AAJOJ8888k</td>
              </tr>





              <tr>
                <td>Aadhar Seeding Status/Date</td>
                <td>	23-01-2024</td>
              </tr>

              <tr>
                <td>Language/Mode</td>
                <td>Marathi/Email</td>
              </tr>


              <tr>
                <td>Contact details - Mobile</td>
                <td>9892686867</td>
              </tr>


              <tr>
                <td>Contact details - Email</td>
                <td>process.env.REACT_APP_ABCMAIL</td>
              </tr>
              <tr>
                <td>Update bank account</td>
                <td>IMPS Pass/No Bank Account</td>
              </tr>

              <tr>
                <td>Update Auto pay</td>
                <td>No</td>
              </tr>

              <tr>
                <td>Registered on Portal</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Registered on mobile app</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Language/Mode</td>
                <td>Marathi/Email</td>
              </tr>
            </tbody>
          </table>
        </div>

      </Drawer>
      <Drawer
        title="FAQ"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={faqOpen}
        maskClosable={false}
        closeIcon={
          <Tooltip title="Close">
            <img src={CloseIcon} alt="" />
          </Tooltip>
        }
      >
        <div>
          <h1>Payment Link</h1>
          <p>
            <ul>
              <li>
                Now you can choose to send customers payment link over
                registered email/SMS/whatsapp.
              </li>
              <li>
                SR will be raised and Auto closed. No further action is required
                from your side for this SR.
              </li>
            </ul>
          </p>
        </div>
      </Drawer>



      <Modal
        title="Assistance"
        open={assistanceOpen}
        destroyOnClose={true}
        width={1200}
        closeIcon={false}
        footer={null}
      >

        <div>

          <div className="reuirement">
            <table className="table responsive-table assistanceTable">

              <tbody>
                {assistance?.length > 0 &&
                  assistance?.map((item, ind) => (
                    <tr key={ind + 1}>
                       <td>{ind+1}</td>
                      <td>{item.assistanceDesc}</td>

                    </tr>
                  ))}
                {assistance?.length === 0 &&
                  <tr>
                    <td>

                      <div className="text-center"><span>No Data Available</span></div>
                    </td>
                  </tr>}
              </tbody>
            </table>
          </div>

          <div className="contact-details-btn">


            <Button
              type="primary"
              className="primary-btn"
              onClick={() => setAssistanceOpen(false)}
            >
              Close
            </Button>
          </div>

        </div>

      </Modal>


      


      <Modal
       
        open={Verifiedpopup}
        destroyOnClose={true}
        width={600}
        closeIcon={false}
        footer={null}
      >

        <div>

          <div className="reuirement">
            <table className="table responsive-table assistanceTable">

              <tbody>
              <tr>
                <td>DOB</td>
                <td> <b>{convertDate(props?.store?.policyDetails?.policyDetailsObj?.identifiers?.dob) || "-"}</b></td>
              </tr>
              <tr>
                <td>Address </td>
                <td><b> {Address}</b></td>
              </tr>
              <tr>
                <td>Premium Amount</td>
                <td> <b>
                                {(data?.premiumDetails?.modelPremiumAmount && (
                                  <NumberFormat
                                    value={
                                      data?.premiumDetails?.modelPremiumAmount
                                    }
                                    decimalSeparator="."
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                  />
                                )) ||
                                  "-"}
                              </b></td>
              </tr>
              <tr>
                <td>Sum Assured</td>
                <td>      <b>
                                {(data?.saDetails?.sumAssured && (
                                  <NumberFormat
                                    value={data?.saDetails?.sumAssured}
                                    decimalSeparator="."
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                  />
                                )) ||
                                  "-"}
                              </b></td>
              </tr>
              <tr>
                <td>Nominee Name and Relationship</td>
                <td> <b> {ClientName ? ClientName : ''} {ClientName ? '&' : ''} {Relation ? relationship[Relation] : ''}</b></td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className="contact-details-btn">


            <Button
              type="primary"
              className="primary-btn"
              onClick={() => setVerifiedPopup(false)}
            >
              OK
            </Button>
          </div>

        </div>

      </Modal>


      <Modal
       
       open={ShowUplodedDocs}
       destroyOnClose={true}
       width={1200}
       closeIcon={false}
       footer={null}
     >

       <div>

         <div className="reuirement">
           <table className="table responsive-table assistanceTable">
          <thead>
          <th>Document</th>
            <th>Uploaded On</th>
            <th>Uploaded By</th>
          </thead>
           <tbody>


                {PosData?.dmsLink?.length > 0 &&
                  PosData?.dmsLink?.map((item, ind) => (
                    <tr key={ind + 1}>
                       {/* <td>{ind+1}</td> */}
                      <td>{item?.labelName}</td>
                      <td><a className="hyperLink" target="_blank"  href={process.env.REACT_APP_Image_Path+item?.fileLocation+item?.documentName}>
                      {item.indexName}
                        </a>  </td>
                        <td> {new Date(item.uploadedOn).toLocaleDateString()}</td>
                        <td> {item.uploadedBy}</td>

                    </tr>
                  ))}
                {PosData?.dmsLink?.length === 0 &&
                  <tr>
                    <td>

                      <div className="text-center"><span>No Data Available</span></div>
                    </td>
                  </tr>}
              </tbody>
           </table>
         </div>

         <div className="contact-details-btn">


           <Button
             type="primary"
             className="primary-btn"
             onClick={() => setShowUplodedDocs(false)}
           >
             OK
           </Button>
         </div>

       </div>

     </Modal>

     <Modal
        title="Payout"
        open={isPayoutModal}
        destroyOnClose={true}
        width={1200}
        closeIcon={
          <Tooltip title="Close">
            <img src={CloseIcon} alt="" onClick={() => setIsPayoutModal(false)}/>
          </Tooltip>
        }
        footer={null}
      >

<div>
  <div className="reuirement">
  {/* {isPayoutModalLoading ? <>
        <Spin />
        </> : */}
    <table className="table responsive-table assistanceTable">
      <thead>
        <tr>
          <th>Ticket No</th>
          <th>Call Type</th>
          <th>Sub Type</th>
          <th>Status</th>
          <th>Payment Date</th>
          <th>Payment Amount</th>
          <th>Payment Mode</th>
          <th>Cheque/UTR No</th>
        </tr>
      </thead>
      <tbody>
         {payoutModalData?.length > 0 ? (
          payoutModalData.map((ele, index) => (
            <tr key={index + 1}>
              <td>{ele.reqnno}</td>
              <td>{ele.callType}</td>
              <td>{ele.subType}</td>
              <td>{ele.status}</td>
              <td>{ele.paydate}</td>
              <td>{ele.payamt}</td>
              <td>{ele.paymentMode}</td>
              <td>{ele.cheqno}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="8">
              <div className="text-center"><span>No Data Available</span></div>
            </td>
          </tr>
        )}
       
      </tbody>
    </table>
        {/* } */}
  </div>
</div>


      </Modal>

      <Modal
        title="Payment Transactions"
        open={showLastPayments}
        destroyOnClose={true}
        width={1200}
        closeIcon={
          <Tooltip title="Close">
            <img src={CloseIcon} alt="" onClick={() => handlePaymentsCLose(false)}/>
          </Tooltip>
        }
        footer={null}
      >
                    <div>
                      <TableFormat
                        headerData={PAYMENT_TRANSACTIONS}
                        data={PAYMENT_TRANSACTIONS_DATA}
                        ticketsLoader={ticketsLoader}
                      ></TableFormat>
                    </div>
            
            </Modal>


            <Modal
        title="All Tickets Details"
        open={showOpenTickets}
        destroyOnClose={true}
        width={1200}
        closeIcon={
          <Tooltip title="Close">
            <img src={CloseIcon} alt="" onClick={() => setShowOpenTickets(false)}/>
          </Tooltip>
        }
        footer={null}
      >
                    <div>
                      <TableFormat
                        headerData={LAST5_OPEN_TICKETS || []}
                        data={ticketsData}
                        ticketsLoader={ticketsLoader}
                        handleTickectNoLink={handleTickectNoLink}
                      ></TableFormat>
                    </div>
                     {/* <Table
              columns={columns}
              dataSource={ticketsData}
              locale={{
                emptyText: 'No Data Available',
              }}
              //bordered={true}
              x={true}
              pagination={{
                //pageSizeOptions: ["5", "10", "15", "15"],
                pageSize: 10,
                //showSizeChanger: true,
                defaultPageSize: 5,
                // size:"small",
                total: showTotalPages,
                //showTotal: `Total ${showTotalPages} items`
              }}
            /> */}
                
            </Modal>

      {
        isOpenTicket && <TicketsOpenPopup isOpenTicket={isOpenTicket} serviceId={serviceId} handleClose={handleClose} details={details} handleTicketSubmit={handleTicketSubmit} ticketsData={isTicketsData} isLoading={isLoading}/>
      }
        </>
    )
}

export default Widgets;