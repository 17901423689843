import React, { useState,useEffect,  } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form,Select,Row,Col, Table,Spin, message,  DatePicker , Button, Space,Modal,Upload } from 'antd';
import apiCalls from "../../api/apiCalls";
import moment from 'moment';
import ExportToExcelButton from '../ExportToExcelButton';
import { UploadOutlined } from '@ant-design/icons';
import PopupAlert from '../popupAlert';

const FinanceDashboard = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [isLoading,setIsLoading] = useState(false);
  const [data,setData] = useState([]);
  const [dashboardData,setDashboardData] = useState([]);
  const [showTotalPages,setShowTotalpages] = useState(null);
  const [tableKey, setTableKey] = useState(0); // Key to force remount
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  //excell upload code start
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isExcelUploadModal, setIsExcelUploadModal] = useState(false);

  const [trigger,setTrigger] = useState([
    {
      value: "T-10",
      label: "T-10",
    },
    {
      value: "T-30",
      label: "T-30",
    },
    {
      value: "T-90",
      label: "T-90",
    },
    {
      value: "T-15",
      label: "T-15",
    },
    
  ]);
  const [commuType,setCommuType] = useState([
    {
      value: "email",
      label: "Email",
    },
    {
      value: "phone",
      label: "Phone",
    }
    
  ]);
  const [uploadType,setUploadType] = useState([
    {
      value: "PayeeCodeTransection",
      label: "Payment Transaction",
    },
     {
      value: "ChequeStatus",
      label: "Check Status",
    },
    {
      value: "MaturityDetails",
      label: "Maturity Details",
    },

  ])

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file);
    });
    setUploading(true);
    // You can use any AJAX library you like
    fetch('', {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        setFileList([]);
        message.success('upload successfully.');
      })
      .catch(() => {
        message.error('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const props2 = {
    onRemove: (file) => {
      const index = fileList2.indexOf(file);
      const newFileList = fileList2.slice();
      newFileList.splice(index, 1);
      setFileList2(newFileList);
    },
    beforeUpload: (file) => {
      setFileList2([file]);
     // setFileList2([...fileList2, file]);
      return false;
    },
    multiple: false,
    fileList2,
  };

  const propss = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      //setFileList([...fileList, file]);
      setFileList([file]);
      return false;
    },
    multiple: false,
    fileList,
  };
  const handleExcelSubmit = (values) => {
  setShowAlert(false)
    let formData = new FormData();
    formData.append("File", fileList[0]);
    let obj = {
      reqtype: values?.uploadType
    }
    let response = apiCalls.UploadExcelFileAPI(formData, obj);
    response
      .then((val) => {

        setAlertTitle('Uploaded Successfully');

        setShowAlert(true);
      })
      .catch((err) => {
        setAlertTitle('Failed to Upload');

        setShowAlert(true);
        setIsLoading(false);
      });
  }
  
  // const handleSubmit2 = (values) => {
  //   setShowAlert(false);
  //   let formData = new FormData();
  //   formData.append("File", fileList2[0]);
  //   let obj = {
  //     reqtype:values?.uploadType

  //   }
  //   let response = apiCalls.UploadExcelFileAPI(formData, obj);
  //   response
  //     .then((val) => {
  //       setAlertTitle('Uploaded Successfully');

  //       setShowAlert(true);
  //     })
  //     .catch((err) => {
  //       setAlertTitle('Failed to Upload');

  //       setShowAlert(true);
  //       setIsLoading(false);
  //     });
  //   }

    //Excell upload code End

  const values = form.getFieldsValue();
  const dateFormat = "DD/MM/YYYY";
  // const defaultColumns = [
    
  //   {
  //     title: "Policy Number",
  //     dataIndex: "policyNo",
  //     key: 'policyNo',
  //   },
  //   {
  //     title: "Beneficiary Name",
  //     dataIndex: "beneficiaryName",
  //     key: 'beneficiaryName',
  //   },
  //   {
  //     title: "ChequeNo",
  //     dataIndex: "chequeNo",
  //     key: 'chequeNo',
  //   },
  //   {
  //     title: "IFSC",
  //     dataIndex: "ifscCode",
  //     key: 'ifscCode',
  //   },
  //   {
  //     title: "User Name",
  //     dataIndex: "userName",
  //     key: 'userName',
  //   },
  //   {
  //     title: "Payment Details1",
  //     dataIndex: "paymentDetails1",
  //     key: 'paymentDetails1',
  //   }
    

  // ];
     

  const defaultColumns = [
    {
          title: "Ticket No",
          dataIndex: "ticketNo",
          key: '',
        },
        {
          title: "Call Log Date",
          dataIndex: "callLogDate",
          showSorterTooltip: false,
          sorter: {
            compare: (a, b) => moment(a.date).diff(moment(b.date)),
          },
         render: (_, record) => (
          <Space size="middle">
          { moment(record.date).local().format("DD/MM/YYYY hh:mm A")}
          </Space>
        ),
        },
        {
          title: "Call Type",
          dataIndex: "callType",
          key: '',
        },
        {
          title: "Sub Type",
          dataIndex: "subType",
          key: '',
        },
        {
          title: "PO No",
          dataIndex: "poNo",
          key: '',
        },
        {
          title: "Beneficiary Name",
          dataIndex: "beneficiaryName",
          key: '',
        },
        {
          title: "Payout Method",
          dataIndex: "payoutMethod",
          key: '',
        },
        {
          title: "Payable Amount",
          dataIndex: "payableAmount",
          key: '',
        },

  ]
  const columns = defaultColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        //handleSave,
      }),
    };
  });

  const excelData = [
    { Name: 'John', Age: 25, Country: 'USA' },
    { Name: 'Jane', Age: 30, Country: 'Canada' },
    // Add more data as needed
  ];

  useEffect(() => {
 
  }, []); 

  
  const handleSubmit = (values) => {
    const obj = {
      "FromDate": new Date(fromDate) ,
      "ToDate": new Date(toDate)
    }
         
    let response = apiCalls.FinanceDashboardAPI(obj);
    response
      .then((val) => {
        if (val?.data) {
          setDashboardData(val?.data);
             
        } else {
          message.error({
            content:
              val?.data?.responseBody?.errormessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });  
    
  };
  

  const handleFromDateChange = (date) => {
        
    setFromDate(date);

  };
    const handleToDateChange = (date) => {
        setToDate(date);
        if (fromDate && date && date.isBefore(fromDate)) {
            message.error('To Date cannot be earlier than From Date');
            setToDate(()=> null); // Reset To Date
            form.setFieldsValue({ todate: null });
          } else {
            setToDate(date);
          }

      };

const handleButtonClick = () => {
    // Navigate to the 'excelUpload' path
    //navigate('/excelUpload');
    setIsExcelUploadModal(true);
  };

  return (
    <>
      <div className='w-94'>
      <Spin spinning={isLoading}>
  
        <Form
          form={form2}
          name="wrap"
          labelCol={{
            flex: "35%",
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Row gutter={[16,16]} className='dashboard-filters'>
            <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
              <Form.Item
                label="From Date"
                name="fromDate"
                className="input-label mb-0"
                rules={[
                    {
                      required: true,
                      message: 'Please select From Date',
                    },
                  ]}
              >
        

<DatePicker
                    style={{ width: "100%" }}
                    className="cust-input"
                    placeholder="From Date"
                    format={dateFormat}
                   value={fromDate}
                    onChange={(e) => handleFromDateChange(e)}
                  />

              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
              <Form.Item
                label="To Date"
                name="todate"
                className="input-label mb-0"
                rules={[
                    {
                      required: true,
                      message: 'Please select To Date',
                    },
                  ]}
              >
      
<DatePicker
                    style={{ width: "100%" }}
                    className="cust-input"
                    placeholder="To Date"
                    format={dateFormat}
                    value={toDate}
                    onChange={(e) => handleToDateChange(e)}
                  />
              </Form.Item>
            </Col>
      
            <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
            <Button
                  type="primary"
                  className="primary-btn mt-33"
                  htmlType="submit"
                 
                >
                   Search
                </Button>
            </Col>


            <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
            <Button
                  type="primary"
                  className="primary-btn mt-33"
                 
                  onClick={handleButtonClick}
                >
                   Upload
                </Button>
            </Col>


            {/* <Col xs={12} sm={12} md={1} lg={1} xxl={1}>
            <span className='assignto-icon'>
              <span className="editIcon c-pointer"> <i  onClick={() => saveAssignTo()} className="bi bi-file-earmark-excel-fill"></i></span>
              </span>
            </Col> */}
          </Row>
        </Form>
      
      <div className="main-start">
        <div className="w-94">
        <p className="export-btn">
          <span className='fw-400'>Download Cases</span>
        <ExportToExcelButton data={data} fileName="exported-data.xlsx" sheetName="MySheet" fromDate = {fromDate} toDate={toDate}/>

            </p>
          <div className="table-container dashboard">
          <Table
           key={tableKey}
       
          rowKey={(record) => record.key}
          hideSelectAll={false}
        columns={columns}
        dataSource={dashboardData}
        rowClassName={() => 'editable-row'}
        //bordered={true}
       // x={true}
        pagination={{
          //pageSizeOptions: ["5", "10", "15", "15"],
          pageSize: 5,
          //showSizeChanger: true,
          defaultPageSize: 5,
         // size:"small",
           total: {showTotalPages},
          //showTotal: `Total ${showTotalPages} items`
        }}
      />
      </div>
      </div>
      </div>
      </Spin>
      </div>
      {showAlert && (
        <PopupAlert
          alertData={''}
          title={alertTitle}
          navigate={''}
          setShowAlert={setShowAlert}
        ></PopupAlert>
      )}

<Modal
        title="Excel Upload"
        open={isExcelUploadModal}
        destroyOnClose={true}
        // width={1200}
        closeIcon={false}
        footer={null}
      >

        <div>

        <div className='w-94'>
      <Spin spinning={isLoading}>
  
        <Form
          form={form}
          name="wrap"
          labelCol={{
            flex: "35%",
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
          onFinish={handleExcelSubmit}
          autoComplete="off"
        >
          <Row gutter={[16,16]} className='dashboard-filters'>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <Form.Item
                label="Trigger"
                name="trigger"
                className="input-label mb-0"
                rules={[
                    {
                      required: false,
                      message: 'Please select Trigger',
                    },
                  ]}
              >
        

        <Select
                  showSearch
                  placeholder="Select Trigger Value"
                  optionFilterProp="children"
   
                  options={trigger}
                />

              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <Form.Item
                label="Communication Type"
                name="communicationType"
                className="input-label mb-0"
                rules={[
                    {
                      required: false,
                      message: 'Please select Communication Type',
                    },
                  ]}
              >

<Select
                  showSearch
                  placeholder="Select Communication Type"
                  optionFilterProp="children"
   
                  options={commuType}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <Form.Item
                label="Upload Type"
                name="uploadType"
                className="input-label mb-0"
                rules={[
                    {
                      required: false,
                      message: 'Please Upload Type',
                    },
                  ]}
              >
        <Select
                  showSearch
                  placeholder="Select Upload Type"
                  optionFilterProp="children"
   
                  options={uploadType}
                />

              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>

            <Form.Item
                label="Upload Excel"
                name=""
                className="input-label mb-0"
                rules={[
                  {
                    required: true,
                    message: 'Please Upload Excel File',
                  },
                ]}
              >
<Upload {...propss}>
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>

              </Form.Item>
       </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
            <div className="contact-details-btn">
            <Button
                  type="primary"
                  className="primary-btn mt-33"
                  htmlType="submit"
                 
                >
                   Submit
                </Button>
                <Button
              type="primary"
              className="primary-btn mt-33"
              onClick={() => setIsExcelUploadModal(false)}
            >
              Close
            </Button>
            </div>
            </Col>
          </Row>
        </Form>
      </Spin>
      </div>
        </div>
      </Modal>
      
    </>
  );
}

export default FinanceDashboard;