import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DownloadIcon from "../assets/images/download.png";
import {
  Button,
  Form,
  Spin,
  message,
  Row,
  Col,
  Modal,
  Checkbox,
  Input,
  DatePicker,
  Select,
} from "antd";
import apiCalls from "../api/apiCalls";
import { useSelector } from 'react-redux';
import dayjs from "dayjs";

const TicketsOpenPopup = (props) => {
  const [form] = Form.useForm();
  const [reaiseenquiryform] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const { TextArea } = Input;
  const loggedUser = useSelector(state => state?.userProfileInfo?.profileObj);
  const [isLoading, setIsLoading] = useState(false);
  const [gridOpen, setGridOpen] = useState(false);
  const [gridLinkOpen, setGridLinkOpen] = useState(false);
  const [handleCommunicationLink, setHandleCommunication] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isViewData, setIsViewData] = useState('');
  const [isShowRaiseEnquiry,setIsShowRaiseEnquiry] = useState(false);
  const [isShowAddFollowUp, setIsShowAddFollowUp] = useState(false);
  const [requestModeLU,setRequestModeLU] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isFollowUpsLoader,setIsFollowUpsLoader] = useState(false);
  const [comments, setComments] = useState('');
  const [showToFromInView, setShowToFromInView] = useState(false);
  
  const requestCommentBySREnquiry = props?.isTicketDetails?.serviceRequestTransectionData
  ?.find(item => item.tagName === "RequestCommentsBySREnquiry")?.tagValue || '';
  
  const handleInputChange = (e) => {
    setComments(e.target.value);
  };

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const handleSubmit = (values) => {
    console.log('Form Values:', values);
  };

  const getCTST = () => {
    let obj =
    {
      "MasterRequest": [
        "REQST_MODE",
      ]
    }
    let CTST = apiCalls.ctst(obj);
    CTST.then((val) => {
      const rquestModeData = transformData(val.data, "REQST_MODE");
      setRequestModeLU(rquestModeData);
      //setIsLoading(false);
    }).catch((err) => {
      //setIsLoading(false);
      message.destroy()
      message.error({
        content: err?.data?.responseBody?.errormessage,
        className: "custom-msg",
        duration: 2,
      });
    })

  }

  const transformData = (data, keyy) => {
    const filteredData = data?.filter((ele) => ele.key === keyy);
    return filteredData[0]?.value?.map((item, index) => {
      let obj;
  
      if(keyy==='CALL_TYP'){
        obj= {
          ...item,
          label: item.mstDesc,
          value: item.mstID,
          //isCallType:true
        }
      }else if(keyy==='SUB_TYP'){   
        obj = {
          ...item,
          label: item.mstDesc,
           value: item.mstID,
          //isSubType:true
        }
            }else{
        obj = {
          ...item,
          label: item.mstDesc,
          value: item.mstID,
        }
      }
      return obj
    }
    );
  };

  const headerSmsData = [
    {
      title: "Communication",
      field: "communication"
    },
    {
      title: "Sent Date",
      field: "SentDate"
    },
    {
      title: "Delivery Status",
      field: "DeliveryStatus"
    },
    {
      title: "Sent Number",
      field: "SentNumber"
    }
  ]

  const DownloadPDF = async () => {
    let obj = {
      srid: props?.serviceId
    }
    await apiCalls.GetDocumentData(obj).
      then(response => {
        if (response.data.header != null) {
          message.success('No files available');
          return;
        }
        response.data.forEach(x => {
          let obj = JSON.parse(x.json);
          if (obj?.responseBody != null) {
            if(obj.responseBody?.dmsFilesList != null) {
              let fileBytes = obj.responseBody.dmsFilesList.at(0).fileBytes;
              convertBytesToPDF(fileBytes, 'myfile');
            } 
          }
        })
      })
  };

  const convertBytesToPDF = async (fileBytes) => {
    const byteCharacters = atob(fileBytes);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }

  // const commentsData = props?.details?.comments?.length > 0 && props?.details?.comments?.map(item => {
  //   const localTime = moment.utc(item.createdOn).local().format("DD/MM/YYYY hh:mm A")
  //   return {
  //     userName: item.userName,
  //     tagName: item.comments,
  //     userRole: item.roleName,
  //     createdOn: localTime
  //   };
  // });

  const commentsData = props?.details?.comments?.length > 0 && props?.details?.comments?.map(item => {
    // Ensure the date is valid and converted to local time
    const localTime = moment(item.createdOn, moment.ISO_8601).isValid()
        ? moment(item.createdOn).local().format("DD/MM/YYYY hh:mm A")
        : "Invalid Date";

    return {
        userName: item.userName,
        tagName: item.comments, // Assuming 'item.comments' holds the actual comment text
        userRole: item.roleName,
        createdOn: localTime // Show the correct local time
    };
});
  const closedOnDate = new Date(props.details.closedOn);
  const formattedDate = moment(closedOnDate, 'YYYYMMDD').format('DD/MM/YYYY')

  const bodySmsData = props.details?.communication?.map(item => {
    const date = new Date(item.sendDate);
    const formattedDate = moment(date, 'YYYYMMDD').format('DD/MM/YYYY');
    return {
      communication: item.communication,
      SentDate: formattedDate,
      DeliveryStatus: item.delivaryStatus,
      SentNumber: item.sentNumber
    };
  });


  const bodySmsData1 = props?.details?.communication?.map((item) => {
    const date = new Date(item.sendDate);
    const formattedDate =  moment(date).format('DD/MM/YYYY HH:mm:ss');
    
    return {
      SentDate: formattedDate,
      SentNumber: item.sentNumber,
      SenderEmail: item.senderEMail
    };
  });
  const bodyEmailData = props.details?.communication?.map(item => {
    const date = new Date(item.sendDate);
    const formattedDate = moment(date, 'YYYYMMDD').format('DD/MM/YYYY');

    return {
      SentDate: formattedDate,
      DeliveryStatus: item.delivaryStatus,
      SentNumber: item.sentNumber
    };
  });
  const bodyLetterData = [{
    SentDate: "20/02/2024",
    Address: "1223444"
  }]
  const comunicationStatus = () => {
    var status = props?.communication?.map((items) => {
      if (items.communication === "OPEN") {
        return "ACKNOWLEDGEMENT"
      }
      else if (items.communication === "REJECT") {
        return "CLOSED WITH REQUIREMENTS "
      }
      else {
        return "CLOSED"
      }
    }
    )
  }
  const convertDate = (inputDate) => {
    const formattedDate = moment(inputDate, 'YYYYMMDD').format('DD/MM/YYYY');
    return formattedDate;
  };
  const handleLinkClick = () => {
    setGridOpen(!gridOpen);
    setGridLinkOpen(false)
    setHandleCommunication(false)
  };
  const handleClose = () => {
    setGridLinkOpen(false)
  }
  const handleClick = async (data) => {
    setGridLinkOpen(!gridLinkOpen);
    setGridOpen(false)
    setHandleCommunication(false)
  };

  const handleCommunication = () => {
    setHandleCommunication(!handleCommunicationLink)
    setGridOpen(false)
    setGridLinkOpen(false)

  }
  const handleRaiseEnquiry =() =>{
    getCTST();
    setIsShowRaiseEnquiry(!isShowRaiseEnquiry);
  }
  const handleAddFollowUp =()=>{
    setIsShowAddFollowUp(!isShowAddFollowUp);
  }

  // const data = [
  //   { name: 'DD/MM/YYYY', age: 'Requirement 1' },
  //   { name: 'Doe', age: 'Requirement 2' },
  // ];
  // useEffect(() => {
  //   // getTicketDetails()
  // }, [])

  // const getTicketDetails = async () => {
  //   const data = await apiCalls.getTicketDetails(props?.serviceId);
  // }

  const handleView = (data, hideViewStatus) => {
    if(hideViewStatus === true){
      setShowToFromInView(true);
    }
    else{
      setShowToFromInView(false);
    }
    setIsDocumentModalOpen(true)
    let list = data.commType === 2 ? data.emailContent : data.smsText;
    if(data.commType === 2) {
      apiCalls.getEmailTemplate(props?.serviceId, data.templateId)
      .then(response => {
        if(response.data?.includes("BlobNotFound")) {
          setIsViewData(list)
        } else {
          setIsViewData(response.data)
        }
      }).catch(error => console.log(error));
    }
    else {
      setIsViewData(list)
    }
    
  }
  const handleViewClose=()=>{
    setIsViewData('')
    setIsDocumentModalOpen(false)
  }
  const saveRaiseEnquiry = async (values) => {
    setIsFollowUpsLoader(true);
    let obj =
    {
      "SrvReqRefNo" : props?.details?.ticketNo || "",
      "RequestChannel" : values?.requestmode || null,
      "RequestComments":values?.RequestComments || ""
      }
    const response = await apiCalls.saveRaiseEnquiry(obj);
    if (response?.data) {
     message.destroy();
     message.success({
      content: "Raise Enquiry Created Successfully!.",
      className: "custom-msg",
      duration: 2,
    });
      setIsFollowUpsLoader(false);
      props?.setIsOpenTicket(false);
      props?.getLastOpenTicketsData();
    } else {
      message.destroy();
      setIsFollowUpsLoader(false);
      props?.setIsOpenTicket(true);
      message.error({
        content: response?.data?.responseBody?.errormessage || "Something went wrong, please try again!",
        className: "custom-msg",
        duration: 2,
      });
    }
  };

  const saveAddFollowUp = async (values) => {
    setIsFollowUpsLoader(true);
    let obj ={
      "FollowUpID": 0,
      "SrvReqRefNo": props?.details?.ticketNo || "",
      "UsrID": loggedUser?.userName || "",
      "ContactPerson": values?.contact,
      "NxtFollowUpDt": values?.nextFollowUp,
      "CompleteByDt": null,
      "Agenda": values?.agenda,
      "CreatedOn": new Date(),
    }
    const response = await apiCalls.SaveFollowUps(obj);
    if (response?.data) {
     message.destroy();
     message.success({
      content: "Follow Up Added Successfully!.",
      className: "custom-msg",
      duration: 2,
    });
      setIsFollowUpsLoader(false);
      props?.setIsOpenTicket(false);
      props?.getLastOpenTicketsData();
    } else {
      message.destroy();
      setIsFollowUpsLoader(false);
      props?.setIsOpenTicket(true);
      message.error({
        content: response?.data?.responseBody?.errormessage || "Something went wrong, please try again!",
        className: "custom-msg",
        duration: 2,
      });
    }
  };
  const firstRecord = bodySmsData1?.[0];

  const featuredatedisabled = (current) => {
    return current && current < dayjs().startOf("day");
};
const handleDate =()=>{}

  return (
 <>
      <Modal
        open={props?.isOpenTicket}
        destroyOnClose={true}
        width={1200}
        closeIcon={false}
        footer={null}
      >
        <div>
        <Spin spinning={props?.isLoading} >
          {/* <Form > */}
            <div className="reuirement p-2" style={{ border: "2px solid red" }}>
              <p style={{ textAlign: "left" }}><b>Tickets Details</b></p>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: '25%' }}>
                  <Form.Item label={
                    <span>
                      Ticket No
                    </span>
                  } name="TicketNo" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}
                  >
                    <Input type="text" placeholder={props?.details?.ticketNo} style={{ width: "120%", color: "black" }} disabled="true"
                    />
                  </Form.Item>
                </div>
                <div style={{ width: '25%' }}>
                  <Form.Item label={
                    <span>
                      Call Type
                    </span>
                  } wrapperCol={{ span: 18 }} labelCol={{ span: 6 }} name="to" >
                    <Input type="text" placeholder={props?.ticketsData?.callTypeName} style={{ width: "120%", color: 'black' }} disabled="true" />
                  </Form.Item>
                </div>
                <div style={{ width: '25%' }}>
                  <Form.Item label={
                    <span>
                      Sub Type
                    </span>
                  } wrapperCol={{ span: 18 }} labelCol={{ span: 6 }} name="to" >
                    <Input type="text" placeholder={props?.ticketsData?.subTypeName} style={{ width: "120%" }} disabled="true" />

                  </Form.Item>
                </div>
                <div style={{ width: '25%' }}>
                  <Form.Item label={
                    <span>
                      Category
                    </span>
                  } wrapperCol={{ span: 18 }} labelCol={{ span: 6 }} name="category" >
                    <Input type="text" placeholder={props?.ticketsData?.category} style={{ width: "120%" }} disabled="true" />
                    <style>
                      {`
          .ant-input::placeholder {
            color: black;
          }
        `}
                    </style>
                  </Form.Item>
                </div>
              </div>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: '25%' }}>
                  <Form.Item label={
                    <span>
                      Created By
                    </span>
                  } name="TicketNo" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}
                  >
                    <Input type="text" placeholder={props?.ticketsData?.createdBy} style={{ width: "120%" }} disabled="true" />
                  </Form.Item>
                </div>
                <div style={{ width: '25%' }}>
                  <Form.Item label={
                    <span>
                      Created On
                    </span>
                  } wrapperCol={{ span: 18 }} labelCol={{ span: 6 }} name="to" >
                    <Input type="text" placeholder={convertDate(props?.ticketsData?.date)} style={{ width: "120%" }} disabled="true" />
                  </Form.Item>
                </div>
                <div style={{ width: '25%' }}>
                  <Form.Item label={
                    <span>
                      Request Mode
                    </span>
                  } wrapperCol={{ span: 18 }} labelCol={{ span: 6 }} name="to" >
                    <Input type="text" placeholder={props?.details?.requestMode || 'NA'} style={{ width: "120%" }} disabled="true" />
                  </Form.Item>
                </div>
                <div style={{ width: '25%' }}>
                  <Form.Item label={
                    <span>
                      Status
                    </span>
                  } wrapperCol={{ span: 18 }} labelCol={{ span: 6 }} name="to" >
                    <Input type="text" placeholder={props?.details?.status} style={{ width: "120%" }} disabled="true" />
                  </Form.Item>
                </div>

              </div>
              <div style={{ display: 'flex', width: '100%' }}>

                <div style={{ width: '25%' }}>
                  <Form.Item label={
                    <span>
                      Pending With
                    </span>
                  } wrapperCol={{ span: 18 }} labelCol={{ span: 6 }} name="category" >
                    <Input type="text" placeholder={props?.details?.status === "PENDING" ? props?.details?.pendingWith : "NA"} style={{ width: "120%" }} disabled="true" />
                  </Form.Item>
                </div>


                <div style={{ width: '25%' }}>
                  <Form.Item
                    label={<span>Closed By</span>}
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    name="closedby"
                  >
                    <Input type="text" placeholder={props?.details?.status === "CLOSED" ? props?.isTicketDetails?.modifiedByRef : "NA"} style={{ width: "120%" }} disabled={true} value="" />
                  </Form.Item>
                </div>
                <div style={{ width: '25%' }}>
                  <Form.Item label={
                    <span>
                      Closed on
                    </span>
                  } name="closeon" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}
                  >
                    <Input type="text" placeholder={props?.details?.status === "CLOSED" ? convertDate(props?.details?.closedOn) : "NA"} style={{ width: "120%" }} disabled="true" />
                  </Form.Item>
                </div>
                <div style={{ width: '25%' }}>
                  <Form.Item label={
                    <span>
                      Request By
                    </span>
                  } wrapperCol={{ span: 18 }} labelCol={{ span: 6 }} name="to" >
                    <Input type="text" placeholder={props?.details?.requestBy || "NA"} style={{ width: "120%" }} disabled="true" />
                  </Form.Item>
                </div>
              </div>
              <div style={{ display: 'flex', width: '100%' }}>
               
                <div style={{ width: '25%' }}>
              {(props.ticketsData.callType == "2" && props.ticketsData.subType == "1" && props.ticketsData.status === "CLOSED" || (props.ticketsData.callType == "2" && props.ticketsData.subType == "2" && props.ticketsData.status === "CLOSED") ) ? ( 
                    <Form.Item label={
                      <span>
                        Download PDF
                      </span>
                    } wrapperCol={{ span: 18 }} labelCol={{ span: 6 }} name="category" >
                      <img src={DownloadIcon} className="send-icon" onClick={DownloadPDF}/>
                    </Form.Item>
              ): null}
              </div>
              </div>
            </div>
            <div className="reuirement" style={{ textAlign: "left" }}>
              <div className='col-sm-2'>
                <a style={{ color: 'black', borderBottom: "1px solid", fontWeight: "bold" }} onClick={handleLinkClick}>View Requirements</a>
              </div>
              {gridOpen && (
                <div className='mt-2 mb-2'>
                  {props?.details?.raiseReq && props?.details?.raiseReq?.length > 0 ? (
                    <table style={{ borderCollapse: 'collapse', width: '50%', border: '1px solid black' }}>
                      <thead>
                        <tr style={{ border: '1px solid black', width: '25%' }}>
                          <th style={{ border: '1px solid black' }}>Raised On</th>
                          <th style={{ border: '1px solid black' }}>List of Requirements</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props?.details?.raiseReq?.map((email, index) => (
                          <tr key={index} style={{ border: '1px solid black' }}>
                            <td style={{ border: '1px solid black' }}>{formattedDate}</td>
                            <td style={{ border: '1px solid black' }}>{email.mstDesc}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>No records found.</div>
                  )}
                </div>
              )}
              <div className='col-sm-2'>
                <a style={{ color: 'black', borderBottom: "1px solid", fontWeight: "bold" }} onClick={handleClick}>View History</a>
              </div>
              {gridLinkOpen && (
                <div className='mt-2 mb-2'>
                  <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                    <div className='openTickets_card'>
                      {commentsData?.length > 0 ? (
                        commentsData?.map((comments, index) => (
                          <div key={index}>
                            <b>{comments.userName}/{comments.userRole}:{comments.createdOn}
                            </b>
                            <p>{comments.tagName}</p>
                          </div>
                        ))
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          <p>No Comments</p>
                        </div>

                      )}
                      {
                        requestCommentBySREnquiry != '' && (
                          <div>
                          <p>Comments: {requestCommentBySREnquiry}</p>
                        </div>
                        )
                      }
                    </div>
                  </Col>
                </div>
              )}
              <div className='col-sm-2'>
                <a className='tickets_links_open' style={{ color: 'black', borderBottom: "1px solid", fontWeight: "bold" }} onClick={handleCommunication}>View Communication</a>
              </div>
              {handleCommunicationLink && (
              <div className='mt-2 mb-2'>
                <Row>
                  <Col xs={24} sm={24} md={16} lg={24} xxl={24}>
                    {props?.details?.status === 'Closed' ? (
                      <div>
                        <p>Closed with requirements</p>
                      </div>
                    ) : props?.details?.status === 'Rejected' ? (
                      <div>
                        <p>Acknowledgement</p>
                      </div>
                    ) : (
                      props?.details?.communication?.some(comm => comm.commType !== 2) ? (
                        <>
                          <table style={{ borderCollapse: 'collapse', border: '1px Solid black', marginBottom: '20px', width: '100%' }}>
                            <thead>
                              <tr>
                                <th style={{ border: '1px solid black' }} rowspan="2">Communication Type</th>
                                <th style={{ border: '1px solid black' }} colspan="4">SMS</th>
                              </tr>
                              <tr style={{ border: '1px solid black', width: '100%' }} >
                                <th className='px-3 py-3' style={{ border: '1px solid black' }}>Send Date</th>
                                <th className='px-3 py-3' style={{ border: '1px solid black' }}>Delivery Status</th>
                                <th className='px-3 py-3' style={{ border: '1px solid black' }}>Sent Number</th>
                                <th className='px-3 py-3' style={{ border: '1px solid black' }}>Sms Text</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Map over the data and render table rows */}
                              {props.details.communication.map((comm, index) => (
                                comm.commType === 1 && (
                                  <tr key={index} style={{ border: '1px solid black', width: '100%' }}>
                                    <td className='px-3 py-3' style={{ border: '1px solid black' }}>{comm.communication === "OPEN" ? "ACKNOWLEDGEMENT" : comm.communication === "REJECT" ? " Closed with Requirements" : "CLOSED"}</td>
                                    <td className='px-3 py-3' style={{ border: '1px solid black' }}>{formattedDate}</td>
                                    <td className='px-3 py-3' style={{ border: '1px solid black' }}>{comm.deliveryStatusDesc}</td>
                                    <td className='px-3 py-3' style={{ border: '1px solid black' }}>{comm.sentNumber}</td>
                                    <td className='px-3 py-3' style={{ border: '1px solid black',cursor:'pointer' }} onClick={() => handleView(comm, true)}>View</td>
                                  </tr>
                                )
                              ))}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <div>
                          <p>No SMS records found</p>
                        </div>
                      )
                    )}

                  </Col>


                  <Col xs={24} sm={24} md={16} lg={24} xxl={24}>
                    {props.details.communication && props.details.communication.length > 0 && props.details.communication.some(comm => comm.commType === 2) ? (
                      <table style={{ borderCollapse: 'collapse', border: '1px Solid black', marginBottom: '20px',width: '100%' }}>
                        <thead>
                          <tr>
                            <th style={{ border: '1px solid black' }} rowspan="2">Communication Type</th>
                            <th style={{ border: '1px solid black' }} colspan="4">Email</th>
                          </tr>
                          <tr style={{ border: '1px solid black', width: '100%' }}>
                            <th className='px-3 py-3' style={{ border: '1px solid black' }}>Sent Date</th>
                            <th className='px-3 py-3' style={{ border: '1px solid black' }}>Delivery Status</th>
                            <th className='px-3 py-3' style={{ border: '1px solid black' }}>Email Id</th>
                            <th className='px-3 py-3' style={{ border: '1px solid black' }}>Email Content</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Map over the data and render table rows */}
                          {props.details.communication.map((comm, index) => (
                            comm.commType === 2 && (
                              <tr key={index} style={{ border: '1px solid black' }}>
                                <td className='px-3 py-3' style={{ border: '1px solid black' }}>{comm.communication === "OPEN" ? "ACKNOWLEDGEMENT" : comm.communication === "REJECT" ? " Closed with Requirements" : "CLOSED"}</td>
                                <td className='px-3 py-3' style={{ border: '1px solid black' }}>{formattedDate}</td>
                                <td className='px-3 py-3' style={{ border: '1px solid black' }}>{comm.deliveryStatusDesc}
                                </td>
                                <td className='px-3 py-3' style={{ border: '1px solid black' }}>{comm.sentNumber}</td>
                                <td className='px-3 py-3' style={{ border: '1px solid black',cursor:"pointer" }} onClick={() => handleView(comm, false)}>View</td>
                              </tr>
                            )
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div>
                        <p>No Email records found</p>
                      </div>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={24} xxl={24}>
                    {bodyLetterData.length == 0 ? (
                      <table style={{ borderCollapse: 'collapse', border: '1px Solid black', marginBottom: '20px', }}>
                        <thead>
                          <tr>
                            <th style={{ border: '1px solid black' }} rowspan="2">Communication Type</th>
                            <th style={{ border: '1px solid black' }} colspan="4">Letter</th>
                          </tr>
                          <tr style={{ border: '1px solid black', width: '50%' }}>
                            <th style={{ border: '1px solid black' }}>Sent Date</th>
                            <th style={{ border: '1px solid black' }}>Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bodyLetterData.map((letter, index) => (
                            <tr style={{ border: '1px solid black' }} key={index}>
                              <td style={{ border: '1px solid black' }}>{letter.SentDate}</td>
                              <td style={{ border: '1px solid black' }}>{letter.Address}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No Letter Records found</p>
                    )}
                  </Col>

                </Row>
              </div>
            )}
              <div className='col-sm-2'>
                <a className='tickets_links_open' style={{ color: 'black', borderBottom: "1px solid", fontWeight: "bold" }} onClick={handleRaiseEnquiry}>Raise Enquiry</a>
              </div>
              {isShowRaiseEnquiry && (
               <div className='openTickets_card mt-8 mb-8'>
             <Form
             form={reaiseenquiryform}
             name="wrap"
             labelCol={{
               flex: "35%",
             }}
             labelAlign="left"
             labelWrap
             wrapperCol={{
               flex: 1,
             }}
             colon={false}
             onFinish={saveRaiseEnquiry}
             autoComplete="off"
           >
            <Row className='mt-8'>
               <Col
              className="m-10"
                xs={24}
                sm={24}
                md={10}
                lg={10}
                xxl={10}
              >
                <Form.Item
                  label={
                    <span>
                    Request Mode <sup>*</sup>
                    </span>
                  }
                  name="requestmode"
                  className="inputs-label mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Request Mode',
                    },
                  ]}
                >
                  <Select
                    className="cust-input calltype-select"
                    maxLength={100}
                    placeholder="Select Request Mode"
                   options={requestModeLU}
                  ></Select>
                </Form.Item>
              </Col>
              <Col className="m-10" xs={24} sm={24} md={10} lg={10} xxl={10}>
      <Form.Item 
        label={
          <span>
           Acknowledge SR Inquiry
          </span>
        }
          name="shareAcknowledgement"
       rules={[
        {
          required: false,
          message: 'Share Acknowledgement ',
        },
      ]}>
        <Checkbox
          checked={selectedValue === 'yes'}
          onChange={() => handleChange('yes')}
          style={{ marginRight: '5px' }}
        >
          Yes
        </Checkbox>
        <Checkbox
          checked={selectedValue === 'no'}
          onChange={() => handleChange('no')}
        >
          No
        </Checkbox>
      </Form.Item>
       {/* <Form.Item
       label={
        <span>
        Share Acknowledgement <sup>*</sup>
        </span>
      }
          name="shareAcknowledgement"
          noStyle
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Checkbox
            checked={selectedValue === 'yes'}
            onChange={() => handleChange('yes')}
            style={{ marginRight: '5px' }}
          >
            Yes
          </Checkbox>
          <Checkbox
            checked={selectedValue === 'no'}
            onChange={() => handleChange('no')}
          >
            No
          </Checkbox>
        </Form.Item> */}
    </Col>
              </Row>
              <Row className='mt-8'>
              <Col className="m-10" xs={24} sm={24} md={10} lg={10} xxl={10}>
  <Form.Item
    label={
      <span>
        Comments
      </span>
    }
    name="RequestorComments"
    className="inputs-label mb-0"
    rules={[
      {
        required: true,
        message: 'Requestor Comments is required',
      },
    ]}
  >
    <Input.TextArea
      id="comments"
      name="RequestorComments"
      placeholder="Comments"
      maxLength={500}
      value={comments}
      onChange={handleInputChange}
    />
  </Form.Item>
</Col>

              </Row>
                 <div className="contact-details-btn">
                   <Button type="primary"  className="primary-btn" htmlType='submit'>
                     Submit
                   </Button>
                   </div>
               </Form>
               </div>
              )}
              <div className='col-sm-2'>
                <a className='tickets_links_open' style={{ color: 'black', borderBottom: "1px solid", fontWeight: "bold" }} onClick={handleAddFollowUp}>Add Follow Up</a>
              </div>
              {isShowAddFollowUp && (
               <div className='openTickets_card mt-8 mb-8'>
             <Form
          form={form}
          name="wrap"
          labelCol={{
            flex: "35%",
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
          onFinish={saveAddFollowUp}
          autoComplete="off"
        >
            <Row gutter={[16,16]} className='mt-8'>
               <Col
              className="m-10"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xxl={12}
              >
                <Form.Item
                  label={
                    <span>
                    Follow Up With <sup>*</sup>
                    </span>
                  }
                  name="contact"
                  className="inputs-label mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Follow Up With',
                    },
                  ]}
                >
                <Input 
                type="text"
                className="cust-input"
                 placeholder='Follow Up With' 
                 maxLength={50}
                 />
                </Form.Item>
              </Col>
              <Col
               className="m-10 mt-8"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xxl={12}
              >
                     <Form.Item
            label={
              <span>
             Follow Up On <sup>*</sup>
              </span>
            }
            name="nextFollowUp"
            className="inputs-label mb-0"
            rules={[
              {
                required: true,
                message: "Follow Up On",
              },
            ]}
          >
            <DatePicker
              allowClear={true}
              style={{ width: "100%" }}
              className="cust-input"
              format={dateFormat}
              handleDate={handleDate}
              disabledDate={(e)=>featuredatedisabled(e)}
            />
          </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12} className='m-10 mt-16'>
              <div className="address-textbox">
                <Form.Item 
               label={
                <span>
                Agenda
                </span>
              }
                name="agenda" className=""
                  rules={[
                    {
                      required: false,
                      message: "Agenda",
                    },   
                    { max: 100, message: "" }
                  ]}
                >
                  <TextArea rows={4} maxLength={500} placeholder="Free Text 500 Characters" className='ml-16'/>
                  {/* <TextArea
                    maxLength={100}
                    placeholder="Agenda"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  /> */}
                </Form.Item>
              </div>
            </Col>
            {/* <Col xs={12} sm={12} md={24} lg={24} xxl={24}>
            <Button
                  type="primary"
                  className="primary-btn mt-33"
                  htmlType="submit"
                 
                >
                   Submit
                </Button>
            </Col> */}
              </Row>
                 <div className="contact-details-btn">
                   <Button type="primary"  className="primary-btn" htmlType="submit">
                     Save
                   </Button>
                   </div>
               </Form>
               </div>
              )}
            </div>
            
            <div className="contact-details-btn">

              <Button
                type="primary"
                className="primary-btn"
                onClick={() => props?.handleTicketSubmit()}
              >
                Close
              </Button>

              {/* <Button
                type="primary"
                className="primary-btn"
                onClick={props?.handleClose}
              >
                Close
              </Button> */}
            </div>
          {/* </Form> */}
          </Spin>
        </div>



      </Modal>
      {/* <Modal
        title="View Communication"
        open={isDocumentModalOpen}
        destroyOnClose={true}
        width={800}
        closeIcon={false}
        footer={null}
      >
        <div  >

          <div className="reuirement">
             <div dangerouslySetInnerHTML={{__html:isViewData}} >
                </div>
          </div>
          <div className="contact-details-btn">
            <Button
              type="primary"
              className="primary-btn"
              onClick={() => handleViewClose()}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal> */}
      <Modal
  title={null} // Remove the "View Communication" title
  open={isDocumentModalOpen}
  destroyOnClose={true}
  width={800}
  closeIcon={false}
  footer={null}
>
  <div>
    {/* Email information header */}
    {/* {
      !showToFromInView && 
      <div className='header-info'>
      {firstRecord && (
          <div>
            <span>To: {firstRecord.SentNumber}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>From: {firstRecord.SenderEmail}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span><i className="bi bi-alarm"></i>: {firstRecord.SentDate}</span>
          </div>
        )}
      </div> */}
    {/* } */}
    {
     
       <div className='header-info'>

       {firstRecord && (
           <div>
            { !showToFromInView &&
            <>
             <span>To: {firstRecord.SentNumber}</span>
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             <span>From: {firstRecord.SenderEmail}</span>
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             </>
            }
             <span><i className="bi bi-alarm"></i>: {firstRecord.SentDate}</span>
           </div>
         )}
       </div>
    }
  <div className="requirement">
      {/* Content of the service request */}
      <div dangerouslySetInnerHTML={{ __html: isViewData }}></div>
    </div>

    <div className="contact-details-btn">
      <Button
        type="primary"
        className="primary-btn"
        onClick={() => handleViewClose()}
      >
        Close
      </Button>
    </div>
  </div>
</Modal>
      </>

  )
}
export default TicketsOpenPopup;