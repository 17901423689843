import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import apiCalls from "../../api/apiCalls";
import { useParams } from 'react-router-dom';
import PopupAlert from "../popupAlert";

const SalesInteractionTab = () => {
    const [form] = Form.useForm(); // Initialize the form instance
    const emailExist = false;
    const dateFormat = "DD/MM/YYYY";
    let { serviceId } = useParams();
    const [srvReqID, setSrvReqID] = useState(0);
    const loginInfo = useSelector(state => state);
    const [alertData, setAlertData] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [previousInteractionDetails, setPreviousInteractionDetails] = useState([]);
    
    const DispositionLU = [
        { label: 'Contacted', value: 'contacted' },
        { label: 'Not Contacted', value: 'notContacted' },
        { label: 'Wrong No', value: 'wrongNo' },
        { label: 'Switched Off', value: 'switchedOff' },
        { label: 'Not Reachable', value: 'notReachable' },
    ];

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const featuredatedisabled = (current) => {
        return current && current < dayjs().startOf("day");
    };

    const handleDate = () => {};

    const getTransactionData = (values) => {
        return [
            {
                "Status": "Create",
                "TagName": "Comp_Sales_Person_Contacted",
                "TagValue": values.salesPersonContacted
            },
            {
                "Status": "Create",
                "TagName": "Comp_Contact_Number",
                "TagValue": values.contactNumber
            },
            {
                "Status": "Create",
                "TagName": "Comp_EmailID",
                "TagValue": values.emailID
            },
            {
                "Status": "Create",
                "TagName": "Comp_Interaction_Details",
                "TagValue": values.description
            }
        ];
    };

    const handleMiscSave = () => {
      form.validateFields().then(async (values) => {
          const obj = {
              SrvReqRefNo: serviceId,
              SrvReqID: srvReqID,
              TransactionData: getTransactionData(values) || [],
              ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
              RequestDateTime: "2024-01-10T00:00:00",
              CustSignDateTime: "2024-01-10T00:00:00"
          };
  
          try {
              const response = await apiCalls.genericAPI(obj); // Await the API call
              if (response?.status === 200) { // Ensure response.status is available in your API response
                  setAlertTitle(response?.data?.header);
                  setAlertData(response?.data?.message);
                  setShowAlert(true); // Show alert popup on success
              } else {
                  console.error("Unexpected response status:", response.status);
              }
          } catch (error) {
              console.error("API call failed:", error);
          }
      }).catch((error) => {
          console.log("Validation failed:", error);
      });
  };
  

    useEffect(() => {
        getPOSIndividualData();
    }, []);

    const setFormFieldsData = (val) => {
        if (val?.data) {
            // Define a mapping of tag names to form field names
            const fieldMapping = {
                'Comp_Sales_Person_Contacted': 'salesPersonContacted',
                'Comp_Contact_Number': 'contactNumber',
                'Comp_EmailID': 'emailID',
                'Comp_Interaction_Details': 'description',
                // Add other mappings as needed
            };
    
            // Initialize an object to hold form field values
            const formValues = {};
    
            // Iterate over the transaction data and populate the formValues object
            val?.data?.serviceRequestTransectionData?.forEach((ele) => {
                const fieldName = fieldMapping[ele.tagName];
                if (fieldName) {
                    formValues[fieldName] = ele.tagValue;
                }
            });
    
            // Set the form field values
            form.setFieldsValue(formValues);
        }
    };

    const getPOSIndividualData = async () => {
        try {
            const val = await apiCalls.getPOSIndividualData(serviceId);
            const srvReqIDFromData = val?.data?.srvReqID; // Extract srvReqID from val.data
    
            // Update state and form values with srvReqID
            setSrvReqID(srvReqIDFromData);
            setFormFieldsData(val);
        }
        catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors appropriately
        }
    };

    const validatePhoneNumber = (_, value) => {
        if (emailExist) {
            return Promise.reject("Mobile number already exists");
        } else if (value && !/^[6-9]\d{9}$/.test(value)) {
            return Promise.reject("Mobile number should start with 6,7,8 or 9 and must be 10 digits");
        } else if (
            value &&
            !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value)
        ) {
            return Promise.reject("Invalid mobile number");
        } else {
            return Promise.resolve();
        }
    };

    const handleKeyDown = (pattern, e) => {
        const key = e.key;
        let specialCharacterRegex = '';

        if (pattern === 'numbersOnly') {
            const inputValue = e.target.value;
            if (inputValue.includes('.')) {
                specialCharacterRegex = /^[0-9]$/; 
            } else {
                specialCharacterRegex = /^[0-9.]$/;
            }
        } else if (pattern === 'charactersOnly') {
            specialCharacterRegex = /^[a-zA-Z0-9]$/;
        } else if (pattern === 'alphabatesOnly') {
            specialCharacterRegex = /^[a-zA-Z]$/;
        } else if (pattern === "decimalOnly") {
            const inputValue = e.target.value;
            if (inputValue.includes('.')) {
                specialCharacterRegex = /^[0-9]$/; 
            } else {
                specialCharacterRegex = /^[0-9.]$/;
            }
        }

        if (key === 'Backspace' || key.startsWith('Arrow')) {
            return;
        }

        if (!specialCharacterRegex.test(key)) {
            e.preventDefault();
        }
    };

    return (
        <Form form={form} layout="vertical" className="sales-interaction-form">
            <Row gutter={[16, 16]} className='mb-16'>
                <Col xs={24} sm={24} md={9} lg={9} xxl={9} className='mb-16'>
                    <Form.Item
                        label="Sales Person Contacted"
                        name="salesPersonContacted"
                        className="inputs-label mb-0"
                        rules={[
                            {
                                required: true,
                                message: "Sales Person Contacted",
                            },
                        ]}
                    >
                        <Input 
                            placeholder="Sales Person Contacted"
                            className="cust-input"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xxl={9} className='mb-16'>
                    <Form.Item
                        label="Contact Number"
                        name="contactNumber"
                        className="inputs-label mb-0"
                        rules={[
                            {
                                required: true,
                                message: "Enter Contact Number",
                            },
                            {
                                validator: validatePhoneNumber,
                            },
                        ]}
                    >
                        <Input
                            placeholder="Contact Number"
                            className="cust-input"
                            maxLength="10"
                            minLength="10"
                            onKeyDown={(e) => handleKeyDown("numbersOnly", e)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xxl={9} className='mb-16'>
                    <Form.Item
                        label="Enter Email ID"
                        name="emailID"
                        className="inputs-label mb-0"
                        rules={[
                            {
                                required: true,
                                message: "Enter Email ID",
                            },
                            {
                                validator(_, value) {
                                    if (emailExist) {
                                        return Promise.reject("Email already exists");
                                    } else if (
                                        value &&
                                        !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$/.test(value)
                                    ) {
                                        return Promise.reject("Invalid email");
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter Email ID"
                            className="cust-input"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={23} lg={23} xxl={23} className='mb-16'>
                    <Form.Item
                        label="Interaction Details"
                        name="description"
                        className="inputs-label mb-0"
                        rules={[
                            {
                                required: true,
                                message: "Enter Description",
                            },
                        ]}
                    >
                        <TextArea
                            rows={4}
                            maxLength={1000}
                            placeholder="Interaction Details"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24} className="form-footer">
                    <Row justify="start">
                        <Col>
                            <Button
                                type="primary"
                                className="primary-btn"
                                htmlType="submit"
                                onClick={handleMiscSave}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
              
            {showAlert && (
                <PopupAlert
                    alertData={alertData}
                    title={alertTitle}
                    setShowAlert={setShowAlert}
                ></PopupAlert>
            )}
        </Form>
    );
};

export default SalesInteractionTab;