import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Header from "./Header";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import ProtectedRoute from "../utils/PrivateRouter";
import PosAdminDashboard from "../components/POSAdmin/posAdminDashboard";
import FinanceDashboard from '../components/Finance/financeDashboard';
import PosApproveComponent from "../components/PosApproveComponent";
import EmailManagement from "../components/EmailManagement";
import EmailManagementView from "../components/EmailManagement/EmailManagementView";
import EmailManagementAdmin from "../components/EmailManagement/EmailManagementAdmin";
import ExcelUpload from  "../components/ExcelUpload";
import ComplaintsDashboard from  "../components/Complaints/complaintsDashboard";
import ComplaintsTeam from  "../components/Complaints/complaintsTeam";
import ComplaintsUser from  "../components/Complaints/complaintsUser";
import BOEDashboard from "../components/BOEDashboard/BOEDashboard";
import ComplaintTeamDashboard from "../components/Complaints/complaintTeamDashboard";
import ComplaintsUserDashboard from "../components/Complaints/complaintsUserDashboard";
import PAUserDashboard from "../components/PAUserDashboard/PAUserDashboard";
import NBUserDashboard from "../components/NBUserDashboard/NBUserDashboard";
import POSPayoutDashboard from "../components/POSPayoutDashboard/POSPayoutDashboard";
import NotificationQC from "../components/Claims/NotificationQC";
import PrimaryAssessment from "../components/Claims/PrimaryAssessment";
import RevivalWithDGH from "../components/RevivalScreen/RevivalWithDGH";
import Foreclosure from "../components/CallTypes/Foreclosure";
import RevivalDashboard from "../components/RevivalScreen/RevivalDashboard";
import POSExDashboard from "../components/POSExDashboard/POSExDashboard";
import POSApproverDashboardPayout from "../components/POSApproverDashboard/POSApproverDashboardPayout";
import PAAdminDashboard from "../components/PAAdminDashboard/PAAdminDashboard";
import PAExecutiveDashboard from "../components/PAExecutiveDashboard/PAExecutiveDashboard";
import PosAdminDashboardNonPayOut from "../components/POSAdmin/POSAdminDashboardNonPayout";
import POSExeDashboardNonPayout from "../components/POSExDashboard/POSExeDashboardNonPayout";
import PAApproverDashboard from "../components/PAAdminDashboard/PAApproverDashboard";
import POSApproverDashboard from "../components/POSApproverDashboard/POSApproverDashboard";
import POSApproverDashboard2 from "../components/POSApproverDashboard2/POSApproverDashboard2";
import POSApproverDashboard3 from "../components/POSApproverDashboard3/POSApproverDashboard3";
import POSApproverDashboard4 from "../components/POSApproverDashboard4/POSApproverDashboard4";
import NBAdminDashboard from "../components/NBUserDashboard/NBAdminDashboard";
import BOEUserDashboard from "../components/BOEUserDashboard.js/BOEUserDashboard";
import PosPayoutExecutive from "../components/PosPayoutExecutive/PosPayoutExecutive";
import PosPayoutAdmin from '../components/POSPayoutAdmin/PosPayoutAdmin'
import PosPayoutExecUser from '../components/PosPayoutExecutive/PosPayoutExecUser';
import ClaimsQCUserDashboard from "../components/ClaimsQCUserDashboard/ClaimsQCUserDashboard";
import CallCenterDashboard from "../components/CallCenterDashboard/CallCenterDashboard";
import PrimaryAssesorDashboard from "../components/Claims/PrimaryAssesorDashboard";
import AssessmentCheckerDashboard from "../components/Claims/AssessmentCheckerDashboard";
import ClaimsApproverDashboard from "../components/Claims/ClaimsApproverDashboard";

const Login = lazy(() => import("./Login"));
const AdvanceSearchComponent = lazy(() =>
  import("../components/AdvanceSearch/advanceSearch")
);
const PosManagerDashboard = lazy(() =>
  import("../components/POSManager/posManagerDashboard")
);
const Dashboard = lazy(() => import("./Dashboard"));
const Table = lazy(() => import("../components/Table"));
const CustomerDetails = lazy(() => import("../components/customerDetails"));
const ErrorPage = lazy(() => import("../utils/ErrorPage"));

const Routing = () => {
    const loginInfo = useSelector(state => state);
    const isLoggedIn =  loginInfo?.userProfileInfo?.profileObj?.name && localStorage.getItem('isLoggedIn')  ? true : false;
   // const isLoggedIn = loginInfo?.userProfileInfo?.profileObj?.name ? true : false;

    return (
        <>
            {window.location.hash?.toLocaleLowerCase() === '/posapprove' && <PosApproveComponent />}
            {window.location.hash?.toLocaleLowerCase() !== "#/posapprove" && ( <>
                    {isLoggedIn ? (
                        <>
                            <Header />
                            <Suspense fallback={<Spin size="large" tip="Loading..." className="loader" />}>
                                <Routes>
                                    {/* <Route path="/" element={<AdvanceSearchComponent />} /> */}
                                    <Route path="/advancesearch" element={<AdvanceSearchComponent />} />
                                    <Route path="/login" element={<Navigate to="/advancesearch" />} />
                                    <Route element={<ProtectedRoute />}>
                                       <Route path="/boedashboard" element={<BOEDashboard />} />
                                        <Route path="/policydetails" element={<CustomerDetails />} />
                                        <Route path="/dashboard" element={<Dashboard />} />
                                        <Route path="/calllogging" element={<CustomerDetails />} />
                                        <Route path="/table" element={<Table />} />
                                        <Route path="/posmanager" element={<PosManagerDashboard />} />
                                        <Route path="/posadmin" element={<PosAdminDashboard />} />
                                        <Route path="/grievanceadmin" element={<ComplaintsDashboard />} />
                                        <Route path="/finance" element={<FinanceDashboard />} />
                                        <Route path="/emailuser" element={<EmailManagement />} />
                                        <Route path="/emailuser/:userId" element={<EmailManagement />} />
                                        <Route path="/emailmanagementview/:id" element={<EmailManagementView />} />
                                        <Route path="/grievanceuser" element={<ComplaintTeamDashboard />} />
                                        <Route path="/grievanceuser/:serviceId" element={<ComplaintsTeam />} />
                                        <Route path="/complaintsuser" element={<ComplaintsUserDashboard />} />
                                        <Route path="/complaintsuser/:serviceId" element={<ComplaintsUser />} />
                                      
                                        <Route path="/pauser" element={<PAUserDashboard />} />
                                        <Route path="/nbuser" element={<NBUserDashboard />} />
                                        <Route path="/pospayoutdashboard" element={<POSPayoutDashboard />} />
                                        <Route path="/excelUpload" element={<ExcelUpload />} />
                                        <Route path="/notificationqc" element={<NotificationQC />} />
                                        <Route path="/primaryassessment" element={<PrimaryAssessment />} />
                                        <Route path="/revivalDashboard" element={<RevivalDashboard />} />
                                        <Route path="/revivalwithDGH" element={<RevivalWithDGH />} />
                                        <Route path="/foreclosure" element={<Foreclosure />} />
                                        <Route path="/emailadmin" element={<EmailManagementAdmin />} />
                                        <Route path="/posexdashboard" element={<POSExDashboard />} />
                                        <Route path="/pospayoutapproverdashboard" element={<POSApproverDashboardPayout />} />
                                        <Route path="/paadmin" element={<PAAdminDashboard />} />
                                        <Route path="/paexecutivedashbaord" element={<PAExecutiveDashboard />} />
                                        <Route path="/posnonpayoutadmindashboard" element={<PosAdminDashboardNonPayOut />} />
                                        <Route path="/posexec" element={<POSExeDashboardNonPayout />} />
                                        <Route path="/paapprover" element={<PAApproverDashboard />} />
                                        <Route path="/posapprover" element={<POSApproverDashboard />} />
                                        <Route path="/posapprover2" element={<POSApproverDashboard2 />} />
                                        <Route path="/posapprover3" element={<POSApproverDashboard3 />} />
                                        <Route path="/posapprover4" element={<POSApproverDashboard4 />} />
                                        <Route path="/nbadmin" element={<NBAdminDashboard />} />
                                        <Route path="/boeuser" element={<BOEUserDashboard />} />
                                        <Route path="/pospayoutexec" element={< PosPayoutExecutive/>} />
                                        <Route path="/pospayoutadmin" element={< PosPayoutAdmin/>} />

                                        
                                        <Route path="/posPayoutExecUser" element={< PosPayoutExecUser/>} />

                                        
                                        <Route path="/claimsnotificationuser" element={<ClaimsQCUserDashboard />} />
                                        <Route path="/callcenteruserdashboard" element={<CallCenterDashboard />} />
                                        <Route path="/claimsprimaryuser" element={<PrimaryAssesorDashboard />} />
                                        <Route path="/claimsassessmentchecker" element={<AssessmentCheckerDashboard />} />
                                        <Route path="/claimsapprover" element={<ClaimsApproverDashboard />} />
                                        <Route path="*" element={<ErrorPage />} />
                                        </Route>
                                </Routes>
                            </Suspense>
                        </>
                    ) : (
                        <Login />
                    )}
                </>
            )}
        </>
    );
};

export default Routing;
