import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import apiCalls from "../../api/apiCalls";
import { Card, Col, Row, Radio, Timeline, Space, Tabs, Switch ,Checkbox,  Select, Input, Form, Button, Collapse, Modal, message, Spin, Progress, Tooltip,DatePicker } from 'antd';
import CloseIcon from "../../assets/images/close-icon.png";
import { Routes, Route, useParams, useLocation } from 'react-router-dom';
import Assistance from "../../assets/images/handshake.png";
import Widgets from '../Widgets/Widgets';
import ViewCustomerComplaintTab from './ViewCustomerComplaintTab';
import CustomerDetails from '../customerDetails';
import CustomerInterationTab from './CustomerInterationTab';
import SalesInteractionTab from './SalesInteractionTab';
import { Data } from '../../mainconfig';
import PopupAlert from "../popupAlert";
import moment from 'moment';
import { useSelector } from "react-redux";

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;


// import apiCalls from "../../api/apiCalls";
const ComplaintsUser = (props) => {
  const callback = (key) => {

  };
  const loginInfo = useSelector(state => state);
  const [data1, setData1] = useState([]);
  const [clientEnquiryResponse, setClientEnquiryResponse] = useState([]);
  const [atrModal, setAtrModal] = useState(false);
  const [MissellingCalc, setMissellingCalc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [serviceRequestData, setServiceRequestData] = useState(null);
  const [gcpAPIResponseData, setgcpAPIResponseData] = useState(null);
  const [ExceptionhandlingRequiredOutCome, setExceptionhandlingRequiredOutCome] = useState("");
  const [ExceptionhandlingNotRequiredOutCome, setExceptionhandlingNotRequiredOutCome] = useState("");
  const [InFavourOfCustomerPercentage, setInFavourOfCustomerPercentage] = useState("");
  const [NotInFavourOfCustomerPercentage, setNotInFavourOfCustomerPercentage] = useState("");
  const [DeclineOutComePercentage, setDeclineOutComePercentage] = useState("");

  const [ExceptionhandlingRequiredPercentage, setExceptionhandlingRequiredPercentage] = useState("");
  const [ExceptionhandlingNotRequiredPercentage, setExceptionhandlingNotRequiredPercentage] = useState("");
  const [NotInFavourOfCustomerOutCome, setNotInFavourOfCustomerOutCome] = useState("");
  const [InFavourOfCustomerOutCome, setInFavourOfCustomerOutCome] = useState("");
  const [DeclineOutCome, setDeclineOutCome] = useState("");
  const [srvReqID1, setSrvReqID] = useState(0);

  const [ExceptionhandlingRequiredCount, setExceptionhandlingRequiredCount] = useState("");
  const [ExceptionhandlingNotRequiredCount, setExceptionhandlingNotRequiredCount] = useState("");
  const [NotInFavourOfCustomerCount, setNotInFavourOfCustomerCount] = useState("");
  const [InFavourOfCustomerCount, setInFavourOfCustomerCount] = useState("");
  const [DeclineOutComeCount, setDeclineOutComeCount] = useState("");
  const [TotalCount, setTotalCount] = useState("");
  const [comments, setComments] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
 const [responseData,setResponseData]=useState([]);

  const [form] = Form.useForm();

  let { serviceId } = useParams();
  const dateFormat = "DD/MM/YYYY";


  let location = useLocation();

  const [rcahideshow, setRCATabHide] = useState(false);
  const [initialrefund, setInitialrefund] = useState(false);
  const [isReferredToSenior,setisReferredToSenior]=useState(false);
  const [isSeniorCitizen, setSeniorCitizen] = useState(false);
  const [fakeLetter, setFakeLetter] = useState('no');
  const [loginLocation, setLoginLocation] = useState('no');
  const [welcomeChange, setWelcomeChange] = useState(false);
  const [signatureonProposal, setSignatureProposal] = useState('no');
  const [welcomeCall, setWelcomeCall] = useState('no');
  const [freshComplaint, setFreshComplaint] = useState('no');
  const [multiplePolicies, setMultiplePolicies] = useState('no');
  const [customerApproched, setCustomerApproched] = useState('no');
  const [renewalPremiumPaid, setRenewalPremiumPaid] = useState('no');
  const [medicalTestsConducted, setMedicalTestsConducted] = useState('no');
  const [renewalCallPositive, setRenewalCallPositive] = useState('no');
  const [deliveryDone, setDeliveryDone] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [transactionDocData, setTransactionDocData] = useState('');
 

  const [size, setSize] = useState('small');
  const onChange = (e) => {
    setSize(e.target.value);
  };

  const [mode, setMode] = useState('left');
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const formItemLayout = {
    labelCol: {
      span: 4, // adjust the span based on your layout needs
    },
    wrapperCol: {
      span: 16, // adjust the span based on your layout needs
    },
  };
  

  const handleFakeLetterChange = (value) => {
    setFakeLetter(value);
    form.setFieldsValue({fakeLetter: value})
  };
  const handleloginLocationChange = (value) => {
    setLoginLocation(value);
    form.setFieldsValue({loginLocation: value})
  };

  const handleWelcomeChnange = (value) => {
    setWelcomeChange(value);
    form.setFieldsValue({welcomeChange: value})
  };

  const handlesignatureonProposalChange = (value) => {
    setSignatureProposal(value);
    form.setFieldsValue({signatureonProposal: value})
  };
  const handlewelcomeCallChange = (value) => {
    setWelcomeCall(value);
    form.setFieldsValue({welcomeCallChange: value})
  };
  const handlefreshComplaintChange = (value) => {
    setFreshComplaint(value);
    form.setFieldsValue({freshComplaint: value})
  };

  const handlePolicyDeliveryDoneStatus = (value) => {
    setDeliveryDone(value);
    form.setFieldsValue({deliveryDone: value})
  };

  const handlemultiplePoliciesChange = (value) => {
    setMultiplePolicies(value);
    form.setFieldsValue({multiplePolicies: value})
  };
  const handlecustomerApprochedChange = (value) => {
    setCustomerApproched(value);
    form.setFieldsValue({customerApproched: value})
  };
  const handlerenewalPremiumPaidChange = (value) => {
    setRenewalPremiumPaid(value);
    form.setFieldsValue({renewalPremiumPaid: value})
  };

  const handlemedicalTestsConductedChange = (value) => {
    setMedicalTestsConducted(value);
    form.setFieldsValue({medicalTestConducted: value})
  };
  const handlerenewalCallPositiveChange = (value) => {
    setRenewalPremiumPaid(value);
    form.setFieldsValue({renewalCallPositive: value})
  };

  
  const getGrievenceTransactionData = (values) => {
    return [
      {
        "Status": "Create",
        "TagName": "Comp_ATR_comments",
        "TagValue": values.Atrcomments
      },
      {
        "Status": "Create",
        "TagName": "Comp_Misselling_Comments",
        "TagValue": values.MissellingCalc
      },
      {
        "Status": "Create",
        "TagName": "Comp_General_Comments",
        "TagValue": values.generalComments
      }
    ];
  };


  
  const getAtrCommentsTransactionData = (values) => {
    return [
      {
        "Status": "Create",
        "TagName": "Comp_Comments",
        "TagValue": values.Comments
      }
    ];
  };

  const getCloserResponseTransactionData = (values) => {
    return [
      {
        "Status": "Create",
        "TagName": "Tag_Complaint",
        "TagValue": values.Tag_Complaint || ""
      },
      {
        "Status": "Create",
        "TagName": "Response_Mode",
        "TagValue": values.Response_Mode || ""
      },
      {
        "Status": "Create",
        "TagName": "Is_Complaint_Closed",
        "TagValue": values.Is_Complaint_Closed || ""
      }
      // Add additional fields if needed
    ];
  };



  const dummy = (values) => {
    return [
      {
        "Status": "Create",
        "TagName": "Comp_ATR_comments",
        "TagValue": values.Atrcomments
      },
      {
        "Status": "Create",
        "TagName": "Comp_Misselling_Comments",
        "TagValue": values.MissellingCalc
      },
      {
        "Status": "Create",
        "TagName": "Comp_General_Comments",
        "TagValue": values.generalComments
      }
    ];
  };


  const getRCATransactionData = (values) => {
    return [
      { "Status": "Create", "TagName": "Comp_Observations", "TagValue": values?.Observations },
      { "Status": "Create", "TagName": "Comp_Additional_Remarks", "TagValue": values?.Additional_Remarks },
      { "Status": "Create", "TagName": "Comp_Conclusion", "TagValue": values.Conclusion },
      { "Status": "Create", "TagName": "Comp_Additional_Space_for_case_remarks", "TagValue": values.Additional_Space_for_case_remarks },
      { "Status": "Create", "TagName": "Comp_Closure_Remarks", "TagValue": values.Closure_Remarks },
      { "Status": "Create", "TagName": "Comp_Nature_of_Complaint", "TagValue": values.Nature_of_Complaint },
      { "Status": "Create", "TagName": "Comp_Type_of_Error", "TagValue": values.Type_of_Error },
      { "Status": "Create", "TagName": "Comp_Reason_For_Error", "TagValue": values.Reason_For_Error },
      { "Status": "Create", "TagName": "Comp_Root_Cause", "TagValue": values.Root_Cause }
    ];
  };



const getSalesFeedbackTransactionData = (values) => {
  return [
    {
      "Status": "Create",
      "TagName": "Comp_Sales_Comments",
      "TagValue": values.salesComments
    },
    {
      "Status": "Create",
      "TagName": "Comp_Sales_Feedback",
      "TagValue": values.SalesFeedback
    }
  ];
};


const handleRCASave = async () => {
  
  try {
    // Validate and retrieve form values
    await form.validateFields(); // Ensure all fields are validated
    const formData = form.getFieldsValue(); // Get all field values

    const obj = {
      srvReqRefNo: serviceId,
      TransactionData: getRCATransactionData(formData) || [],
      srvReqID: srvReqID1,
      ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
      RequestDateTime: "2024-01-10T00:00:00", // Update as needed
      CustSignDateTime: "2024-01-10T00:00:00" // Update as needed
    };

    // Make API call
    const response = await apiCalls.genericAPI(obj);
    if (response?.status === 200) { // Ensure response.status is available in your API response
      setAlertTitle(response?.data?.header);
      setAlertData(response?.data?.message);
      setShowAlert(true);
    }

    // Fetch additional data
    const val = await apiCalls.getPOSIndividualData(serviceId);
    setFormFieldsData(val);

  } catch (error) {
    console.error('API call failed:', error);
  }
};


  const getTransactionData = (values) => {
      
    
      return [
        {
          "Status": "Create",
          "TagName": "Comp_LA_Age",
          "TagValue": values.age
        },
        {
          "Status": "Create",
          "TagName": "Comp_PO_Age",
          "TagValue": values.poage
        },
        {
          "Status": "Create",
          "TagName": "Comp_Payor_Age",
          "TagValue": values.payorage
        },
        {
          "Status": "Create",
          "TagName": "Comp_Income",
          "TagValue": values.laIncome
        },
        {
          "Status": "Create",
          "TagName": "Comp_Occu_payor",
          "TagValue": values.laOccupation
        },
        {
          "Status": "Create",
          "TagName": "Comp_Rated_up",
          "TagValue": values.isNormalOrRateup
        },
        {
          "Status": "Create",
          "TagName": "Comp_PLVC_Done",
          "TagValue": values.totalPremiumDue
        },
        {
          "Status": "Create",
          "TagName": "Comp_Welcome_Calling",
          "TagValue": values.welcomeCall
        },
        {
          "Status": "Create",
          "TagName": "Comp_Login_Location",
          "TagValue": values.loginLocation
        },
        {
          "Status": "Create",
          "TagName": "Comp_Signature_Proposal",
          "TagValue": values.signatureonProposal
        },
        {
          "Status": "Create",
          "TagName": "Comp_PIVC_Welcome",
          "TagValue": values.pivcStatus
        },
        {
          "Status": "Create",
          "TagName": "Comp_Policy_Delivery",
          "TagValue": values.deliv
        },
        {
          "Status": "Create",
          "TagName": "Comp_Fresh_Complaint",
          "TagValue": values.freshComplaint
        },
        {
          "Status": "Create",
          "TagName": "Comp_Multiple_policies",
          "TagValue": values.multiplePolicies
        },
        {
          "Status": "Create",
          "TagName": "Comp_Customer_Approched",
          "TagValue": values.customerApproched
        },
        {
          "Status": "Create",
          "TagName": "Comp_Renewal_Premium_paid",
          "TagValue": values.renewalPremiumPaid
        },
        {
          "Status": "Create",
          "TagName": "Comp_MedicalTests_Conducted",
          "TagValue": values.medflag
        },
        {
          "Status": "Create",
          "TagName": "Comp_Renewal_Call_positive",
          "TagValue": values.renewalCallPositive
        },

      ];
    
  }

  const handleAtrCommentsSave = async () => {
    const formData = form.getFieldsValue();
    console.log('Form Data on Save:', formData); // Check this log to see if 'Comments' is populated
  
    const obj = {
      TransactionData: getAtrCommentsTransactionData(formData) || [],
      srvReqID: srvReqID1,
      ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
      RequestDateTime: "2024-01-10T00:00:00",
      CustSignDateTime: "2024-01-10T00:00:00",
      srvReqRefNo: serviceId
    };
  
    console.log('Object to be sent:', obj);
  
    try {
      const response = await apiCalls.genericAPI(obj);
      if (response?.status === 200) {
        setAlertTitle(response?.data?.header);
        setAlertData(response?.data?.message);
        setShowAlert(true);
      }
      
      const val = await apiCalls.getPOSIndividualData(serviceId);
      setFormFieldsData(val?.data);
    } catch (error) {
      console.error('API Error:', error);
    }
  }
  
  

  const handleMiscSave = async () => {
    try {
        const formData = form.getFieldValue();
        const obj = {
            TransactionData: getTransactionData(formData) || [],
            srvReqID: srvReqID1,
            ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
            RequestDateTime: "2024-01-10T00:00:00",
            CustSignDateTime: "2024-01-10T00:00:00",
            srvReqRefNo: serviceId
        };

        // First API call
        const response = await apiCalls.genericAPI(obj);
        
        if (response?.status === 200) {
            setAlertTitle(response?.data?.header);
            setAlertData(response?.data?.message);
            setShowAlert(true);

            // Second API call
            const val = await apiCalls.getPOSIndividualData(serviceId);
            setTransactionData(val?.data);
            setData1(val?.data?.misSellingResult);
        }
    } catch (error) {
        console.error("Error occurred while saving:", error);
        // Handle error (e.g., show an alert or notification)
    }
};


  const formItemLayout2 = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const billFreq={
    '01':'Annual',
    '02' : 'Semi Annual',
    '04': 'Quarterly ',
    '12' : 'Monthly',
}

  useEffect(() => {
    getPOSIndividualData()
  }, []);

  const convertDate = (inputDate) => {
    const formattedDate = moment(inputDate, 'YYYYMMDD').format('DD/MM/YYYY');
    return formattedDate;
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
 
  const handleSalesFeedbackSave = async () => {
    // Retrieve form values
    const formData = form.getFieldsValue(); // Use getFieldsValue to get all field values
    
    const obj = {
      srvReqRefNo: serviceId,
      TransactionData: getSalesFeedbackTransactionData(formData) || [],
      srvReqID: srvReqID1, // Ensure srvReqID1 is defined in your context
      ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName, // Ensure loginInfo is defined
      RequestDateTime: "2024-01-10T00:00:00",
      CustSignDateTime: "2024-01-10T00:00:00"
    };

    try {
      // Make API call
      const response = await apiCalls.genericAPI(obj);
      if (response?.status === 200) { // Ensure response.status is available in your API response
        setAlertTitle(response?.data?.header);
        setAlertData(response?.data?.message);
        setShowAlert(true);
        // Handle successful response
      }
      
      // Fetch additional data
      const val = await apiCalls.getPOSIndividualData(serviceId); // Ensure serviceId is defined
      setFormFieldsData(val); // Ensure setTransactionData is defined
    } catch (error) {
      console.error('API call failed:', error);
    }
  };

  const getRefundTransactionData = (values) => {
    return [
      { "Status": "Create", "TagName": "IFSC", "TagValue": values.IFSC },
      { "Status": "Create", "TagName": "Account_Holders_Name", "TagValue": values.Account_Holders_Name },
      { "Status": "Create", "TagName": "Bank_Account_Number", "TagValue": values.Bank_Account_Number },
      { "Status": "Create", "TagName": "Re_enter_Account_Number", "TagValue": values.Re_enter_Account_Number },
      { "Status": "Create", "TagName": "Bank", "TagValue": values.Bank },
      { "Status": "Create", "TagName": "Penny_Drop_Result", "TagValue": values.Penny_Drop_Result },
      { "Status": "Create", "TagName": "Branch_Name", "TagValue": values.Branch_Name }
    ];
  };
  

  const handleWelcomeCallChange = (checked) => {
    // Update the state with the new value of the switch
    setWelcomeCall(checked);

    // Update the form's field value
    form.setFieldsValue({ welcomeChange: checked });
  };

  const getApproverCommentsTransactionData = (values) => {
    return [
      {
        "Status": "Create",
        "TagName": "Comp_Approver_Comments",
        "TagValue": values.ApproverComments
      },
      {
        "Status": "Create",
        "TagName": "Comp_Approver_Feedback",
        "TagValue": values.ApproverFeedback
      },
      {
        "Status": "Create",
        "TagName": "Comp_Refer_to_Senior",
        "TagValue": values.RefertoSenior
      },
      {
        "Status": "Create",
        "TagName": "dateOfApproval",
        "TagValue": values.dateOfApproval
      },
      
    ];
  };

  function formatDate(dateString) {
    const year = dateString?.slice(0, 4);  // Extract year
    const month = dateString?.slice(4, 6); // Extract month
    const day = dateString?.slice(6, 8);   // Extract day
    return `${year}/${month}/${day}`;     // Format as YYYY/MM/DD
  }


  const handleApproverCommentsSave = async () => {
    // Retrieve form values
    const formData = form.getFieldsValue(); // Use getFieldsValue to get all field values
    
    const obj = {
      srvReqRefNo: serviceId,
      TransactionData: getApproverCommentsTransactionData(formData) || [],
      srvReqID: srvReqID1, // Ensure srvReqID1 is defined in your context
      ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName, // Ensure loginInfo is defined
      RequestDateTime: "2024-01-10T00:00:00",
      CustSignDateTime: "2024-01-10T00:00:00"
    };

    try {
      // Make API call
      const response = await apiCalls.genericAPI(obj);
      if (response?.status === 200) { // Ensure response.status is available in your API response
        setAlertTitle(response?.data?.header);
        setAlertData(response?.data?.message);
        setShowAlert(true);
      }
      
      // Fetch additional data
      const val = await apiCalls.getPOSIndividualData(serviceId); // Ensure serviceId is defined
       setFormFieldsData(val); // Ensure setTransactionData is defined
    } catch (error) {
      console.error('API call failed:', error);
    }
  };


  const handleRefundSave = async () => {
    try {
      // Validate and retrieve form values
      await form.validateFields(); // Ensure all fields are validated
      const formData = form.getFieldsValue(); // Get all field values

      const obj = {
        srvReqRefNo: serviceId,
        TransactionData: getRefundTransactionData(formData) || [],
        srvReqID: srvReqID1,
        ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
        RequestDateTime: new Date().toISOString(),
        CustSignDateTime: new Date().toISOString()
      };

      // Make API call
      const response = await apiCalls.genericAPI(obj);
      if (response?.status === 200) { // Ensure response.status is available in your API response
        setAlertTitle(response?.data?.header);
        setAlertData(response?.data?.message);
        setShowAlert(true);
      }
      

      // Fetch additional data
      const val = await apiCalls.getPOSIndividualData(serviceId);
      setFormFieldsData(val);

    } catch (error) {
      console.error('API call failed:', error);
    }
  };

  
// const setGrievenceUserData = (val) => {
//   if(val?.data){
//     if(val?.data?.serviceRequestTransectionData){
//       val?.data?.serviceRequestTransectionData?.forEach((ele)=>{
//         if(ele.tagName==='Comp_ATR_comments'){
//          form.setFieldValue(Atrcomments, ele.tagValue)
//         }
//         if(ele.tagName==='Comp_Misselling_Comments'){
//           form.setFieldValue(MissellingCalc, ele.tagValue)
//          }
//          if(ele.tagName==='Comp_General_Comments'){
//           form.setFieldValue(generalComments, ele.tagValue)
//          }
//          if(ele.tagName==='Comp_Sales_Feedback'){
//           form.setFieldValue(SalesFeedback, ele.tagValue)
//          }
//          if(ele.tagName==='Comp_Sales_Comments'){
//           form.setFieldValue(salesComments, ele.tagValue)
//          }
//          if(ele.tagName==='IFSC'){
//           form.setFieldValue(IFSC, ele.tagValue)
//          }
//       })
//   }
// }
// }

const setFormFieldsData = (val) => {
  
  if (val?.data) {
    // Define a mapping of tag names to form field names
    const fieldMapping = {
      'IFSC': 'IFSC',
      'Account_Holders_Name': 'Account_Holders_Name',
      'Bank_Account_Number': 'Bank_Account_Number',
      'Re_enter_Account_Number': 'Re_enter_Account_Number',
      'Bank': 'Bank',
      'Penny_Drop_Result': 'Penny_Drop_Result',
      'Branch_Name': 'Branch_Name',
      'Comp_ATR_comments': 'Atrcomments',
      'Comp_Misselling_Comments': 'MissellingCalc',
      'Comp_General_Comments': 'generalComments',
      'Comp_Sales_Feedback': 'SalesFeedback',
      'Comp_Sales_Comments': 'salesComments',
      'Comp_Approver_Comments': 'ApproverComments',
      'Comp_Approver_Feedback': 'ApproverFeedback',
      'Comp_Observations': 'Observations',
      'Comp_Additional_Remarks': 'Additional_Remarks',
      'Comp_Conclusion': 'Conclusion',
      'Comp_Additional_Space_for_case_remarks': 'Additional_Space_for_case_remarks',
      'Comp_Closure_Remarks': 'Closure_Remarks',
      'Comp_Nature_of_Complaint': 'Nature_of_Complaint',
      'Comp_Type_of_Error': 'Type_of_Error',
      'Comp_Reason_For_Error': 'Reason_For_Error',
      'Comp_Comments': 'Comments',
      'Comp_Root_Cause': 'Root_Cause',
      'Tag_Complaint' : 'Tag_Complaint',
      'Response_Mode' : 'Response_Mode',
      'Is_Complaint_Closed' : 'Is_Complaint_Closed'
      // Add other mappings as needed
    };

    // Initialize an object to hold form field values
    const formValues = {};

    // Iterate over the transaction data and populate the formValues object
    val?.data?.serviceRequestTransectionData?.forEach((ele) => {
      const fieldName = fieldMapping[ele.tagName];
      if (fieldName) {
        formValues[fieldName] = ele.tagValue;
      }
    });

    // Set the form field values
    form.setFieldsValue(formValues);
  }
};




const grievenceUserSave = async () => {
  // Retrieve form values
  
  const formData = form.getFieldsValue(); // Use getFieldsValue to get all field values
  
  const obj = {
    srvReqRefNo : serviceId,
    TransactionData: getGrievenceTransactionData(formData) || [],
    srvReqID: srvReqID1, // Make sure srvReqID1 is defined in your context
    ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName, // Ensure loginInfo is defined
    RequestDateTime: "2024-01-10T00:00:00",
    CustSignDateTime: "2024-01-10T00:00:00"
  };

  try {
    // Make API call
    const response = await apiCalls.genericAPI(obj);
    if (response?.status === 200) { // Ensure response.status is available in your API response
      setAlertTitle(response?.data?.header);
      setAlertData(response?.data?.message);
      setShowAlert(true);
    }
    
    // Fetch additional data
    const val = await apiCalls.getPOSIndividualData(serviceId); // Ensure serviceId is defined
    setFormFieldsData(val?.data);
  } catch (error) {
    console.error('API call failed:', error);
  }
};

const handleCheckboxChange = (e) => {
  setIsCheckboxChecked(e.target.checked);
};


const CloserResponseSave = async () => {
  try {
    // Validate and retrieve form values
    await form.validateFields(); // Ensure all fields are validated
    const formData = form.getFieldsValue(); // Get all field values

    const obj = {
      srvReqRefNo: serviceId,
      TransactionData: getCloserResponseTransactionData(formData) || [],
      srvReqID: srvReqID1,
      ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
      RequestDateTime: "2024-01-10T00:00:00", // Update as needed
      CustSignDateTime: "2024-01-10T00:00:00" // Update as needed
    };

    // Make API call
    const response = await apiCalls.genericAPI(obj);
    if (response?.status === 200) { // Ensure response.status is available in your API response
      setAlertTitle(response?.data?.header);
      setAlertData(response?.data?.message);
      setShowAlert(true);
    }

    // Fetch additional data
    const val = await apiCalls.getPOSIndividualData(serviceId);
    setFormFieldsData(val);

  } catch (error) {
    console.error('API call failed:', error);
  }
};


const setTransactionData = (obj) => {
  setSrvReqID(obj?.srvReqID);
  if (obj?.serviceRequestTransectionData) {
      obj?.serviceRequestTransectionData.forEach((ele) => {
          switch (ele.tagName) {
              case 'ExceptionhandlingRequiredOutCome':
                  setExceptionhandlingRequiredOutCome(ele.tagValue);
                  break;
              case 'ExceptionhandlingNotRequiredOutCome':
                  setExceptionhandlingNotRequiredOutCome(ele.tagValue);
                  break;
              case 'NotInFavourOfCustomerOutCome':
                  setNotInFavourOfCustomerOutCome(ele.tagValue);
                  break;
              case 'InFavourOfCustomerOutCome':
                  setInFavourOfCustomerOutCome(ele.tagValue);
                  break;
              case 'ExceptionhandlingRequiredPercentage':
                  setExceptionhandlingRequiredPercentage(ele.tagValue);
                  break;
              case 'ExceptionhandlingNotRequiredPercentage':
                  setExceptionhandlingNotRequiredPercentage(ele.tagValue);
                  break;
              case 'InFavourOfCustomerPercentage':
                  setInFavourOfCustomerPercentage(ele.tagValue);
                  break;
              case 'NotInFavourOfCustomerPercentage':
                  setNotInFavourOfCustomerPercentage(ele.tagValue);
                  break;
              case 'ExceptionhandlingRequiredCount':
                  setExceptionhandlingRequiredCount(ele.tagValue);
                  break;
              case 'ExceptionhandlingNotRequiredCount':
                  setExceptionhandlingNotRequiredCount(ele.tagValue);
                  break;
              case 'NotInFavourOfCustomerCount':
                  setNotInFavourOfCustomerCount(ele.tagValue);
                  break;
              case 'InFavourOfCustomerCount':
                  setInFavourOfCustomerCount(ele.tagValue);
                  break;
              case 'DeclineOutComeCountPercentage':
                  setDeclineOutComePercentage(ele.tagValue);
                  break;
              case 'DeclineOutComeCount':
                  setDeclineOutComeCount(ele.tagValue);
                  break;
              case 'DeclineOutCome':
                  setDeclineOutCome(ele.tagValue);
                  break;
              case 'TotalOutcomeCount':
                  setTotalCount(ele.tagValue);
                  break;
              default:
                  break;
          }
      });
  }
};

const getPOSIndividualData = async () => {
  try {
    setIsLoading(true);

    const val = await apiCalls.getPOSIndividualData(serviceId);
    const srvReqIDFromData = val?.data?.srvReqID; // Extract srvReqID from val.data

    // Update state and form values with srvReqID
    setSrvReqID(srvReqIDFromData);
    form.setFieldsValue({ srvReqID: srvReqIDFromData });

    // Set other data states
    setServiceRequestData(val.data);
    setTransactionData(val?.data);
    setFormFieldsData(val);

    const [gcpPolicyResponse, headerParametersResponse] = await Promise.all([
      apiCalls.getFreeLookDetailsApi( process.env.REACT_APP_ENVIRONMENT == "PRODUCTION" ? val.data.policyNo : '01817159'),
      apiCalls.getHeaderParameters({
        policyNo: process.env.REACT_APP_ENVIRONMENT == "PRODUCTION" ? val.data.policyNo : val.data.policyNo,
        applicationNo: '',
        dob: val.data.dob
      })
    ]);

    if (gcpPolicyResponse.data.statusCode !== '400') {
      setgcpAPIResponseData(gcpPolicyResponse?.data);

      const clientAttribute = gcpPolicyResponse?.data?.response?.clientAttribute[0];
      const policyAttribute = gcpPolicyResponse?.data?.response?.policyAttribute[0];
      const applicationAttribute = gcpPolicyResponse?.data?.response?.applicationAttribute;

      form.setFieldsValue({
        laincome: clientAttribute?.laIncome,
        medflag: policyAttribute?.medflag,
        isitratedup: policyAttribute?.isNormalOrRateup,
        npdd: new Date(convertDate(applicationAttribute?.npdd)) > new Date() ? 'yes' : 'no',
        pivcStatus: policyAttribute?.pivcStatus
      });
    }

    if (headerParametersResponse?.data?.responseHeader?.issuccess) {
      setData(headerParametersResponse?.data?.responseBody);

      const clientNumber = {
        clientNumber: headerParametersResponse?.data?.responseBody?.identifiers?.la_ClientID === headerParametersResponse?.data?.responseBody?.identifiers?.po_ClientID
          ? headerParametersResponse?.data?.responseBody?.identifiers?.la_ClientID
          : headerParametersResponse?.data?.responseBody?.identifiers?.po_ClientID
      };

      const clientEnquiryResponse = await apiCalls.getClientEnquiry(clientNumber,loginInfo?.userProfileInfo?.profileObj?.allRoles[0]?.employeeID);
      if (clientEnquiryResponse.data.responseHeader.issuccess) {
        setClientEnquiryResponse(clientEnquiryResponse.data.responseBody);
        const dob = clientEnquiryResponse.data.responseBody.clTdob;
        const newDob = convertDate(dob);
        const dobDate = new Date(newDob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDifference = today.getMonth() - dobDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dobDate.getDate())) {
          age--;
        }
        if (age > 65) {
          setSeniorCitizen(true);
        }

        form.setFieldsValue({
          age: age,
          poage: age,
          dob: dob
        });
      }
    } else {
      message.destroy();
      message.error({
        content: headerParametersResponse?.data?.responseBody?.errormessage || "Something went wrong please try again!",
        className: "custom-msg",
        duration: 2,
      });
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle errors appropriately
  } finally {
    setIsLoading(false);
  }
};






  return (
    <>
      <Spin spinning={isLoading} >
        {serviceRequestData && <>
        <div className='complaints-section'>
          <div >
            <br />
            {/* <Row className='sec-dark' gutter={16} style={{ width: '100%', margin: '0px 16px', padding: '10px' }}>
              <Col style={{ width: '20%' }}>
                <div className='boxx'>
                  <div>
                    <p ><b> Policy No</b></p>
                    <p >{data?.identifiers?.policyNo}</p>
                  </div>
                  <div >
                    <p ><b> App No</b></p>
                    <p >{data?.identifiers?.applicationNo}</p>
                  </div>
                  <div >
                    <p ><b> LA Name</b></p>
                    <p >{data?.identifiers?.la_Name}</p>
                  </div>
                  <div >
                    <p ><b> PO Name</b></p>
                    <p >Graduate</p>
                  </div>
                </div>
              </Col>
              <Col style={{ width: '20%' }}>
                <div className='boxx'>
                  <div >
                    <p ><b> Customer Type</b></p>
                    <p >{data?.planAndStatus?.customerType}</p>
                  </div>
                  <div >
                    <p ><b> Plan Name(ULIP/Non ULIP)</b></p>
                    <p >{data?.planAndStatus?.planName}</p>
                  </div>
                  <div >
                    <p ><b> Policy  Status</b></p>
                    <p >{data?.planAndStatus?.policyStatus}</p>
                  </div>
                  <div >
                    <p ><b> Premium Status</b></p>
                    <p >{data?.planAndStatus?.premiumStatus}</p>
                  </div>
                </div>
              </Col>
              <Col style={{ width: '20%' }}>
                <div className='boxx'>
                  <div >
                    <p ><b> Sum Assured</b></p>
                    <p >{data?.saDetails?.sumAssured}</p>
                  </div>
                  <div >
                    <p ><b> PT </b> </p>
                    <p >{data?.saDetails?.pt}</p>
                  </div>
                  <div >
                    <p ><b> RCD</b></p>
                    <p >{data?.saDetails?.rcd}</p>
                  </div>
                  <div >
                    <p ><b> Assignment</b></p>
                    <p >{data?.saDetails?.assignment}</p>
                  </div>
                </div>
              </Col>
              <Col style={{ width: '20%' }}>
                <div className='boxx'>
                  <div >
                    <p ><b> Model Premium Amount</b></p>
                    <p >{data?.premiumDetails?.modelPremiumAmount}</p>
                  </div>
                  <div >
                    <p ><b>PPT </b> </p>
                    <p >{data?.premiumDetails?.ppt}</p>
                  </div>
                  <div >
                    <p ><b> PTD</b></p>
                    <p >{data?.premiumDetails?.ptd}</p>
                  </div>
                  <div >
                    <p ><b> Mode</b></p>
                    <p >{data?.premiumDetails?.mode}</p>
                  </div>
                </div>
              </Col>

              <Col style={{ width: '20%' }}>
                <div className='boxx'>
                  <div >
                    <p ><b> Branch</b></p>
                    <p >{data?.salesDetails?.branch}</p>
                  </div>
                  <div >
                    <p ><b>Channel </b> </p>
                    <p >{data?.salesDetails?.channel}</p>
                  </div>
                  <div >
                    <p ><b> Agent Name</b></p>
                    <p >{data?.salesDetails?.agentName}</p>
                  </div>
                  <div >
                    <p ><b> Orphan Flag</b></p>
                    <p >{data?.salesDetails?.orphanFlag
                    }</p>
                  </div>
                </div>
              </Col>
            </Row> */}
            <CustomerDetails serviceId={serviceId} isComplaintsUser={true}  serviceRequestData = {serviceRequestData} setServiceRequestData={setServiceRequestData} transactionDocData={transactionDocData}/>

          </div>


          {/* <div className='tabs-begin' style={{ width: '77%', float: 'left', margin: '16px 16px' }}> */}



          <Tabs tabPosition="left" type="card">
            <TabPane
              tab={
                <span>

                  View Customer Complaint
                </span>
              }
              key="1"
            >
              
              <ViewCustomerComplaintTab serviceRequestData={serviceRequestData} setRCATabHide={setRCATabHide} setTransactionDocData={setTransactionDocData}/>
            </TabPane>
            <TabPane
              tab="Customer Interaction"
              key="2"
            >
              <CustomerInterationTab serviceRequestData={serviceRequestData} data={data}/>
            </TabPane>
            <TabPane
              tab="Sales Interaction"
              key="3"
            >
              <SalesInteractionTab serviceRequestData={serviceRequestData}/>
            </TabPane>
            <TabPane
      tab={
        <span>Grievance Officer Remarks</span>
      }
      key="12"
    >
      <div style={{ width: '77%', float: 'left', margin: '16px 0px' }}>
        <Form form={form} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <div style={{ width: '100%' }}>
            <Form.Item label="ATR comments" name="Atrcomments" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
              <Input.TextArea placeholder="ATR comments" />
            </Form.Item>
          </div>
          <div style={{ width: '100%' }}>
            <Form.Item label="Misselling Calculator Comments" name="MissellingCalc" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
              <Input.TextArea placeholder="Misselling Calculator Comments" />
            </Form.Item>
          </div>
          <div style={{ width: '100%' }}>
            <Form.Item label="General Comments/Recomentation" name="generalComments" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
              <Input.TextArea placeholder="General Comments/Recomentation" />
            </Form.Item>
          </div>
          <div className="contact-details-btn" style={{ marginTop: '16px' }}>
            <Button type="primary" className="primary-btn mt-4 me-3" htmlType="submit" onClick={grievenceUserSave}>
              Save
            </Button>
          </div>
        </Form>
      </div>
      {/* <div style={{ width: '17%', float: 'left', margin: '16px 0px' }}>
        <Widgets />
      </div> */}
    </TabPane>
            <TabPane
              tab={
                <span>
                  ATR
                </span>
              }
              key="13"
            >
              <div className='tabs-begin' style={{ width: '77%', float: 'left', margin: '16px 16px' }}>
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="reuirement" style={{ width: "100%" }}>

                      <Collapse expandIconPosition="end" defaultActiveKey={['1']} onChange={callback} accordion>
                        <Panel header="Personal Information" key="1">


                          <Row gutter={16} style={{ width: '100%', padding: '10px' }}>
                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Name Of LA</b></p>
                                  <p >{data?.identifiers?.la_Name}</p>
                                </div>
                                <div >
                                  <p ><b> DOB</b></p>
                                  <p >{clientEnquiryResponse.clTdob ? formatDate(clientEnquiryResponse.clTdob) : "NA"}</p>
                                </div>
                                {/* <div >
        <p ><b> City Name</b></p>
        <p >MRS Babita Mittal</p>
      </div> */}
                                <div >
                                  <p ><b> Contact Details</b></p>
                                  <p >{clientEnquiryResponse.rmblphone}</p>
                                </div>
                                <div >
                                  <p ><b> Income of LA</b></p>
                                  <p >{gcpAPIResponseData?.response?.clientAttribute[0]?.laIncome}</p>
                                </div>
                                <div >
                                  <p ><b> Occupation of LA</b></p>
                                  <p >{gcpAPIResponseData?.response?.clientAttribute[0]?.laOccupation}</p>
                                </div>

                              </div>
                            </Col>
                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Name Of Proposer</b></p>
                                  <p >{data?.identifiers?.po_Name}</p>
                                </div>
                                <div >
                                  <p ><b> DOB</b></p>
                                  <p >{clientEnquiryResponse.clTdob ? formatDate(clientEnquiryResponse.clTdob) : "NA"}</p>
                                </div>
                                {/* <div >
        <p ><b> City Name</b></p>
        <p >MRS Babita Mittal</p>
      </div> */}
                                <div >
                                  <p ><b> Contact Details</b></p>
                                  <p >{clientEnquiryResponse.rmblphone}</p>
                                </div>
                                <div >
                                  <p ><b> Income of Proposer</b></p>
                                  <p >{gcpAPIResponseData?.response?.clientAttribute[0]?.proposerIncome}</p>
                                </div>
                                <div >
                                  <p ><b> Occupation of Proposer</b></p>
                                  <p >{gcpAPIResponseData?.response?.clientAttribute[0]?.proposerOccupation}</p>
                                </div>

                              </div>
                            </Col>


                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Name Of Payor</b></p>
                                  <p >{data?.identifiers?.po_Name}</p>
                                </div>
                                <div >
                                  <p ><b> DOB</b></p>
                                  <p >{clientEnquiryResponse.clTdob ? formatDate(clientEnquiryResponse.clTdob) : "NA"}</p>
                                </div>
                                {/* <div >
        <p ><b> City Name</b></p>
        <p >MRS Babita Mittal</p>
      </div> */}
                                <div >
                                  <p ><b> Contact Details</b></p>
                                  <p >{clientEnquiryResponse.rmblphone}</p>
                                </div>
                                <div >
                                  <p ><b> Income of Payor</b></p>
                                  <p >{gcpAPIResponseData?.response?.clientAttribute[0]?.payorIncome}</p>
                                </div>
                                <div >
                                  <p ><b> Occupation of Payor</b></p>
                                  <p >{gcpAPIResponseData?.response?.clientAttribute[0]?.payorOccupation}</p>
                                </div>

                              </div>
                            </Col>


                          </Row>



                        </Panel>
                        <Panel header="Product Related Information" key="2">
                          <Row gutter={16} style={{ width: '100%', padding: '10px' }}>
                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Policy Type</b></p>
                                  <p >{data?.planAndStatus?.policyType}</p>
                                </div>
                                <div >
                                  <p ><b> Frequency</b></p>
                                  <p >{billFreq[data?.premiumDetails?.billFreq]}</p>
                                </div>
                                <div >
                                  <p ><b> Channel</b></p>
                                  <p >{data?.salesDetails?.channel}</p>
                                </div>
                              </div>
                            </Col>
                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Policy Premium</b></p>
                                  <p >{data?.premiumDetails?.modelPremiumAmount}</p>
                                </div>
                                <div >
                                  <p ><b> Date of Isssuance</b></p>
                                  <p >{data?.saDetails?.rcd}</p>
                                </div>
                                <div >
                                  <p ><b> Agent Name</b></p>
                                  <p >{data?.salesDetails?.agentName}</p>
                                </div>
                              </div>
                            </Col>

                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> SA</b></p>
                                  <p >{data?.saDetails?.sumAssured}</p>
                                </div>
                                <div >
                                  <p ><b> NPDD</b></p>
                                  <p >{gcpAPIResponseData?.response?.applicationAttribute[0]?.npdd}</p>
                                </div>
                                <div >
                                  <p ><b> Advisor Status</b></p>
                                  <p >{gcpAPIResponseData?.response?.applicationAttribute[0]?.npdd}</p>
                                </div>
                              </div>
                            </Col>


                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Normal / Rated Up</b></p>
                                  <p >{gcpAPIResponseData?.response?.policyAttribute[0]?.isNormalOrRateup}</p>
                                </div>
                                <div >
                                  <p ><b> Policy status</b></p>
                                  <p >{data?.planAndStatus?.policyStatus}</p>
                                </div>
                                <div >
                                  <p ><b> Auto Pay</b></p>
                                  <p ></p>
                                </div>
                              </div>
                            </Col>

                          </Row>

                        </Panel>
                        <Panel header="Issuance Related Information" key="3">
                          <Row gutter={16} style={{ width: '100%', padding: '10px' }}>
                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Proposal Sign Date</b></p>
                                  <p >{gcpAPIResponseData?.response?.applicationAttribute[0]?.proposalsigndate}</p>
                                </div>
                                <div >
                                  <p ><b> Medical Tests conducted</b></p>
                                  <p >{gcpAPIResponseData?.response?.policyAttribute[0]?.medflag}</p>
                                </div>
                                <div >
                                  <p ><b> Income Proof</b></p>
                                  <p ></p>
                                </div>
                              </div>
                            </Col>

                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Proposal Logged Date</b></p>
                                  <p >{gcpAPIResponseData?.response?.applicationAttribute[0]?.proposalloggeddate}</p>
                                </div>
                                <div >
                                  <p ><b> Welcome calling done</b></p>
                                  <p ></p>
                                </div>
                                <div >
                                  <p ><b> Any Questionnaire submitted</b></p>
                                  <p ></p>
                                </div>
                              </div>
                            </Col>



                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Login Location</b></p>
                                  <p ></p>
                                </div>
                                <div >
                                  <p ><b> Age proof </b></p>
                                  <p > </p>
                                </div>
                                <div >
                                  <p ><b> PIVC</b></p>
                                  <p >{gcpAPIResponseData?.response?.policyAttribute[0]?.pivcStatus}</p>
                                </div>
                              </div>
                            </Col>


                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Mode of Premium</b></p>
                                  <p >{billFreq[data?.premiumDetails?.billFreq]}</p>
                                </div>
                                <div >
                                  <p ><b>Address Proof </b></p>
                                  <p > </p>
                                </div>
                                <div >
                                  <p ><b> F2F Done</b></p>
                                  <p >{gcpAPIResponseData?.response?.clientAttribute[0]?.f2f}</p>
                                </div>
                              </div>
                            </Col>


                          </Row>
                        </Panel>

                        <Panel header="Dispatch Related Information" key="4">
                          <Row gutter={16} style={{ width: '100%', padding: '10px' }}>
                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Date of Dispatch</b></p>
                                  <p >{gcpAPIResponseData?.response?.policyAttribute[0]?.pivcStatus}</p>
                                </div>
                                <div >
                                  <p ><b> Within FLC Window</b></p>
                                  <p ></p>
                                </div>

                              </div>
                            </Col>

                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Mode Of Dispatch</b></p>
                                  <pre >{gcpAPIResponseData?.response?.dispatch_details[0]?.dispatchMode}</pre>
                                </div>
                                <div >
                                  <p ><b> Any endorsements done </b></p>
                                  <p ></p>
                                </div>

                              </div>
                            </Col>



                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b> Delivery Date</b></p>
                                  <p >{gcpAPIResponseData?.response?.dispatch_details[0]?.dispatchDeliveryDate}</p>
                                </div>
                                <div >
                                  <p ><b> Is  customer related to Employee / Agent </b></p>
                                  <p ></p>
                                </div>

                              </div>
                            </Col>


                            <Col style={{ width: '50%' }}>
                              <div className='atrbox'>
                                <div>
                                  <p ><b>Delivery Status</b></p>
                                  <p >{gcpAPIResponseData?.response?.delivery_details[0]?.dispatchStatus}</p>
                                </div>
                                <div >
                                  <p ><b>AWB No. </b></p>
                                  <p >{gcpAPIResponseData?.response?.delivery_details[0]?.airwayBillNo}</p>
                                </div>

                              </div>
                            </Col>


                          </Row>
                        </Panel>





                      </Collapse>


                    </div>
                  </div>
                </div>
                <Form>
                  <div style={{ width: '100%' }} className='mt-3'>
                  <Form.Item label="Comments" name="Comments" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
  <Input.TextArea placeholder="Comments" onChange={(e) => setComments(e.target.value)} value={comments} />
</Form.Item>
                  </div>
                </Form>
                <div className="contact-details-btn" style={{ marginTop: '16px' }}>
                  <Button type="primary" className="primary-btn mt-4 me-3" htmlType="submit" onClick={handleAtrCommentsSave}
                  >
                    Save
                  </Button>
                </div>
              </div>
              {/* <div style={{ width: '17%', float: 'left', margin: '16px 0px' }}>

                <Widgets />


              </div> */}
            </TabPane>
            <TabPane
              tab={
                <span>
                  Misselling Calculator
                </span>
              }
              key="4"
            >
            <div className='tabs-begin' style={{ width: '77%', float: 'left', margin: '16px 16px' }}>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="reuirement">
            <Collapse expandIconPosition="end" accordion>
              <div style={{ padding: '10px', width: '100%', height: '430px', overflow: 'auto' }}>
                <Form form={form} layout="vertical">
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    
                    {/* Example of a 2-column layout */}
                    <div style={{ width: '50%' }}>
                      <Form.Item 
                        label="LA age" 
                        name="age"
                        extra={isSeniorCitizen ? <div style={{ color: 'green' }}>Senior Citizen</div> : ''}
                      >
                        <Input type="text" placeholder="LA age" disabled />
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item label="Proposer Age" name='poage'>
                        <Input type="text" placeholder="Proposer Age" disabled />
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item label="Payer age">
                        <Input type="text" placeholder="Payer age" />
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item label="Income" name="laincome" initialValue={gcpAPIResponseData?.response?.clientAttribute[0]?.laIncome}>
                        <Input type="text" placeholder="Income" />
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item label="Occupation of Payor" initialValue={gcpAPIResponseData?.response?.clientAttribute[0]?.payorOccupation}>
                        <Input type="text" placeholder="Occupation of Payor" disabled />
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item label="Is it Rated up" name="isitratedup">
                        <Input type="text" placeholder="Is it Rated up" disabled />
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item label="Fake Letter issued to customer /Call recording" name='fakeLetter'>
                        <Select 
                          placeholder="Fake Letter issued to customer /Call recording" 
                          value={fakeLetter} 
                          onChange={handleFakeLetterChange}
                        >
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item label="PLVC done correctly">
                        <Switch 
                          checkedChildren="Yes" 
                          unCheckedChildren="No" 
                          defaultChecked={false} 
                        />
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item
                        label="Welcome calling"
                      >
                        <Switch
                    checkedChildren="Yes" 
                    unCheckedChildren="No" 
                    defaultChecked={false} 
                        />
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item 
                        label="Variance in Login location and customer's address"
                        name='loginLocation'
                      >
                        <Select 
                          placeholder="Variance in Login location and customer's address" 
                          value={loginLocation} 
                          onChange={handleloginLocationChange}
                          style={{ width: '100%' }} 
                        >
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item 
                        label="Variance in Signature on proposal form/CDF"
                        name='signatureonProposal'
                      >
                        <Select 
                          placeholder="Variance in Signature on proposal form/CDF" 
                          value={signatureonProposal} 
                          onChange={handlesignatureonProposalChange}
                          style={{ width: '100%' }} 
                        >
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item label="PIVC/Welcome call- Any negative observations" name='welcomeCallChange'>
                        <Select 
                          placeholder="PIVC/Welcome call- Any negative observations" 
                          value={welcomeCall} 
                          onChange={handlewelcomeCallChange}
                        >
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item 
                        label="Policy Delivery Done status"
                      >
                        <Switch 
                          checked={deliveryDone} 
                          onChange={handlePolicyDeliveryDoneStatus}
                          checkedChildren="Done"
                          unCheckedChildren="Not Done"
                        />
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item label="Fresh complaint" name='freshComplaint'>
                        <Select 
                          placeholder="Fresh complaint"
                          value={freshComplaint} 
                          onChange={handlefreshComplaintChange}
                        >
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item label="Multiple policies" name='multiplePolicies'>
                        <Select 
                          placeholder="Multiple policies"
                          value={multiplePolicies} 
                          onChange={handlemultiplePoliciesChange}
                        >
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item 
                        label="Customer approached just 90 days beyond FLC"
                        name='customerApproached90Days'
                      >
                        <Select
                          placeholder="Customer approached just 90 days beyond FLC"
                          value={fakeLetter}
                          onChange={handleFakeLetterChange}
                          style={{ width: '100%' }}
                        >
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item label="Renewal premium paid Yes/No" name='npdd'>
                        <Select 
                          placeholder="Renewal premium paid Yes/No"
                          value={renewalPremiumPaid} 
                          onChange={handlerenewalPremiumPaidChange}
                        >
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                          <Option value="NA">Not Available</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item 
                        label="Medical Tests conducted Yes/No" 
                        name="medflag"
                        initialValue={gcpAPIResponseData?.response?.policyAttribute[0]?.medflag}
                      >
                        <Select 
                          placeholder="Medical Tests conducted Yes/No"
                          value={medicalTestsConducted} 
                          onChange={handlemedicalTestsConductedChange}
                        >
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                          <Option value="NA">Not Available</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div style={{ width: '50%' }}>
                      <Form.Item 
                        label="Renewal Call" 
                        name='renewalCallPositive'
                      >
                        <Select 
                          placeholder="Renewal Call"
                          value={renewalCallPositive} 
                          onChange={handlerenewalCallPositiveChange}
                        >
                          <Option value="Positive">Positive</Option>
                          <Option value="Negative">Negative</Option>
                          <Option value="NA">Not Available</Option>
                        </Select>
                      </Form.Item>
                    </div>

                  </div>
                </Form>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      <div className="contact-details-btn mt-5" style={{ marginTop: '16px' }}>
        <Button type="primary" className="primary-btn mt-4 me-3" htmlType="submit" onClick={handleMiscSave}>
          Save
        </Button>
      </div>
    </div>
       
    <div>
            {/* Conditionally render the grid */}
            {TotalCount > 0 && (
                <table border="1" style={{ width: '80%', borderCollapse: 'collapse' }}>
                    <thead style={{ backgroundColor: "#b3201f", color: 'white' }}>
                        <tr>
                            <th></th>
                            <th>Outcome</th>
                            <th></th>
                            <th>Ratio of Outcome</th>
                            <th>Target & Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>
                                {ExceptionhandlingRequiredOutCome}<br />
                                {ExceptionhandlingNotRequiredOutCome}<br />
                                {NotInFavourOfCustomerOutCome}<br />
                                {InFavourOfCustomerOutCome}<br />
                                {DeclineOutCome}
                            </td>
                            <td>
                                {ExceptionhandlingRequiredCount}<br />
                                {ExceptionhandlingNotRequiredCount}<br />
                                {NotInFavourOfCustomerCount}<br />
                                {InFavourOfCustomerCount}<br />
                                {DeclineOutComeCount}
                            </td>
                            <td>
                                {ExceptionhandlingRequiredPercentage}%<br />
                                {ExceptionhandlingNotRequiredPercentage}%<br />
                                {NotInFavourOfCustomerPercentage}%<br />
                                {InFavourOfCustomerPercentage}%<br />
                                {DeclineOutComePercentage}%
                            </td>
                            <td>Decline the case</td>
                            <td></td>
                        </tr>
                        <tr style={{ fontWeight: 'bold' }}>
                            <td></td>
                            <td>Total</td>
                            <td>{TotalCount}</td>
                            <td>100.0%</td>
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
              <div style={{ width: '17%', float: 'left', margin: '16px 0px' }}>
              
              
                {/* <Widgets /> */}


              </div>
            </TabPane>
            <TabPane
      tab={
        <span>Sales Feedback</span>
      }
      key="5"
    >
      <div className='tabs-begin' style={{ width: '77%', float: 'left', margin: '16px 16px' }}>
        <Form form={form} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <div style={{ width: '100%' }}>
            <Form.Item label="Sales Comments" name="salesComments" wrapperCol={{ span: 20 }} labelCol={{ span: 4 }}>
              <Input.TextArea placeholder="" />
            </Form.Item>
          </div>

          <div style={{ width: '100%' }}>
            <Form.Item label="Sales Feedback" name="SalesFeedback" wrapperCol={{ span: 20 }} labelCol={{ span: 4 }}>
              <Select placeholder="Sales Feedback">
                <Option value="Approve">Approve</Option>
                <Option value="Reject">Reject</Option>
              </Select>
            </Form.Item>
          </div>

          <div className="contact-details-btn" style={{ marginTop: '16px' }}>
            <Button type="primary" className="primary-btn mt-4 me-3" htmlType="submit" onClick={handleSalesFeedbackSave}>
              Save
            </Button>
          </div>
        </Form>
      </div>

      {/* <div style={{ width: '17%', float: 'left', margin: '16px 0px' }}>
        <Widgets />
      </div> */}
    </TabPane>


    <TabPane
  tab={<span>Refund Details</span>}
  key="6"
>
  <div style={{ display: 'flex', margin: '16px' }}>
    {/* Form and Button Container */}
    <div style={{ width: '77%' }}>
      <Form form={form} style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <div style={{ width: '50%' }}>
            <Form.Item label="IFSC" name="IFSC" {...formItemLayout2}>
              <Input type="text" placeholder="IFSC" />
            </Form.Item>
          </div>
          <div style={{ width: '50%' }}>
            <Form.Item label="Account Holders Name" name="Account_Holders_Name" {...formItemLayout2}>
              <Input type="text" placeholder="Account Holders Name" />
            </Form.Item>
          </div>
          <div style={{ width: '50%' }}>
            <Form.Item label="Bank Account Number" name="Bank_Account_Number" {...formItemLayout2}>
              <Input type="text" placeholder="Bank Account Number" />
            </Form.Item>
          </div>
          <div style={{ width: '50%' }}>
            <Form.Item label="Re-enter Account Number" name="Re_enter_Account_Number" {...formItemLayout2}>
              <Input type="text" placeholder="Re-enter Account Number" />
            </Form.Item>
          </div>
          <div style={{ width: '50%' }}>
            <Form.Item label="Bank" name="Bank" {...formItemLayout2}>
              <Input type="text" placeholder="Bank" />
            </Form.Item>
          </div>
          <div style={{ width: '50%' }}>
            <Form.Item label="Penny Drop Result" name="Penny_Drop_Result" {...formItemLayout2}>
              <Input type="text" placeholder="Penny Drop Result" />
            </Form.Item>
          </div>
          <div style={{ width: '50%' }}>
            <Form.Item label="Branch Name" name="Branch_Name" {...formItemLayout2}>
              <Input type="text" placeholder="Branch Name" />
            </Form.Item>
          </div>
        </div>
        <div style={{ marginTop: 'auto', textAlign: 'right' }}>
          <Button
            type="primary"
            className="primary-btn"
            htmlType="submit"
            onClick={handleRefundSave}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
    
    {/* Widgets Container */}
    {/* <div style={{ width: '17%' }}>
      <Widgets />
    </div> */}
  </div>
</TabPane>

           

<TabPane tab={<span>Approver Comments</span>} key="7">
      <div className="tabs-begin" style={{ width: '77%', margin: '16px' }}>
        <Form form={form} style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: '100%' }}>
            <Form.Item
              label="Approver Comments"
              name="ApproverComments"
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
            >
              <Input.TextArea placeholder="Approver Comments" />
            </Form.Item>
          </div>

          <div style={{ width: '100%' }}>
            <Form.Item
              label="Approver Feedback"
              name="ApproverFeedback"
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
            >
              <Select placeholder="Approver Feedback">
                <Option value="Approve">Approve</Option>
                <Option value="Reject">Reject</Option>
              </Select>
            </Form.Item>
          </div>
          <div  style={{ width: '100%' }}>
          <Form.Item
            label={
              <span>
                Date Of Approval
              </span>
            }
            name="dateOfApproval"
            className="inputs-label mb-0"
            wrapperCol={{ span: 20 }}
            labelCol={{ span: 4 }}
          
          >
            <DatePicker
              allowClear={true}
              style={{ width: "100%" }}
              className="cust-input"
              format={dateFormat}
            />
          </Form.Item>
        </div>
      <br/>
          <Form.Item
            label="Refer to Senior"
            name="RefertoSenior"
            valuePropName="checked"
            wrapperCol={{ span: 20 }}
            labelCol={{ span: 4 }}
          >
            <Checkbox onChange={handleCheckboxChange}>
              Yes
            </Checkbox>
          </Form.Item>

          <div style={{ marginTop: '16px' }}>
            <Button
              type="primary"
              className="primary-btn"
              htmlType="submit"
              onClick={handleApproverCommentsSave}
              disabled={!isCheckboxChecked}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>

      <div style={{ width: '17%', margin: '16px 0' }}>
        {/* <Widgets /> */}
      </div>
    </TabPane>


            
{
 !rcahideshow && (
  <TabPane
  tab={<span>RCA</span>}
  key="8"
>
  <div className='tabs-begin' style={{ width: '77%', float: 'left', margin: '16px 16px' }}>
    <Form form={form} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <div style={{ width: '100%' }}>
        <Form.Item label="Observations" wrapperCol={{ span: 20 }} labelCol={{ span: 4 }} name="Observations">
          <Input.TextArea placeholder="Observations" />
        </Form.Item>
      </div>
      <div style={{ width: '100%' }}>
        <Form.Item label="Additional Remarks" wrapperCol={{ span: 20 }} labelCol={{ span: 4 }} name="Additional_Remarks">
          <Input.TextArea placeholder="Additional Remarks" />
        </Form.Item>
      </div>
      <div style={{ width: '100%' }}>
        <Form.Item label="Conclusion" wrapperCol={{ span: 20 }} labelCol={{ span: 4 }} name="Conclusion">
          <Input.TextArea placeholder="Conclusion" />
        </Form.Item>
      </div>
      <div style={{ width: '100%' }}>
        <Form.Item label="Additional Space for case remarks" wrapperCol={{ span: 20 }} labelCol={{ span: 4 }} name="Additional_Space_for_case_remarks">
          <Input.TextArea placeholder="Additional Space for case remarks" />
        </Form.Item>
      </div>
      <div style={{ width: '100%' }}>
        <Form.Item label="Closure Remarks" {...formItemLayout2} wrapperCol={{ span: 20 }} labelCol={{ span: 4 }} name="Closure_Remarks">
          <Input type="text" placeholder="Closure Remarks" />
        </Form.Item>
      </div>
      <div style={{ width: '100%' }}>
        <Form.Item label="Nature of Complaint" {...formItemLayout2} wrapperCol={{ span: 20 }} labelCol={{ span: 4 }} name="Nature_of_Complaint">
          <Select placeholder="Select Nature of Complaint">
            <Option value="surrender">Surrender</Option>
            <Option value="contact_details">Contact Details</Option>
          </Select>
        </Form.Item>
      </div>
      <div style={{ width: '100%' }}>
        <Form.Item label="Type of Error" {...formItemLayout2} wrapperCol={{ span: 20 }} labelCol={{ span: 4 }} name="Type_of_Error">
          <Select placeholder="Select Type of Error">
            <Option value="surrender">Surrender</Option>
            <Option value="contact_details">Contact Details</Option>
          </Select>
        </Form.Item>
      </div>
      <div style={{ width: '100%' }}>
        <Form.Item label="Reason For Error" wrapperCol={{ span: 20 }} labelCol={{ span: 4 }} name="Reason_For_Error">
          <Input.TextArea placeholder="" />
        </Form.Item>
      </div>
      <div style={{ width: '100%' }}>
        <Form.Item label="Root Cause" wrapperCol={{ span: 20 }} labelCol={{ span: 4 }} name="Root_Cause">
          <Input.TextArea placeholder="" />
        </Form.Item>
      </div>
      <div className="contact-details-btn" style={{ marginTop: '16px' }}>
        <Button type="primary" className="primary-btn mt-4 me-3" htmlType="submit" onClick={handleRCASave}>
          Save
        </Button>
      </div>
    </Form>
  </div>
  {/* <div style={{ width: '17%', float: 'left', margin: '16px 0px' }}>
    <Widgets />
  </div> */}
</TabPane>
)
}
            


<TabPane
  tab={<span>Closure Response</span>}
  key="9"
>
  <div className='tabs-container' style={{ display: 'flex', alignItems: 'flex-start' }}>
    <div className='tabs-content' style={{ width: '77%' }}>
      <Form form={form} {...formItemLayout}>
        {/* <Form.Item label="Tag Complaint" name="Tag_Complaint">
          <Radio.Group>
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Radio.Group>
        </Form.Item> */}

        <Form.Item label="Response Mode" name="Response_Mode">
          <Radio.Group>
            <Radio value="email">Email</Radio>
            <Radio value="letter">Letter</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Is Complaint Closed in favour of customer" name="Is_Complaint_Closed">
          <Radio.Group onChange={(e) => setInitialrefund(e.target.value === 'true')}>
            <Radio value="true">Yes</Radio>
            <Radio value="false">No</Radio>
          </Radio.Group>
        </Form.Item>

        {/* Other form items */}
        {/* <div className="contact-details-btn">
        {/* <div className="complaints-details-btn" style={{ marginTop: '16px' }}> */}
          {/* {initialrefund && <Button type="primary" className="primary-btn"value="Reassign"htmlType="submit">
            Initiate Refund
          </Button>} */}
        {/* </div> */} 

        {/* <Button type="primary" className="primary-btn mt-4 me-3" htmlType="submit" onClick={CloserResponseSave}>
          Save
        </Button> */}
      </Form>
      {/* <div className="save-btn-container" style={{ marginTop: '16px', textAlign: 'right' }}> */}
      <div className="contact-details-btn">
    <Button
      type="primary"
      value="Reassign"
      className="primary-btn"
      htmlType="submit"
      onClick={CloserResponseSave}
    >
      Save
    </Button>
    {initialrefund && <Button type="primary" className="primary-btn"value="Reassign"htmlType="submit">
            Initiate Refund
          </Button>}
  </div>
    </div>
    {/* <div style={{ width: '17%' }}>
      <>
        <p style={{ textAlign: 'center' }}>
          <b>Timeline </b>
        </p>
        <Timeline
          mode={mode}
          items={[
            {
              label: 'DEC-25',
              children: 'Complaint Received',
            },
            {
              label: 'DEC-26',
              children: 'Acknowledgement Sent',
            },
            {
              label: 'DEC-27',
              children: 'Sales Feedback Sent',
            },
            {
              label: 'DEC-27',
              children: 'Sales Reminder 1',
            },
            {
              label: 'DEC-25',
              children: 'Sales Reminder 2',
            },
            {
              label: 'DEC-26',
              children: 'Send For Approver',
            },
            {
              label: 'DEC-27',
              children: 'Payment',
            }
          ]}
        />
      </>
    </div> */}
  </div>


</TabPane>



          </Tabs>

        </div>

        <div>
      <a href="#" onClick={openModal}>Open Popup</a>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <h2>Popup Content</h2>
        <button onClick={closeModal}>Close</button>
        <div>
          <label>
            Read-Only Field:
            <input type="text" value="Read-Only Value" readOnly />
          </label>
        </div>
        <div>
          <label>
            Input Field:
            <input type="text" placeholder="Enter something..." />
          </label>
        </div>

        {showAlert && (
        <PopupAlert
          alertData={alertData}
          title={alertTitle}
          getAdvance={props.getAdvance}
          //navigate={navigateTo}
          setShowAlert={setShowAlert}
        ></PopupAlert>
      )}
      </Modal>
    </div>

        </>}
        {/* <div style={{ width: '17%', float: 'left', margin: '16px 0px' }}>
           
          </div> */}
        {/* </div> */}



      </Spin>
     
      {showAlert && (
        <PopupAlert
          alertData={alertData}
          title={alertTitle}
          getAdvance={props.getAdvance}
          //navigate={navigateTo}
          setShowAlert={setShowAlert}
        ></PopupAlert>
      )}
    </>
  )
}


export default ComplaintsUser;