import React, { useState, useEffect, useRef } from "react";
import { Collapse, Spin, Tooltip, Drawer, Progress, message, Modal, Button, Checkbox,Table, Select,Form,Col,Row, Input, DatePicker, } from "antd";
import moment from 'moment';
import apiCalls from "../api/apiCalls";
import NumberFormat from "react-number-format";
import { SearchOutlined } from '@ant-design/icons';
import { policyDetailsObj } from "../reducers/policyDetailsReducer";
import { useLocation } from "react-router-dom";
import TypesComponent from "./CallTypes/TypesComponent";
import CloseIcon from "../assets/images/close-icon.png";
import Assistance from "../assets/images/handshake.png";
import TableFormat from "../utils/TableFormat";
import { connect,useSelector } from "react-redux";
import archivedImg from '../assets/images/archived-tickets.svg';
import payoutsImg from '../assets/images/payout.svg';
import upcomingImg from '../assets/images/upcoming_renewal.svg';
import dmsImg from '../assets/images/DMS.svg';
import scrollImg from '../assets/images/scroll[1].svg';
import transactionImg from '../assets/images/transactiondocuments.svg';
import HistoricalCommunication from "./HistoricalCommunication";
import {
  LAST5_OPEN_TICKETS,
  LAST5_OPEN_ARCHIEVEDTICKETS,
  PAYMENT_TRANSACTIONS,
  PAYMENT_TRANSACTIONS_DATA,
  HISTORICAL_COMMUNICATION,
} from "../config";
import TicketsOpenPopup from "./TicketsOpenPopup";


const CustomerDetails = (props) => {
  const loginInfo = useSelector(state => state);
  const { Panel } = Collapse;
  const loggedUser = useSelector(state => state?.userProfileInfo?.profileObj);
  const {customerData,transactionDocData} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const { state } = useLocation();
  const [allDetailsOpen, setAllDetailsOpen] = useState(false);
  const [showLastPayments, setShowLastPayments] = useState(false);
  const [showOpenTickets, setShowOpenTickets] = useState(false);
  const [faqOpen, setFaqOpen] = useState(false);
  const [ticketsData, setTicketsData] = useState([]);
  const [CALL_TyPES, setCALL_TyPES] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [requirementModalLoader, setRequirementLoader] = useState(false);
  const [requestModeLU, setRequestModeLU] = useState([]);
  const [uwDecisionLU, setUWDecisionLU] = useState([]);
  const [cautionModal, setCautionModal] = useState(false);
  const [cautionData, setCautionData] = useState(false);
  const [cautionComment, setCautionComment] = useState(null);
  const [isCaution, setIsCaution] = useState(false);
  const [isRiderData, setIsRiderData] = useState([]);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [isHistoricalComm, setIsHistoricalComm] = useState(false);
  const [historicalCommData, setHistoricalCommData] = useState([]);
  const [isCommunicationContent, setIsCommunicationContent] = useState(false);
  const [commContent, setCommContent] = useState({});


  const [clientRoleLU, setClientRoleLU] = useState([]);
  const [ticketsLoader, setTicketsLoader] = useState(false);
  const [bankAccTypeLU, setBankAccTypeLU] = useState([]);
  const [requestReceivedViaLU, setRequestReceivedViaLU] = useState([]);
  const [premiumStatus, setPremiumStatus] = useState("");
  const [policyStatus, setPolicyStatus] = useState("");
  const [cursorPortalLU, setCursorPortalLU] = useState([]);
  const [websitePortalLU, setWebsitePortalLU] = useState([]);
  const [callRelatedActionLU, setCallRelatedActionLU] = useState([]);
  const [customerQueryLU, setCustomerQueryLU] = useState([]);
  const [panUpdateLU, setPanUpdateLU] = useState([]);
  const [processNameLU, setProcessNameLU] = useState([]);
  const [BirthdayIcon, setBirthdayIcon] = useState(false);
  const [sisLU, setSISLU] = useState([]);
  const [interlRequirementTagValue, setInternalRequirementTagValue] = useState([]);
  const [annuityPlans, setAnnuityPlans] = useState([]);
  const [isVerifiedCheck, setIsVerifiedCheck] = useState(false);
  const isCheckedButton = state && state.isAdvanceVerifiedCheck;
  const [details,setDetails]=useState([]);
  const [assistance, setAssistance] = useState([]);
  const [isTicketDetails,setIsTicketsDetails] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});

  const [assistanceOpen, setAssistanceOpen] = useState(false);
  const [CallTypeId, setCallTypeId] = useState("");
  const [SubTypeId, setSubTypeId] = useState("");
  const [isShowAllTicketsData, setIsShowAllTicketsData] = useState(false);
  const [isSelectedTicketsData, setIsSelectedTicketsData] = useState({});
  const [isTicketsPOSObj, setIsTicketsPOSObj] = useState({});
  const [isChecked, SetIsChecked] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [isOpenTicket,setIsOpenTicket]=useState(false);
  const [serviceId,setServiceId]=useState('');
  const [isTicketsData,setIsTicketsData]=useState([]);
  const [Verifiedpopup, setVerifiedPopup] = useState(false);
  const [ShowUplodedDocs, setShowUplodedDocs] = useState(false);
  const [SelectedSubTypeVal, setSelectedSubTypeVall]=useState('');
  const [Address, setAddress] = useState('');
  const [Relation, setRelation] = useState('');
  const [PosData, setPosData]= useState(transactionDocData? transactionDocData :'');
  const [ClientName, setClientName] = useState('');
  const [isPayoutModal,setIsPayoutModal] = useState(false);
  const [payoutModalData,setpayoutModalData] = useState([]);
  const [isPayoutModalLoading, setIsPayoutModalLoading] = useState(false);
  const [customerProfile, setCustomerProfile] = useState({});
  const [NPSData, setNPSData] = useState('');
  const [languageMode, setLanguageMode] = useState('');
  const [communicationMode, setCommunicationMode] = useState('');
  const [customerSentiment, setCustomerSentiment] = useState('');
  const [causeOfEventLU,setCauseOfEventLU] = useState([]);
  const [natureOfDeathLU, setNatureOfDeathLU] = useState([]);
  const [paymentTransactions, setPaymentTransactions] = useState([]);
  const [paymentTransactionsData, setPaymentTransactionsData] = useState([]);
  const [policyTypeLU, setPolicyTypeLU] = useState([]);
  const [claimCategoryLU, setClaimCategoryLU] = useState([]);
  const [sourceInformationLU, setSourceInformationLU] = useState([]);
  const [claimIntimationLU, setClaimIntimationLU] = useState([]);
  const [assuredIncomePlanLU, setAssuredIncomePlanLU] = useState([]);
  const [policyDetailsModal, setPolicyDetailsModal] = useState(false);
  const [archivedTickets, setArchivedTickets] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [premiumEnquiryResponse, setPremiumEnquiryResponse] = useState([]);
  const [RupeeDetailsModal, setRupeeDetailsModal] = useState(false);

  const [laNomineeAddressLU, setLANomineeAddressLU] = useState([]);
  const [subStageLU, setSubStageLU] = useState([]);
  const [assessorsDecisionLU, setAssessorsDecisionLU] = useState([]);
  const [policyStatusDOBLU, setPolicyStatusDOBLU] = useState([]);
  const [approverDecisionLU, setApproverDecisionLU] = useState([]);
  const [dataBseCHeckLU, setDataBaseCheckLU] = useState([]);
  const [hotSpotCheckLU, setHotSpotCheckLU] = useState([]);
  const [referCaseToLU, setReferCaseToLU] = useState([]);
  const [reinstatementDecisionLU, setReinstatementDecisionLU] = useState([]);
  const [withDGHLU, setWithDGHLU] = useState([]);
  const [investigatorLU, setInvestigatorLU] = useState([]);
  const [decisionDescriptionLU, setDecisionDescriptionLU] = useState([]);
  const [isAccoundetail, setIsAccoundetail] = useState(false);
  const [accountDetailsData, setAccountDetailsData] = useState(null);


  const [showArchievedTickets, setShowArchievedTickets] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const dateFormat = "YYYY-MM-DD";

  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${day}/${month}/${year}`;
};

    const formatAmount = (amount) => {
      return parseFloat(amount).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
      });
  };
  
  const handleLanguageChange = (event) => {
    setLanguageMode(event.target.value);
    
  };

  const handleSentimentChange = (event) => {
    setCustomerSentiment(event.target.value);
    
  };

  const handleCommunicationChange = (event) => {
    setCommunicationMode(event.target.value);
  };


  const saveDropdownProfileValues = (onchangeValue) => {
    setIsLoading(true);
    let data = {};
    if(onchangeValue == "Language"){
      data.TagValue =languageMode;
      data.TagName = "Language";
    }
    else if(onchangeValue =="Communication"){
      data.TagValue =communicationMode;
      data.TagName = "CommunicationMode";
    }
    else if(onchangeValue =="Customer"){
      data.TagValue =customerSentiment;
      data.TagName = "CustomerSentiment";
    }
   data.CreatedBy = "";
 data.ClientID = state?.poClientID;

 let response = apiCalls.getUserProfilePreferences (data.CreatedBy, data.ClientID, data.TagName, data.TagValue) ;
 response
 .then((val) => {
   //setCustomerProfile(val.data)
   setIsLoading(false);
   setAllDetailsOpen(true);
   })
   .catch((err) => {
     setIsLoading(false);
     message.destroy()
     message.error({
       content: response?.data?.responseBody?.errormessage || "Something went wrong please try again!",
       className: "custom-msg",
       duration: 2,
     });
   });


  }

  const premiumStatusList = [
    { descItem: 'AC', shortDesc: 'Auto Cover', longDesc: 'Auto Cover Period' },
    { descItem: 'AF', shortDesc: 'Foreclose', longDesc: 'Foreclosure' },
    { descItem: 'AP', shortDesc: 'Ann Paying', longDesc: 'Annuity in Payment' },
    { descItem: 'CD', shortDesc: 'CF DOB Chg', longDesc: 'Cancelld frm Inception DOB chg' },
    { descItem: 'CF', shortDesc: 'CFI', longDesc: 'Cancelled from Inception' },
    { descItem: 'CR', shortDesc: 'Clm-Regstd', longDesc: 'Health Claim Premium Status' },
    { descItem: 'DC', shortDesc: 'Declined', longDesc: 'Declined' },
    { descItem: 'DF', shortDesc: 'Disc Forc', longDesc: 'Discontinuance Foreclose' },
    { descItem: 'DH', shortDesc: 'Deceased', longDesc: 'Deceased' },
    { descItem: 'DI', shortDesc: 'Dth Disc I', longDesc: 'Dth-Discontinued Invalid' },
    { descItem: 'DS', shortDesc: 'Er Sur Reg', longDesc: 'Early Surrendr Registered' },
    { descItem: 'DU', shortDesc: 'Discont', longDesc: 'Policy Discontinued' },
    { descItem: 'EL', shortDesc: 'Extd Life', longDesc: 'Extended Maturity' },
    { descItem: 'ET', shortDesc: 'ETI', longDesc: 'Extended Term Insurance' },
    { descItem: 'EX', shortDesc: 'Expired', longDesc: 'Expired' },
    { descItem: 'FL', shortDesc: 'Free Look', longDesc: 'Free Look Cancellation' },
    { descItem: 'FP', shortDesc: 'Fully Paid', longDesc: 'Fully Paid' },
    { descItem: 'FR', shortDesc: 'FL Regstr', longDesc: 'Free Look Cancel Register' },
    { descItem: 'FU', shortDesc: 'FP Death', longDesc: 'FullyPaid Death' },
    { descItem: 'HA', shortDesc: 'Prem Holdy', longDesc: 'Premium Holiday - Auto' },
    { descItem: 'HC', shortDesc: 'Health Clm', longDesc: 'Health Claim' },
    { descItem: 'HP', shortDesc: 'PH RestPnd', longDesc: 'PH Reinstatement Pending' },
    { descItem: 'LA', shortDesc: 'Lapsed', longDesc: 'Lapsed' },
    { descItem: 'LM', shortDesc: 'Lapse Mat', longDesc: 'Lapsed Maturity' },
    { descItem: 'MA', shortDesc: 'Matured', longDesc: 'Matured' },
    { descItem: 'MD', shortDesc: 'Mat Death', longDesc: 'Maturity-Death' },
    { descItem: 'MO', shortDesc: 'MO', longDesc: 'Maturity-Settlmnt (No admin)' },
    { descItem: 'MS', shortDesc: 'Mat Stl', longDesc: 'Maturity-Settlmnt opt' },
    { descItem: 'MX', shortDesc: 'Setlmt-End', longDesc: 'Setlmt-End' },
    { descItem: 'NF', shortDesc: 'NF Surr', longDesc: 'Non-Forfeiture Surrendered' },
    { descItem: 'PM', shortDesc: 'Prm Matury', longDesc: 'Premium Maturity' },
    { descItem: 'PO', shortDesc: 'Postpone', longDesc: 'Contract Postponed' },
    { descItem: 'PP', shortDesc: 'Prm Paying', longDesc: 'Premium Paying' },
    { descItem: 'PS', shortDesc: 'Proposal', longDesc: 'Proposal' },
    { descItem: 'PU', shortDesc: 'Paid-up', longDesc: 'Made Paid-up' },
    { descItem: 'RC', shortDesc: 'Repudiate', longDesc: 'Repudiate Death Claim' },
    { descItem: 'RP', shortDesc: 'Red Paidup', longDesc: 'Reduced Paid-up' },
    { descItem: 'RS', shortDesc: 'Repd Setlm', longDesc: 'Repudiation Settlement Option' },
    { descItem: 'S', shortDesc: 'Suspended', longDesc: 'Suspended Pending Transaction' },
    { descItem: 'SD', shortDesc: 'Er Sr Appv', longDesc: 'Early Surrender Approval' },
    { descItem: 'SP', shortDesc: 'Single Prm', longDesc: 'Single Premium' },
    { descItem: 'SR', shortDesc: 'Surr Reg', longDesc: 'Surrender Register' },
    { descItem: 'SU', shortDesc: 'Surrender', longDesc: 'Fully Surrendered' },
    { descItem: 'TM', shortDesc: 'Terminate', longDesc: 'Termination Health Claim' },
    { descItem: 'UD', shortDesc: 'Unpaid Dis', longDesc: 'Unpaid Discontinued' },
    { descItem: 'VA', shortDesc: 'Vest Appr', longDesc: 'Vesting Approval' },
    { descItem: 'VP', shortDesc: 'Vest pendg', longDesc: 'Vesting Pending' },
    { descItem: 'VR', shortDesc: 'Vest Reg', longDesc: 'Vesting Registered' },
    { descItem: 'WD', shortDesc: 'Withdrawn', longDesc: 'Withdrawn' },
    { descItem: 'WV', shortDesc: 'WvrofPrem', longDesc: 'Waiver of Premium' },
  ];

  const billFreq = {
    '01': 'Annual',
    '04': 'Quarterly ',
    
    '12': 'Monthly',
    '00': 'Single Pay',
    '02': 'Semi Annual',


  }

  const callCenterLU = [
    { label: 'Call Related', value: 14 },
  ];
  const callCenterSubTypeLU = [
    { label: 'Verification not Completed', value: 10 },
    // { label: 'Blank Call / Ghost Call', value: 8 },
    // { label: 'Call Drop', value: 9 },
    // { label: 'General insurance call', value: 7 },
    // { label: 'Wrong Customer Contacted', value: 1 },
    // { label: 'Group Insurance Call', value: 10 },
  ];
  // Policy Status List 
  const policyStatusList = [
    { descItem: 'EL', shortDesc: 'Extd Life', longDesc: 'Extended Maturity' },
    { descItem: 'EX', shortDesc: 'Expiry', longDesc: 'Expiry' },
    { descItem: 'FA', shortDesc: 'FP DthAppv', longDesc: 'FullyPaid Death Approval' },
    { descItem: 'FD', shortDesc: 'FP Dth Reg', longDesc: 'FullyPaid Death Register' },
    { descItem: 'FL', shortDesc: 'Free Look', longDesc: 'Free Look Cancellation' },
    { descItem: 'FR', shortDesc: 'FL Regstr', longDesc: 'Free Look Cancel Register' },
    { descItem: 'HC', shortDesc: 'Health Clm', longDesc: 'Health Claim' },
    { descItem: 'HP', shortDesc: 'PH RestPnd', longDesc: 'PH Reinstatement Pending' },
    { descItem: 'IF', shortDesc: 'In Force', longDesc: 'In Force' },
    { descItem: 'LA', shortDesc: 'Lapsed', longDesc: 'Contract Lapsed' },
    { descItem: 'LM', shortDesc: 'Lapsed Mat', longDesc: 'Lapsed Maturity' },
    { descItem: 'MA', shortDesc: 'Matured', longDesc: 'Contract Matured' },
    { descItem: 'MD', shortDesc: 'Mat Death', longDesc: 'Maturity Death' },
    { descItem: 'MO', shortDesc: 'MO', longDesc: 'Maturity-Settlmnt (No admin)' },
    { descItem: 'MP', shortDesc: 'Modify prp', longDesc: 'Component changes - modify prp' },
    { descItem: 'MR', shortDesc: 'MA Dth Reg', longDesc: 'Maturity Death Register' },
    { descItem: 'MS', shortDesc: 'Mat Stl', longDesc: 'Maturity-Settlmnt opt' },
    { descItem: 'MX', shortDesc: 'Setlmt-End', longDesc: 'Setlmt-End' },
    { descItem: 'PM', shortDesc: 'Prm Matury', longDesc: 'Premium Maturity' },
    { descItem: 'PO', shortDesc: 'Postpone', longDesc: 'Contract Postponed' },
    { descItem: 'PS', shortDesc: 'Proposal', longDesc: 'Contract Proposal' },
    { descItem: 'PU', shortDesc: 'Paid Up', longDesc: 'Paid Up Contract' },
    { descItem: 'PW', shortDesc: 'Wfwd Pndg', longDesc: 'Windforward Pending' },
    { descItem: 'RC', shortDesc: 'Repudiate', longDesc: 'Repudiate Death Claim' },
    { descItem: 'RD', shortDesc: 'Reg Death', longDesc: 'Registered Death Claim' },
    { descItem: 'RP', shortDesc: 'Red paidup', longDesc: 'Reduced Paid Up Contract' },
    { descItem: 'RS', shortDesc: 'Repd Setlm', longDesc: 'Repudiation Settlement Option' },
    { descItem: 'SD', shortDesc: 'Er Sr Appv', longDesc: 'Early Surrender Approval' },
    { descItem: 'SR', shortDesc: 'Surr Reg', longDesc: 'Surrender Register' },
    { descItem: 'SU', shortDesc: 'Surrender', longDesc: 'Contract Surrendered' },
    { descItem: 'TM', shortDesc: 'Terminate', longDesc: 'Termination Health Claim' },
    { descItem: 'TR', shortDesc: 'Terminated', longDesc: 'Contract Terminated' },
    { descItem: 'UW', shortDesc: 'U/W', longDesc: 'Underwriting' },
    { descItem: 'VA', shortDesc: 'Vest Appr', longDesc: 'Vesting Approval' },
    { descItem: 'VP', shortDesc: 'Vest pendg', longDesc: 'Vesting Pending' },
    { descItem: 'VR', shortDesc: 'Reg Vested', longDesc: 'Vesting Registered' },
    { descItem: 'WD', shortDesc: 'Withdrawn', longDesc: 'Contract Withdrawn' },
    { descItem: 'AF', shortDesc: 'Foreclose', longDesc: 'Foreclosure' },
    { descItem: 'AP', shortDesc: 'Add prpsl', longDesc: 'Component change - Add prpsl' },
    { descItem: 'CD', shortDesc: 'CF DOB Chg', longDesc: 'Cancelld frm Inception DOB chg' },
    { descItem: 'CF', shortDesc: 'CFI', longDesc: 'Cancelled from Inception' },
    { descItem: 'CP', shortDesc: 'CP', longDesc: 'Certificate Printed' },
    { descItem: 'CR', shortDesc: 'Regstd HLT', longDesc: 'Health Claim Resgistred' },
    { descItem: 'DC', shortDesc: 'Declined', longDesc: 'Contract Declined' },
    { descItem: 'DD', shortDesc: 'Dth Disc R', longDesc: 'Dth-Discontinued Registered' },
    { descItem: 'DF', shortDesc: 'Disc Forc', longDesc: 'Discontinuance Foreclosure' },
    { descItem: 'DH', shortDesc: 'Death Clm', longDesc: 'Approved Death Claim' },
    { descItem: 'DI', shortDesc: 'Dth Disc I', longDesc: 'Dth - Discontinued Invalid' },
    { descItem: 'DS', shortDesc: 'Er Sur Reg', longDesc: 'Early Surrender' },
    { descItem: 'DU', shortDesc: 'Discont', longDesc: 'Policy Discontinued' },
  ];

  const relationship = {
    'AP':'Appointee',
    'AU':'Aunt  ',
    'BR':'Brother    ',
    'BU':'Business   ',
    'CH':'Contingent Policy Holder   ',
    'DA':'Daughter    ',
    'EX':'Executor   ',
    'FA':'Father      ',
    'GD':'Granddaughter            ',
    'GF':'Grandfather',
    'GM':'Grandmother',
    'GS':'Grandson',
    'HU':'Husband',
    'MO':'Mother',
    'NE':'Nephew',
    'NI':'Niece',
    'O':'Other',
    'OT':'Other',
    'SI':'Sister',
    'SO':'Son',
    'TR':'Trustee ',
    'UL':'Uncle',
    'WI':'Wife',

  }
  const shouldLog = useRef(true);

  const getClaimsPrimaryAssessmentEnquiry = async () => {
    try {
      const response = await apiCalls.GetClaimsPrimaryAssessmentEnquiry(loginInfo?.userProfileInfo?.profileObj?.allRoles[0]?.employeeID, (state?.policyNo));
      if (response?.data?.responseHeader?.errorcode === "0") {
        const updatedRiderData = response?.data?.responseBody?.claimsPrimaryAssessmentEnquiry;
        setIsRiderData(updatedRiderData);
      } else {
        message.error({
          content:
            response?.data?.responseBody?.errormessage ||
            "Something went wrong, please try again!",
          className: "custom-msg",
          duration: 2,
        });
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    // if(props?.isEmailManagement){
    //   setCallTypeId(props?.CallTypeId);
    //   setSubTypeId(props?.SubTypeId);
      
    // }

    if(loggedUser?.role == 14){
      getClientEnquiry();
      getNomineeEnquiry();
    }

    isCusBirthday();
    // if (shouldLog.current) {
    //   shouldLog.current = false;
    if(!props?.isComplaintsUser && !props.isEmailManagement && props?.isComplaintsUser !== undefined && props.isEmailManagement !== undefined){
      getHeadersData();
      getClaimsPrimaryAssessmentEnquiry();
     }
     else {
      getHeadersData(props?.serviceRequestData);
      //getClaimsPrimaryAssessmentEnquiry();
      
     }
    // else {
    //   getPOSIndividualData(props?.serviceId);
    // }
       
      // getDetails();

    //}
  }, [props?.searchPolicyData, isVerifiedCheck,isOpenTicket]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if(state?.isAdvanceVerifiedCheck){
      setIsVerifiedCheck(true)
    }
    
      },[state?.isAdvanceVerifiedCheck, props?.searchPolicyData, isVerifiedCheck]); //eslint-disable-line react-hooks/exhaustive-deps

  const getPremiumStatus = (status, stausList) => {
    if (status) {
      const lowerCaseStatus = status.toLowerCase();
      const filteredStatus = stausList?.find(
        (ele) => ele?.descItem?.toLocaleLowerCase() === lowerCaseStatus
      );
      return filteredStatus?.longDesc || ''; // Return an empty string if not found
    }
    return '';
  };



  const convertDate = (inputDate) => {
    const formattedDate = moment(inputDate, 'YYYYMMDD').format('DD/MM/YYYY');
    return formattedDate;
  };

  const convertDateYYMMDD = (inputDate) => {
    const formattedDate = moment(inputDate, 'YYYYMMDD').format('YYYYMMDD');
    return formattedDate;
  };

  // const checkData = [
  //   {
  //     id:1,
  //     name: "Signature Failed"
  //   },
  //   {
  //     id:2,
  //     name: "Documents Incomplete "
  //   },
  //   {
  //     id:3,
  //     name: "Documnets Not Clear"
  //   }
  // ]




  const isCusBirthday = () => {
    const dobString = props?.policyDetails?.sentDetailsObj?.dob;
    if (dobString) {
      const dob = new Date(
        parseInt(dobString.substring(0, 4)),
        parseInt(dobString.substring(4, 6)) - 1, // Months are zero-indexed
        parseInt(dobString.substring(6, 8))
      );

      // Get the current date
      const currentDate = new Date();

      // Calculate the difference in days
      const differenceInDays = Math.floor((currentDate - dob) / (24 * 60 * 60 * 1000));

      // Check if the difference is less than 5
      const isLessThan5Days = differenceInDays <= 5;
      setBirthdayIcon(isLessThan5Days)
    }


  }
  const getHeadersData = async (value) => {
    setIsLoading(true);
    let obj = {
      "policyNo": state?.policyNo?.replace(/\D/g, '') || props?.searchPolicyData?.policyNo ||value?.policyNo,
      "applicationNo": state?.applicationNo || props?.searchPolicyData?.applicationNo || '',
      "dob": state?.dob ? state?.dob.includes('/') ? state?.dob : convertDate(state?.dob) :
        props?.searchPolicyData?.dob ? props?.searchPolicyData?.dob.includes('/') ? props?.searchPolicyData?.dob : convertDate(props?.searchPolicyData?.dob) :
        props?.isComplaintsUser ? value?.dob :  null
    }
    let response = await apiCalls.getHeaderParameters(obj);
     let gcpresponse = await apiCalls.getFreeLookDetailsApi(process.env.REACT_APP_ENVIRONMENT == "PRODUCTION" ? state?.policyNo || props?.searchPolicyData?.policyNo ||value?.policyNo : '01817159')
     if (gcpresponse?.data?.statusCode === '200') {
      setNPSData(gcpresponse.data.response.policyAttribute[0].nps_score)
      form.setFieldsValue({ 
          applicationSignedDateNo:convertDate(gcpresponse?.data?.response?.applicationAttribute[0]?.proposalSignDate)
       })
     }
    if (response?.data?.responseHeader?.issuccess) {
      fetchCautionListHandler(response?.data?.responseBody?.identifiers?.applicationNo);
      props?.updatePolicyDetails(response?.data?.responseBody);
      setMoreInfo(response?.data?.responseBody)
      form.setFieldsValue({ 
        maturityDate : convertDate(response?.data?.responseBody?.saDetails?.riskcessiondate),
      })
      setData(response?.data?.responseBody);
      const newPremiumStatus = getPremiumStatus(response?.data?.responseBody?.planAndStatus?.premiumStatus, premiumStatusList);
      setPremiumStatus(newPremiumStatus);
      const newPolicyStatus = getPremiumStatus(response?.data?.responseBody?.planAndStatus?.policyStatus, policyStatusList);
      setPolicyStatus(newPolicyStatus);
      getCTST();
    }
    else {
      message.destroy()
      message.error({
        content: response?.data?.responseBody?.errormessage || "Something went wrong please try again!",
        className: "custom-msg",
        duration: 2,
      });
    }
  };

  // Define a reusable function for data transformation
const transformData = (data, keyy) => {
  const filteredData = data?.filter((ele) => ele.key === keyy);
  return filteredData[0]?.value?.map((item, index) => {
    let obj;

    if(keyy==='CALL_TYP'){
      obj= {
        ...item,
        label: item.mstDesc,
        // value: item.mstID,
        isCallType:true
      }
    }else if(keyy==='SUB_TYP'){   
      obj = {
        ...item,
        label: item.mstDesc,
        // value: item.mstID,
        isSubType:true
      }
          }else{
      obj = {
        ...item,
        label: item.mstDesc,
        value: item.mstID,
      }
    }
    return obj
  }
  );
};
const funCallType =(calltypes, subtypes) => {
  if(loggedUser?.boe && !state?.isBOE && !props?.isEmailManagement){
    return [...calltypes, ...subtypes ].map((ele, index)=>{
      return {
        ...ele,
        value: index+1
      }
    })
  }else{
    return [...calltypes ].map((ele, index)=>{
      return {
        ...ele,
        value: ele.mstID
      }
    })
  }


}
const funSubDescription =(calltypes, subtypes) => {
    return [...calltypes, ...subtypes ].map((ele, index)=>{
      return {
        ...ele,
        value: index+1
      }
    })

}

  const getCTST = () => {
    let obj =
    {
      "MasterRequest": [
        "CALL_TYP", "SUB_TYP", "REQST_MODE", "CLIENT_ROLE", "BNKACCTYP", "REQVIA", "CUST_PORTAL_ISSUE", "WEBSITE_PORTAL_ISSUE", "CALL_RELATED_ACTION", "CUSTOMER_QUERY_TOPIC", "PanUpdate", "PROCESS_NAME", "FORM_NAME","INTL_REQMNT","ANNUITY_TYP",
        "CAUSE_OF_EVENT", "NATURE_OF_DEATH", "POLICY_TYPES", "CLAIM_CATEGORY", "SOURCE_OF_INTIMATION", "CLAIM_INTIMATED_BY", "ASSURED_INCOME_PLAN","UW_Decision",
        "Is_LA_Nominee_Address_Same", "Select_Sub_Stage", "ASSESOR_DECI","Policy_Status_On_Date_Of_Death","Approver_Decision",
        "IIB_Data_Base_Check","Claim_Hotspot_Check", "Refer_Case_To","Reinstatement_Decision",
        "With_DGH", "Investigator","ASSESOR_SUB_DECI"
      ]
    }
    let CTST = apiCalls.ctst(obj,(loggedUser?.role == 14&&!isVerifiedCheck));
    CTST.then((val) => {
      setMasterData(val.data);
      const transformedData = transformData(val.data, "CALL_TYP");
      const transformedSubType = transformData(val.data, "SUB_TYP");
      const rquestModeData = transformData(val.data, "REQST_MODE");
      const uWDecisionData=transformData(val.data,'UW_Decision')
      const clientroleData = transformData(val.data, "CLIENT_ROLE");
      const bankTypeLU = transformData(val.data, "BNKACCTYP");
      const getReqViaData = transformData(val.data, "REQVIA");
      const getPortalData = transformData(val.data, "CUST_PORTAL_ISSUE");
      const getWebsiteData = transformData(val.data, "WEBSITE_PORTAL_ISSUE");
      const getRelatedData = transformData(val.data, "CALL_RELATED_ACTION");
      const getCustomerQueryData = transformData(val.data, "CUSTOMER_QUERY_TOPIC");
      const getAnnuityPlans = transformData(val.data, "ANNUITY_TYP");
      const getPANUpdateData = transformData(val.data, "PanUpdate");
      const gerProcessNameData = transformData(val.data, "PROCESS_NAME");
      const getSISData = transformData(val.data, "FORM_NAME");
      const getInternalRequirementTagValue = transformData(val.data, "INTL_REQMNT");
      const getCauseofEventData = transformData(val.data, "CAUSE_OF_EVENT");
      const getNatureofDeathData = transformData(val.data, "NATURE_OF_DEATH");
      const getPolicyTypesData = transformData(val.data, "POLICY_TYPES");
      const getCalimCategoryData = transformData(val.data, "CLAIM_CATEGORY");
      const getSourceOfInfoData = transformData(val.data, "SOURCE_OF_INTIMATION");
      const getClaimIntimatedData = transformData(val.data, "CLAIM_INTIMATED_BY");
      const getAssuredIncomePlanData = transformData(val.data, "ASSURED_INCOME_PLAN");
      const getLANomineeAddressData = transformData(val.data, "Is_LA_Nominee_Address_Same");
      const getSubStageData = transformData(val.data, "Select_Sub_Stage");
      const getAssessorsDecisionData = transformData(val.data, "ASSESOR_DECI");
      const getPolicyStatusDOB = transformData(val.data, "Policy_Status_On_Date_Of_Death");
      const getApproverDecision = transformData(val.data, "Approver_Decision");
      const getDataBaseCheck = transformData(val.data, "IIB_Data_Base_Check");
      const getHotSpotCheck = transformData(val.data, "Claim_Hotspot_Check");
      const getReferCaseTo = transformData(val.data, "Refer_Case_To");
      const getReinstatementDecision = transformData(val.data, "Reinstatement_Decision")
      const getWithDGH = transformData(val.data, "With_DGH")
      const getInvestigator = transformData(val.data, "Investigator")
      const getAssessorsSubDecisionData = transformData(val.data, "ASSESOR_SUB_DECI");
      //const getLANomineeAddress = transformData(val.data, "ASSURED_INCOME_PLAN");
      // Set state using the transformed data
       //setCALL_TyPES(transformedData);
      setCALL_TyPES(funCallType(transformedData, transformedSubType));
      setRequestModeLU(rquestModeData);
      setUWDecisionLU(uWDecisionData);
      setClientRoleLU(clientroleData);
      setBankAccTypeLU(bankTypeLU);
      setRequestReceivedViaLU(getReqViaData);
      setCursorPortalLU(getPortalData);
      setWebsitePortalLU(getWebsiteData);
      setCallRelatedActionLU(getRelatedData);
      setCustomerQueryLU(getCustomerQueryData);
      setPanUpdateLU(getPANUpdateData);
      setProcessNameLU(gerProcessNameData);
      setInternalRequirementTagValue(getInternalRequirementTagValue)
      setAnnuityPlans(getAnnuityPlans)
      setSISLU(getSISData);
      setCauseOfEventLU(getCauseofEventData);
      setNatureOfDeathLU(getNatureofDeathData);
      setPolicyTypeLU(getPolicyTypesData);
      setClaimCategoryLU(getCalimCategoryData);
      setSourceInformationLU(getSourceOfInfoData);
      setClaimIntimationLU(getClaimIntimatedData);
      setAssuredIncomePlanLU(getAssuredIncomePlanData);
      setLANomineeAddressLU(getLANomineeAddressData);
      setSubStageLU(getSubStageData);
      setAssessorsDecisionLU(getAssessorsDecisionData);
      setPolicyStatusDOBLU(getPolicyStatusDOB);
      setApproverDecisionLU(getApproverDecision);
      setDataBaseCheckLU(getDataBaseCheck);
       setHotSpotCheckLU(getHotSpotCheck);
       setReferCaseToLU(getReferCaseTo);
       setReinstatementDecisionLU(getReinstatementDecision);
       setWithDGHLU(getWithDGH);
       setInvestigatorLU(getInvestigator);
       setDecisionDescriptionLU(getAssessorsSubDecisionData);

      
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      message.destroy()
      message.error({
        content: err?.data?.responseBody?.errormessage,
        className: "custom-msg",
        duration: 2,
      });
    })

  }

  const getAssistanceDetails = () => {
    if(PosData === ''){
      if (!CallTypeId || !SubTypeId) {
        return
      }
    }else{
      if (!PosData.callType || !PosData.subType) {
        return
      }
    }
   
    let newCallTypeId = PosData === ''? CallTypeId: PosData.callType
    setIsLoading(true);
    let response = apiCalls.getAssistanceDetails(newCallTypeId, SubTypeId, loggedUser?.role);
    response
      .then((val) => {
        setAssistance(val.data)
        setIsLoading(false);
        setAssistanceOpen(true)
      })
      .catch((err) => {
        setIsLoading(false);
        message.destroy()
        message.error({
          content: response?.data?.responseBody?.errormessage || "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
      });
  };

  // const handlePayoutMethod = ()=>{
  //   getPayoutDetailsEnquiry();
  // }


  const getPayoutDetailsEnquiry = () => {
    // if (!CallTypeId || !SubTypeId) {
    //   return
    // }
    let modalData =[]
    setIsPayoutModal(true);
    setIsPayoutModalLoading(true);
    let response = apiCalls.getPayoutDetailsEnquiry(state?.policyNo || props?.policyDetails?.sentDetailsObj?.policyNo);
    response.then((val) => {
      if(val?.data?.payment_details){
        //modalData.push(val?.data?.payment_details);
        setpayoutModalData(val?.data?.payment_details);
        setIsPayoutModalLoading(false);
      }
      else {
        setIsPayoutModalLoading(false);
        message.destroy();
        message.error({
          content: response?.data?.responseBody?.errormessage || "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
      }
      })
      .catch((err) => {
        setIsPayoutModalLoading(false);
        message.destroy();
        message.error({
          content: response?.data?.responseBody?.errormessage || "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
      });
  };



  const getClientEnquiry = (e)=>{
        let obj = {
          clientNumber: props?.policyDetails?.sentDetailsObj?.poClientID
        
    };
    let empID = loggedUser?.allRoles[0]?.employeeID
    let response = apiCalls.getClientEnquiry(obj,empID);
    response
      .then((val) => {
        if (val?.data) {
         
          const res = val?.data?.responseBody

          const address =[
            res?.cltaddR01,
            res?.cltaddR02,
            res?.cltaddR03,
            res?.cltaddR04,
            res?.cltaddR05,
            res?.cltpcode,
            res?.ctrycode,
          ].filter(Boolean).join(', ');
          setAddress(address)
        } else {
     
          message.error({
            content:
              val?.data?.responseBody?.errormessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
      })
      .catch((err) => {
  
      });
  };

  const handleCuationList = () => {
    setCautionModal(true);
  }

  const fetchCautionListHandler = async (applicationNo) => {
    setIsLoading(true);
    try{
      const obj = {
        policyNo : state?.policyNo,
        applicationNo : applicationNo
      }
  
      const response = await apiCalls.GetFetchCautionList(obj);
      if(response?.data){
        const lastObject = response?.data[response?.data?.length - 1];
        if(lastObject?.status === 0){
          setIsCaution(true);
        }
        if(lastObject?.status === 1){
          setIsCaution(false);
        }
        const addPolicyNo = response.data.map(item => ({
          ...item,           // Spread the existing object properties
          policyNo: state?.policyNo // Add the `policyNo` field
      }));
        setCautionData(addPolicyNo)
        setIsLoading(false);
      }
    }
    catch(err){
      setIsLoading(false);
      console.log(err)
    }
  }
  const getNomineeEnquiry = async (checkItem) => {
    setIsLoading(true);
    try {
      const response = await apiCalls.getNomineeEnquiry(props?.policyDetails?.sentDetailsObj?.policyNo, loggedUser?.allRoles[0]?.employeeID);
      if (response?.data?.responseBody?.errorcode==0) {
        const res = response?.data?.responseBody;
        if (res?.nomineeEnquiry?.length > 0) {
          for (const val of res?.nomineeEnquiry) {
            if (val) {
             setRelation(val?.bnyrln);
             setClientName(val?.clientName);
            }
          }
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        message.error({
          content:
            response?.data?.responseBody?.errormessage ||
            "Something went wrong, please try again!",
          className: "custom-msg",
          duration: 2,
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const Assistancee = () => {
    getAssistanceDetails();
  }
  const handleCancel = () => {
    SetIsChecked(false)
    setIsVerifiedCheck('');
  }
  const handleOk = () => {
    setRequirementLoader(true);
    SetIsChecked(false)
  }
  const handleOnChange = (value) => {
    setCheckedValues(value);
  };

  const handleVerifyCheckBox = (e) => {
    // if(state?.isAdvanceVerifiedCheck){
    //   setIsVerifiedCheck(state?.isAdvanceVerifiedCheck)
    // }
   // else{
    if(e.target.checked){
      SetIsChecked(true)
      setIsVerifiedCheck(e.target.checked);
    }
    else {
      SetIsChecked(false)
      setIsVerifiedCheck(e.target.checked);
    }
   // }
  }

  const handleAllDetails = () => {
    setIsLoading(true);
    let response = apiCalls.getUserProfile(state?.policyNo, state?.poClientID,loggedUser?.userName);
    response
    .then((val) => {
      setCustomerProfile(val.data)
      setIsLoading(false);
      setAllDetailsOpen(true);
      })
      .catch((err) => {
        setIsLoading(false);
        message.destroy()
        message.error({
          content: response?.data?.responseBody?.errormessage || "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
      });
  };

  const dms = ()=>{


    const url =  process.env.REACT_APP_DMS_API_URL + `/omnidocs/WebApiRequestRedirection?Application=BPMPOLENQ&cabinetName=FG&sessionIndexSet=false&DataClassName=Future_Generali&DC.Application_no=${props?.policyDetails?.policyDetailsObj?.identifiers?.applicationNo}`;

  window.open(url, '_blank');
  }
  const handleLastPayments = async () => {
    setIsLoading(true);
    await handleGetReceiptEnquiryForPolicy();
    setShowOpenTickets(false);
    setShowLastPayments(true);
    setIsHistoricalComm(false);
  };

  const handleGetReceiptEnquiryForPolicy = async () => {
    
    const payload = state?.policyNo;

    try {
        let empID = loggedUser?.allRoles[0]?.employeeID
        const val = await apiCalls.GetReceiptEnquiryForPolicy(payload, empID);

        if (val?.data) {
          setPaymentTransactions(val?.data?.responseBody?.receiptEnquiryDetailsList1 || []);
          // setPaymentTransactionsData(val?.data?.responseBody?.receiptEnquiryDetailsList2 || []);
        } else {
            setPaymentTransactions([]);
            setPaymentTransactionsData([]);
        }
    } catch (err) {
        console.error('Failed to fetch receipt enquiry for policy:', err);
        setPaymentTransactions([]);
        setPaymentTransactionsData([]);
        
    } finally{
      setIsLoading(false);
    }
};

  const handleOpenTickets = () => {
    setShowOpenTickets(true);
    setShowArchievedTickets(false);
    setShowLastPayments(false);
    setIsHistoricalComm(false);
    getLastOpenTicketsData();
    setIsShowAllTicketsData(false);
    setIsTicketsPOSObj({});
  };
  const showArchivedTicketsHandler = () => {
    setShowArchievedTickets(true);
    setShowOpenTickets(false);
    setIsHistoricalComm(false);
  };
  const onClose = () => {
    setAllDetailsOpen(false);
    setFaqOpen(false);
  };
  const handlePaymentsCLose = () => {
    setShowLastPayments(false);
  };
  const handleOpenTicketsCLose = () => {
    setShowOpenTickets(false);
    setIsShowAllTicketsData(false);
    setIsTicketsPOSObj({});
    setIsSelectedTicketsData({});
  };

  const getLastOpenTicketsData = () => {
    //setTicketsLoader(true);
    setIsLoading(true);
    let response = apiCalls.getLastOpenTickets(state?.policyNo);
    response
      .then((val) => {
        const updatedDataArray = val?.data.map(item => {
          if (item.status === "REJECTED") {
            return { ...item, status: "CLOSED WITH REQUIREMENTS" };
          } else if (item.status === "PENDING") {
            return { ...item, closedDate: "-" };
          }
          return item;
        });
        setTicketsData(updatedDataArray);
        // setTicketsLoader(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // setTicketsLoader(false);
        setIsLoading(false);
        message.destroy()
        message.error({
          content: response?.data?.responseBody?.errormessage || "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
      });
  };
  const customHeader = (
    <>
      <div class="flex-container">
        <span className="headingg gridd flexxx p-0">
          Policy No : {data?.identifiers?.policyNo}
          &nbsp;
          <Tooltip
                      placement="leftTop"
                      title="Click for more info!"
                    >
                      <a id="pay-premium" 
                      onClick={() => handleClickIcon()}
                      >
                       <i class="bi bi-info-square" style={{color:"#b21f1f"}}></i>
                      </a>
                    </Tooltip>
                    &nbsp;
                    {
                      ([4,5,31,32,33,34].includes(loggedUser?.role)) && 
                        <Tooltip
                          placement="leftTop"
                          title="Click to add policy in caution list"
                        >
                          <a id="pay-premium" 
                              onClick={() => handleCuationList()}
                          >
                      <i class="bi bi-exclamation-triangle-fill" style={{color:"#b21f1f"}}></i>
                          </a>
                        </Tooltip>
                    }

          {NPSData && (
          <Tooltip title="NPS Score">
            <span className="square">{NPSData}</span>
          </Tooltip>)}
        </span>
        <span className="headingg gridd p-0">
          Application No : {data?.identifiers?.applicationNo}
        </span>
        <span className="headingg gridd flexxx p-0">
          LA Name : {data?.identifiers?.la_Name}
          {/* <Tooltip title="Happy Birthday">
            <span className="square">
              <i className="bi bi-cake"></i>
            </span>
          </Tooltip> */}
        </span>
        <span className="headingg gridd flexxx p-0">
          {" "}
          PO Name : {data?.identifiers?.po_Name}
          {/* <Tooltip title="NPS Score">
            <span className="square">5</span>
          </Tooltip> */}

          {BirthdayIcon &&

            <Tooltip title={convertDate(props?.policyDetails?.sentDetailsObj?.dob) || "-"}>
              <span className="square">
                <i className="bi bi-cake"></i>
              </span>
            </Tooltip>}
        </span>
      </div>
    </>
  );
  const getDetails=async(serialNum)=>{
    setIsLoading(true);
    const  val = await apiCalls.getTicketDetails(serialNum);
    if(val?.data){
      setIsLoading(false);
      setDetails(val?.data)
      
    }else{
      message.destroy();
      message.error({
        content: val?.data?.responseHeader?.message||"Something went wrong please try again!",
        className: "custom-msg",
        duration: 2,
      });
    }
  }
  const handleClickIcon=()=>{
setPolicyDetailsModal(true);
  }
  // const handleClickRupeeIcon=()=>{
  //   getUpcomingPayoutData();
  //   setRupeeDetailsModal(true);
  // };


  const handleClickRupeeIcon = async () => {
    try{
     
      const effectiveDateCoversion = convertDateYYMMDD(new Date());
      
      const obj = {
        //ClientID:props?.policyDetails?.sentDetailsObj?.poClientID,
          ClientID:null,
        PolicyNo: data?.identifiers?.policyNo,
      //  PolicyNo:"00107063",
        Action: "A",
        // EffectiveDate:"20241021",
        EffectiveDate:effectiveDateCoversion,
        CustomerRef:null,
        _HeaderPayload: {
            loggedinUser: loggedUser.userName
             //loggedinUser: "ezytek001@futuregenerali.in"
        }
      }
      setIsLoading(true);
      const response = await apiCalls.GetClientLevelInfo(obj);
        if(response.status === 200){
          setIsLoading(false);
          setUpcomingEvents(response?.data?.upcomingEvents);
          setPremiumEnquiryResponse([response?.data?._GetPremiumEnquiryResponse.responseBody]);
          setRupeeDetailsModal(true);
        }
    } catch(err) {
      console.log('error is', err)
      setRupeeDetailsModal(true);
      setUpcomingEvents([]);
      setPremiumEnquiryResponse([]);
    }
    
    };
 const getPOSIndividualData=async(data)=>{
   setIsLoading(true);
   const  val = await apiCalls.getPOSIndividualData(data);
   if(val?.data){
     setIsLoading(false);
     setDetails(val?.data)
     setIsTicketsDetails(val?.data);
     if(props?.isComplaintsUser){
      getHeadersData(val.data);
      props?.setServiceRequestData(val.data);
    }
   }else{
     message.destroy();
     message.error({
       content: val?.data?.responseHeader?.message||"Something went wrong please try again!",
       className: "custom-msg",
       duration: 2,
     });
   }
 }

  const handleTickectNoLink = (slectedRow) => {   
    setCallTypeId(slectedRow?.callType);
    setSubTypeId(slectedRow?.subType);
    // setIsShowAllTicketsData(true);
    setIsOpenTicket(true);
    setServiceId(slectedRow?.serviceNo)
    getPOSIndividualData(slectedRow?.serviceNo);
    setIsTicketsData(slectedRow);
    setIsLoading(true)
    setTimeout(()=>{
      getDetails(slectedRow?.serviceNo)
    },1000)
    setIsLoading(false)
    // setShowOpenTickets(false);
    // setIsSelectedTicketsData(slectedRow);
    // setIsTicketsPOSObj({
    //   serialNo: slectedRow?.serviceNo, isPOS: true, policyNo: state?.policyNo || props?.searchPolicyData?.policyNo,
    //   dob: state?.dob ? state?.dob.includes('/') ? state?.dob : convertDate(state?.dob) :
    //     props?.searchPolicyData?.dob ? props?.searchPolicyData?.dob.includes('/') ? props?.searchPolicyData?.dob : convertDate(props?.searchPolicyData?.dob) : null
    // })
  }
  const handleClose=()=>{
    setIsOpenTicket(false);
  }
  const handleTicketSubmit=()=>{
    setIsOpenTicket(false);
  }

  const handleClick = (e, item) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_Image_Path}${item?.fileLocation}${item?.documentName}`;
    const imageExtensions = ["jpeg", "jpg", "png"];
    const pdfExtension = "pdf";
    const fileExtension = item?.documentName?.split('.').pop().toLowerCase();
  
    if (imageExtensions.includes(fileExtension)) {
      const newWindow = window.open();
      newWindow.document.write(`
        <html>
          <head>
            <title>New Tab</title>
          </head>
          <body>
            <img src="${url}" alt="Image" style="width:100%; height:100%;">
          </body>
        </html>
      `);
      newWindow.document.close();
    }
     else if (fileExtension === pdfExtension) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } 
    else {
      const link = document.createElement('a');
      link.href = url;
      link.download = item.documentName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const hideArchivedTicketsHandler = () => {
    setShowArchievedTickets(false);
  };

  const fromDateHandler = (date, dateString) => {
    setFromDate(dateString);
  }

  const toDateHandler = (date, dateString) => {
    setToDate(dateString);
  };

  const getArchievedTickets  = () => {

    if (fromDate === null || toDate === null) {
      return null;
    }

    // Get today's date (without time)
    const today = new Date();
    today.setHours(0, 0, 0, 0);  // Set time to 00:00 to compare only dates

    // Convert fromDate and toDate to Date objects for comparison
    const fromDateObj = new Date(fromDate);
    fromDateObj.setHours(0, 0, 0, 0);
    const toDateObj = new Date(toDate);
    toDateObj.setHours(0, 0, 0, 0);

    // Check if fromDate or toDate is in the future
    if (fromDateObj > today || toDateObj > today) {
     // console.error("FromDate and ToDate cannot be in the future.");
      return;
    }

    try {
      setIsLoading(true);

      let obj = {
        "FromDate":"2022-01-01",
        "ToDate": toDate,
        "PolicyNo": data?.identifiers?.policyNo
      };

      const response = apiCalls.GetHistoricalCTST(obj);
      response.then(res => {
                setIsLoading(false);
                setArchivedTickets(res.data);
              })
              .catch(err => {
                setIsLoading(false);
                console.log(err);
              });

    } catch (err) {
      setIsLoading(false);
      console.error("An error occurred:", err);
    }
};

const formatDateToDDMMYYYY = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const cuationCommentsHandler = async () => {
  if(cautionComment === null || cautionComment === ''){
    message.warning("Please enter comments")
    return null;
  }
  
  try{
  const obj = {
		"CautionLstID": 1,
		"PolicyRef": state?.policyNo,
		"CustomerRef": 111222333,
		"CreatedBy": loggedUser?.name,
		"CreatedOn": new Date(),
		"ModifiedBy": loggedUser?.name,
		"ModifiedOn": new Date(),
		"Status": 2,
		"Comments": cautionComment,
		"policy": {
			"PolicyRef": 0,
			"LA_PolicyNo": state?.policyNo,
			"FG_ApplNo": data?.identifiers?.applicationNo,
			"CautionLstStatus": 0
		},
		"customer":{
			"CustomerRef":0,
			"LA_CustomerID":props?.policyDetails?.policyDetailsObj?.identifiers?.po_ClientID
		}
	};
    const response = await apiCalls.SaveCautionComments(obj)
    if(response.status === 200){
      message.success("Policy added to Caution-List");
      setIsCaution(true);
      const newComment = {
        ...response?.data, // Spread the existing properties from response data
        policyNo: state?.policyNo // Add policyNo to newComment object
    };
      const addNewComment = [
        ...cautionData, // Spread the existing items in the array
        newComment      // Add `newComment` as the next item in the array
    ];
      setCautionData(addNewComment)
      setCautionComment('')
    }
  }
  catch(err){
    console.log(err)
  }

};

const removeCautionHandler = async () => {

  if(cautionComment === null || cautionComment === ''){
    message.warning("Please enter comments")
    return null;
  }

  try{

    const obj = {
      "policy": {
        "PolicyRef": 0,
        "LA_PolicyNo": state?.policyNo,
        "FG_ApplNo": data?.identifiers?.applicationNo,
        "CautionLstStatus": 1
      },
      "appUser":{
          "UsrID":loggedUser?.name
      },
       "ClosureComments" : cautionComment
    }

    const response = await apiCalls.CloseCautionComments(obj);
    if(response?.status === 200){
      setCautionComment('')
      setIsCaution(false);
      message.success("Removed Policy from Caution-List");
      setCautionModal(false);
    } else {
      message.warning("Something went wrong")
    }
  }
  catch(err){
    console.log(err)
  }
}

const showHistoricalCommHandler = () => {
  setShowOpenTickets(false);
  setShowArchievedTickets(false);
  setShowLastPayments(false);
  setIsShowAllTicketsData(false);
  setIsHistoricalComm(!isHistoricalComm);
  getHistoricalCommunicationDetails()
};

const getHistoricalCommunicationDetails = async () => {
  setIsLoading(true);
  try{
    const obj = {
      'PolicyNo' : state?.policyNo
    }

    const response  = await apiCalls?.GetHistCommunications(obj);
    if(response?.data){
      setHistoricalCommData(response?.data);
      // console.log(response?.data);
      setIsLoading(false);
    } else {
      console.log('error is ', response?.error)
      setIsLoading(false);
    }
  }
  catch(err) {
    console.log('Error is ',err )
    setIsLoading(false);
  }
};

const communicationContentHandler = (contentData) => {  
  setCommContent(contentData);
};

const accoutDeailHandler = (ele) => {
  setAccountDetailsData(ele);
  setIsAccoundetail(true);
}

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <div className="main-start">
       
        <div className= {props?.isComplaintsUser ? "complaintsuser-width" : "w-94"}>
          {/* {SelectedSubTypeVal==="Revival With DGH" && <div className="mb-3">{customHeader}</div> } */}
          {!props?.isEmailManagement  && 
          <>
            <div>
              <Collapse
                accordion
                expandIconPosition={"end"}
                className="accordian customer acc1"
                defaultActiveKey={["1"]}
              >
                <Panel
                  header={customHeader}
                  key="1"
                  className={`fs-16 fw-500 mb-10 ${isCaution && "caution-list"}`}
                >
                  <div className="">
                    <div className="flex-container">
                      <section className="grid">
                        <div className="left-half">
                          <article>
                            <p>Customer Type</p>
                            <p>Plan Name</p>
                            <p>Policy Status</p>
                            <p>Premium Status</p>
                            {loggedUser?.role == 14 && <>
                              <p> <a className="hyperLink" onClick={() => setVerifiedPopup(true)}> Verified</a></p>
                            </>}
                          </article>
                        </div>
                        <div className="right-half">
                          <article>
                            <p>
                              <b>
                                {data?.planAndStatus?.customerType || "-"}{" "}
                              </b>
                            </p>
                            <p>
                              <b>
                                {" "}
                                {data?.planAndStatus?.planName ||
                                  "-"}{" "}
                              </b>
                            </p>
                            <p>
                              <b>
                                {" "}
                                {/* {data?.planAndStatus?.policyStatus ||
                                  "-"}{" "} */}
                                {policyStatus || "-"}{" "}
                              </b>
                            </p>
                            <p>
                              <b>
                                {" "}
                                {/* {getPremiumStatus(data?.planAndStatus?.premiumStatus) ||
                                  "-"}{" "} */}
                                {premiumStatus || "-"}{" "}
                              </b>
                            </p>
                            {loggedUser?.role == 14 && <><p>
                              <Checkbox type="checkbox"
                              onChange={(e) => handleVerifyCheckBox(e)} checked={isVerifiedCheck} />
                                {/* <h1>{isCheckedButton?"srrr":"hhhhh"}</h1> */}
                              </p>
                            {/* <Modal title="Verified"
                                open={isChecked}
                                closeIcon={false}
                                footer={null}
                              >
          <div  >
              <div className="reuirement">

              
              <table className="responsive-table">
                <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Description</th>
                  <th className="z-index">Select</th>
                </tr></thead>
                <tbody>
                  {checkData?.map((ele, index) => (
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td>{ele.name}</td>

                      <td>
                        {" "}
                        <Checkbox
                                       type="checkbox"

                                      onChange={(e) => {
                                        const value = e.target.checked
                                          ? [...checkedValues, ele.name]
                                          : checkedValues.filter(item => item !== ele.name);
                                        handleOnChange(value);
                                      }}
                                      checked={checkedValues.includes(ele.name)}
                                   />
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>
              </div> */}
              {/* <div className="text-area mt-16">
             <Form.Item
                      // label={<span>{"Comment"} <sup>*</sup>
                      // </span>}
                      name="requirementCmnt"
                      className="inputs-label mb-0"
                      rules={[
                        {
                          required: true,
                          message: "Enter Comments",
                        },
                      ]}
                    >
                       <TextArea rows={2} value={requirementCmnt} placeholder="Comments" onChange={(e)=>setRequirementCmnt(e.currentTarget.value)}/>
                    </Form.Item>
                  </div> */}
          {/* <div className="contact-details-btn">
          <Button type="primary" className="primary-btn" onClick={() => handleCancel()}>
                                    Cancel
                                  </Button>
                                  <Button type="primary" className="primary-btn" onClick={() => handleOk()}>
                                    Ok
                                  </Button>
              </div>
          </div>

          
      </Modal> */}
                              {/* <Modal title="Verified"
                                open={isChecked}
                                closeIcon={false}
                                footer={null}
                              >


                                {checkData.map((ele, index) => (
                                  <div style={{
                                    width: '100rem',
                                    display: 'Flex',
                                    textAlign: 'center',
                                  }}>

                                    <Checkbox
                                      onChange={(e) => {
                                        const value = e.target.checked
                                          ? [...checkedValues, ele.name]
                                          : checkedValues.filter(item => item !== ele.name);
                                        handleOnChange(value);
                                      }}
                                      checked={checkedValues.includes(ele.name)}
                                    >
                                      {ele.name}
                                    </Checkbox>

                                  </div>
                                ))}







                                <div className="contact-details-btn mt-24">

                                  <Button type="primary" className="primary-btn" onClick={() => handleCancel()}>
                                    Cancel
                                  </Button>
                                  <Button type="primary" className="primary-btn" onClick={() => handleOk()}>
                                    Ok
                                  </Button>
                                </div>
                              </Modal> */}

                            </>}
                          </article>
                        </div>
                      </section>
                      <section className="grid">
                        <div className="left-half">
                          <article>
                            <p>Sum Assured</p>
                            <p>PT</p>
                            <p>RCD</p>
                            <p>Assignment</p>
                          </article>
                        </div>
                        <div className="right-half">
                          <article>
                            <p>
                              <b>
                                {(data?.saDetails?.sumAssured && (
                                  <NumberFormat
                                    value={data?.saDetails?.sumAssured}
                                    decimalSeparator="."
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                  />
                                )) ||
                                  "-"}
                              </b>
                            </p>
                            <p>
                              <b>{data?.saDetails?.pt || "-"}</b>
                            </p>
                            <p>
                              <b>{convertDate(data?.saDetails?.rcd) || "-"}</b>
                            </p>
                            <p>
                              <b>{data?.assigneeDetails?.isPolicyAssigned || "N"}</b>
                            </p>
                          </article>
                        </div>
                      </section>
                      <section className="grid">
                        <div className="left-half">
                          <article>
                            <p>Premium</p>
                            <p>PPT</p>
                            <p>PTD</p>
                            <p>Mode</p>
                          </article>
                        </div>
                        <div className="right-half">
                          <article>
                            <p>
                              <b>
                                {(data?.premiumDetails?.modelPremiumAmount && (
                                  <NumberFormat
                                    value={
                                      data?.premiumDetails?.modelPremiumAmount
                                    }
                                    decimalSeparator="."
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                  />
                                )) ||
                                  "-"}
                              </b>
                            </p>
                            <p>
                              <b>{data?.premiumDetails?.ppt || "-"}</b>
                            </p>
                            <p>
                              <b>{convertDate(data?.premiumDetails?.ptd) || "-"}</b>
                            </p>
                            <p>
                              <b>{billFreq[data?.premiumDetails?.billFreq] || "-"}</b>
                            </p>
                          </article>
                        </div>
                      </section>
                      <section className="grid">
                        <div className="left-half">
                          <article>
                            <p>Branch</p>
                            <p>Channel</p>
                            <p>Agent</p>
                            <p>Orphan</p>
                          </article>
                        </div>
                        <div className="right-half">
                          <article>
                            <p>
                              <b>{data?.identifiers?.branchName || "-"} </b>
                            </p>
                            <p>
                              <b>{data?.salesDetails?.channel || "-"} </b>
                            </p>
                            <p>
                              <b>
                                {data?.salesDetails?.agentName || "-"}
                              </b>
                            </p>
                            <p>
                              <b>{data?.salesDetails?.orphanFlag || "-"}</b>
                            </p>
                          </article>
                        </div>
                      </section>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
            </>}

            {showLastPayments && (
              <>
                <Collapse
                  accordion
                  expandIconPosition={"end"}
                  defaultActiveKey={["1"]}
                  className="lats-tickets"
                >
                  <img
                    src={CloseIcon}
                    alt=""
                    className="close-icons"
                    onClick={() => handlePaymentsCLose()}
                  ></img>
                  <Panel
                    header="Payment Transactions"
                    key={1}
                    className="table-top"
                  >
                    <div>
                      <TableFormat
                        headerData={PAYMENT_TRANSACTIONS}
                        data={paymentTransactions}
                        ticketsLoader={ticketsLoader}
                      ></TableFormat>
                    </div>
                  </Panel>
                </Collapse>
              </>
            )}
            {showOpenTickets && (
              <>
                <Collapse
                  accordion
                  expandIconPosition={"end"}
                  defaultActiveKey={["1"]}
                  className="lats-tickets"
                >
                  <img
                    src={CloseIcon}
                    alt=""
                    className="close-icons"
                    onClick={() => handleOpenTicketsCLose()}
                  ></img>
                  {/* <Panel
                    header="All Tickets Details"
                    key={1}
                    className="table-top"
                  > */}
                    <div>
                      <TableFormat
                        headerData={LAST5_OPEN_TICKETS || []}
                        data={ticketsData}
                        ticketsLoader={ticketsLoader}
                        handleTickectNoLink={handleTickectNoLink}
                      ></TableFormat>
                    </div>
                  {/* </Panel> */}
                </Collapse>
              </>
            )}
             {showArchievedTickets && (
              <>
                <Collapse
                  accordion
                  expandIconPosition={"end"}
                  defaultActiveKey={["1"]}
                  className="lats-tickets"
                >
                  <img
                    src={CloseIcon}
                    alt=""
                    className="close-icons"
                    onClick={() => hideArchivedTicketsHandler()}
                  ></img>
                  <div className="form-container">

                  <Form layout="inline">
                    <Form.Item
                      label="From Date"
                      name="fromDate"
                      rules={[{ required: true, message: 'Select From Date' }]}
                    >
                      <DatePicker 
                        format={dateFormat} 
                        className="date-picker" 
                        onChange={(date, dateString) => fromDateHandler(date, dateString)}
                        // disabledDate={(current) => current && current > new Date()}
                        disabledDate={(current) => 
                          current && (current < new Date(2022, 0, 1) || current > new Date()) }
                      />
                    </Form.Item>

                    <Form.Item
                      label="To Date"
                      name="toDate"
                      rules={[{ required: true, message: 'Select To Date' }]}
                    >
                      <DatePicker 
                        format={dateFormat} 
                        className="date-picker" 
                        onChange={(date, dateString) => toDateHandler(date, dateString)}
                        disabledDate={(current) => current && current > new Date()}
                      />
                    </Form.Item>
                    
                    <Button 
                      icon={<SearchOutlined />} 
                      className="search-button" 
                      onClick={getArchievedTickets}
                    />
                  </Form>
                  <h6 style={{ fontSize: '10px', color: '#b21f1f',padding: '10px'}}>Data Available Only from Jan-2022 till today</h6>

      {/* <Table
        style={{ marginTop: '20px' }}
        bordered
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      /> */}
    </div>
              
<br/>
     <div>
                      <TableFormat
                        headerData={LAST5_OPEN_ARCHIEVEDTICKETS || []}
                        data={archivedTickets}
                         ticketsLoader={ticketsLoader}
                        // handleTickectNoLink={handleTickectNoLink}
                      ></TableFormat>
                    </div>
                </Collapse>
              </>
            )}
          
          {((!showLastPayments && !showOpenTickets &&!showArchievedTickets && CALL_TyPES?.length > 0) || isShowAllTicketsData) && !props?.isComplaintsUser && (
            <>
              <TypesComponent
                customerData={(isTicketsPOSObj?.isPOS && isTicketsPOSObj) || 
                  (props?.isEmailManagement && props?.searchPolicyData) || state || props?.searchPolicyData} CALL_TyPES={CALL_TyPES} masterData={masterData} clientRoleLU={clientRoleLU} requestModeLU={requestModeLU }

                  uwDecisionLU={uwDecisionLU}
                bankAccTypeLU={bankAccTypeLU} requestReceivedViaLU={requestReceivedViaLU}
                cursorPortalLU={cursorPortalLU} websitePortalLU={websitePortalLU} callRelatedActionLU={callRelatedActionLU}
                customerQueryLU={customerQueryLU}
                isEmailManagement={props?.isEmailManagement}
                isComplaintsUserTabs={props?.isComplaintsUserTabs}
                EmailResponse={props?.EmailResponse}
                panUpdateLU={panUpdateLU}
                processNameLU={processNameLU}
                sisLU={sisLU}
                loggedUser={loggedUser}
                callCenterLU={callCenterLU}
                callCenterSubTypeLU={callCenterSubTypeLU}
                isVerifiedCheck={isVerifiedCheck}
                setSubTypeId={setSubTypeId}
                setCallTypeId={setCallTypeId}
                setSelectedSubTypeVall ={setSelectedSubTypeVall}
                isShowAllTicketsData={isShowAllTicketsData}
                CallTypeId={CallTypeId || props?.CallTypeId}
                SubTypeId={SubTypeId|| props?.SubTypeId}
                isSelectedTicketsData={isSelectedTicketsData}
                setPosData = {setPosData}
                interlRequirementTagValue = {interlRequirementTagValue}
                annuityPlans = {annuityPlans}
                causeOfEventLU={causeOfEventLU}
                natureOfDeathLU={natureOfDeathLU}
                policyTypeLU={policyTypeLU}
                claimCategoryLU={claimCategoryLU}
                claimIntimationLU={claimIntimationLU}
                sourceInformationLU={sourceInformationLU}
                assuredIncomePlanLU={assuredIncomePlanLU}
                laNomineeAddressLU={laNomineeAddressLU}
                subStageLU={subStageLU}
                assessorsDecisionLU={assessorsDecisionLU}
                policyStatusDOBLU={policyStatusDOBLU}
                approverDecisionLU = {approverDecisionLU}
                dataBseCHeckLU = {dataBseCHeckLU}
                hotSpotCheckLU = {hotSpotCheckLU}
                referCaseToLU = {referCaseToLU}
                reinstatementDecisionLU={reinstatementDecisionLU}
                withDGHLU={withDGHLU}
                investigatorLU={investigatorLU}
                decisionDescriptionLU={decisionDescriptionLU}
              ></TypesComponent>
            </>
          )}
           
          {
              isHistoricalComm && 
              <aside className="float-left">
                <HistoricalCommunication historicalCommData={historicalCommData} setIsHistoricalComm={setIsHistoricalComm} setIsCommunicationContent={setIsCommunicationContent} communicationContentHandler={communicationContentHandler}/>
              </aside>
          }

          {!props?.isEmailManagement   &&
            <div className="widgets-start">
              <aside className="fixed-nav active">
                <div className="widgets-1">
                {/* {([4,5,11,12,31,32,33,34].includes(loggedUser?.role)) &&  */}
                {/* <div className="item ">
                    <Tooltip
                      placement="leftTop"
                      title="Transaction Documents"
                    >
                      <a id="pay-premium"  onClick={() => setShowUplodedDocs(true)} >
                      {/* <i className="bi bi-file-text"></i> */}
                      {/* <img src={ transactionImg} width='40px' height='40px' /> */}
                    
                        {/* <p>Transaction Documents</p> */}
                      {/* </a> */}
                    {/* </Tooltip> */}
                  {/* </div>  */}
                  {/* } */}

                  <div className="item">
                    <Tooltip
                      placement="leftTop"
                      title="Asssistance/FAQ Details"
                    >
                      <a id="pay-premium" onClick={() => Assistancee()}>
                        <img src={Assistance} alt="" className="m-auto"></img>
                        {/* <p>Assistance</p> */}
                      </a>
                    </Tooltip>
                  </div>
                  {/* {(loggedUser?.role === 1) &&  */}
                  <div className="widgets">
                    <div className="item ">
                      <Tooltip
                        placement="leftTop"
                        title="Payments"
                      >
                        <a id="pay-premium" onClick={() => handleLastPayments()}>
                          <i className="bi bi-currency-rupee"></i>
                          {/* <p>Renewal Payments</p> */}
                        </a>
                      </Tooltip>
                    </div>
                  
                      <div className="item">
                    <Tooltip
                      placement="leftTop"
                      title="Payouts"
                    >
                      <a id="pay-premium" title="Payouts" onClick={() => getPayoutDetailsEnquiry()}>
                      <img src={payoutsImg} width='40px' height='40px' />
                        {/* <p>Payout</p> */}
                      </a>
                    </Tooltip>
                  </div>
                  </div>
                     {/* }   */}
                  {/* <div className="item">
                    <Tooltip
                      placement="leftTop"
                      title="Click here for Open Tickets"
                    >
                      <a id="pay-premium" onClick={() => handleOpenTickets()}>
                        <i className="bi bi-ticket"></i>
                        <p>Tickets</p>
                      </a>
                    </Tooltip>
                  </div> */}

                  {/* <div className="item">
                    <Tooltip placement="leftTop" title="Click here for DMS">
                      <a id="pay-premium">
                        <i className="bi bi-file-text"></i>
                        <p>DMS</p>
                      </a>
                    </Tooltip>
                  </div> */}
                </div>

                <div className="widgets">
                <div className="item ">
                    <Tooltip
                      placement="leftTop"
                      title="Oasis Tickets"
                    >
                      <a id="pay-premium" onClick={() => handleOpenTickets()}>
                        <i className="bi bi-ticket"></i>
                        {/* <p>Tickets</p> */}
                      </a>
                    </Tooltip>
                  </div> <div className="item">
                    <Tooltip
                      placement="leftTop"
                      title="Archived Tickets"
                    >
                      <a id="pay-premium" 
                       onClick={() => showArchivedTicketsHandler()}
                      >
                        {/* <i className="bi bi-ticket"></i> */}
                        <img src={archivedImg} width='40px' height='40px' />
                        {/* <p>Archived Tickets</p> */}
                      </a>
                    </Tooltip>
                  </div>
              {/* {(loggedUser?.role === 4 ||loggedUser?.role === 11 || loggedUser?.role === 12) &&  */}
                <div className="item">
                    <Tooltip placement="leftTop" title="DMS">
                      <a id="pay-premium" onClick={() => dms()}>
                      {/* <i className="bi bi-file-text"></i> */}
                      <img src={dmsImg} width='40px' height='40px' />
                        {/* <p>DMS</p> */}
                      </a>
                    </Tooltip>
                  </div>
                  {([4,5,11,12,22,31,32,33,34].includes(loggedUser?.role)) ?
                  <div className="item ">
                    <Tooltip
                      placement="leftTop"
                      title="Transaction Documents"
                      
                    >
                      <a id="pay-premium"  onClick={() => setShowUplodedDocs(true)} >
                      {/* <i className="bi bi-file-text"></i> */}
                      <img src={ transactionImg} width='40px' height='40px' />
                    
                        {/* <p>Transaction Documents</p> */}
                      </a>
                    </Tooltip>
                  </div>
                  : 
                  <div className="item ">
                  <Tooltip
                    placement="leftTop"
                    title="Transaction Documents"
                    
                  >
                    <a id="pay-premium" >
                    {/* <i className="bi bi-file-text"></i> */}
                    <img src={ transactionImg} width='40px' height='40px' />
                  
                      {/* <p>Transaction Documents</p> */}
                    </a>
                  </Tooltip>
                </div>
                  }
                  

                  {/* <div className="item">
                    <Tooltip
                      placement="leftTop"
                      title="Payouts"
                    >
                      <a id="pay-premium" title="Payouts" onClick={() => getPayoutDetailsEnquiry()}>
                      <img src={payoutsImg} width='40px' height='40px' />
                        {/* <p>Payout</p> */}
                      {/* </a>
                    </Tooltip>
                  </div>  */}


                  <div className="item">
                    <Tooltip placement="leftTop" title="Profile">
                      <a id="pay-premium" onClick={() => handleAllDetails()}>
                        <Progress type="circle" size={35} percent={75} />
                        {/* <p>Profile</p> */}
                      </a>
                    </Tooltip>
                  </div>
                  {([1,4,5,11,12,22,31,32,33,34].includes(loggedUser?.role)) && 
                  <div className="item">
                    <Tooltip
                      placement="leftTop"
                      title="Upcoming Renewal/Payout"
                    >
                     <a id="pay-premium"
                          onClick={handleClickRupeeIcon}
                          >
                       <img src={upcomingImg} width="40px" height="40px"/>
                        {/* <p>Upcoming Renewal/<br/>Payout</p> */}
                      </a> 
                    </Tooltip>
                  </div>
                     } 
                  {/* <div className="item">
                    <Tooltip
                      placement="leftTop"
                      title="Click here for Payments"
                    >
                      <a id="pay-premium">
                        <i className="bi bi-currency-rupee"></i>
                        <p>yyyyy</p>
                      </a>
                    </Tooltip>
                  </div> */}

                  {/* <div className="item">
                    <Tooltip
                      placement="leftTop"
                      title="Click here for Payments"
                    >
                      <a id="pay-premium">
                        <i className="bi bi-currency-rupee"></i>
                        <p>zzzzzz</p>
                      </a>
                    </Tooltip>
                  </div> */}
                </div>
                <div className="item">
                    <Tooltip
                      placement="leftTop"
                      title="Historical Communication"
                    >
                     <a id="pay-premium" onClick={showHistoricalCommHandler}
                          //onClick={handleClickRupeeIcon}
                     >
                       <img src={scrollImg} width="40px" height="40px" onClick={showHistoricalCommHandler}/>
                          {/* <p>Upcoming Renewal/<br/>Payout</p> */}
                     </a> 
                    </Tooltip>
                  </div>
              </aside>
            </div>}
        </div>
       
      </div>

      <Drawer
        title="Profile"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={allDetailsOpen}
        maskClosable={false}
        closeIcon={
          <Tooltip title="Close">
            <img src={CloseIcon} alt="" />
          </Tooltip>
        }
      >
        <div>
          <table className="table table-bodered Profile">
            <tbody>
              {/* <tr>
                <td>NPS Score</td>
                <td>{NPSData}</td>
              </tr>
              <tr>
                <td>Customer Sentiment</td>
                <td>{customerProfile.customerSentiment}</td>
              </tr> */}
              {/* <tr>
                <td colSpan={2} className="text-center"> <b>Near Future Event </b></td>
              </tr> */}
              <tr>
                <td>PO DOB</td>
                <td>{customerProfile.ladob}
                <span className="profile-alert">{customerProfile.ladobAlert ?? ""}</span>
                </td>
              </tr>
              {/* <tr>
                <td>Renewal Due Date</td>
                <td>{customerProfile.renewalDueDate}
                <span className="profile-alert">{customerProfile.renewalDueDateAlert ?? ""}</span>
                </td>
              </tr> */}
              <tr>
                <td>Policy payout</td>
                <td>{customerProfile.policyPayout}
                <span className="profile-alert">{customerProfile.policyPayoutAlert ?? ""}</span>
                </td>
              </tr>
              <tr>
                <td>Loan (If active)</td>
                <td>{customerProfile.loan}
                <span className="profile-alert">{customerProfile.loanAlert ?? ""}</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="text-center"> <b> Customer Profile</b></td>

              </tr>
              <tr>
                <td>CKYC Status</td>
                <td>{customerProfile.ckycStatus}
                <span className="profile-alert">{customerProfile.ckycStatusAlert ?? ""}</span>
                </td>
              </tr>
              {/* <tr>
                <td>PAN</td>
                <td>{customerProfile.pan}
                <span className="profile-alert">{customerProfile.panAlert ?? ""}</span></td>
              </tr> */}
              <tr>
                <td>PAN Aadhar Seeding Status</td>
                <td>{customerProfile.aadharSeedingStatusDate}
                <span className="profile-alert">{customerProfile.aadharSeedingStatusDate === "No" ? (
                <>
                <span  className="profile-alert">{customerProfile.aadharSeedingStatusDateAlert}</span>
                  <a
                    href="https://eportal.incometax.gov.in/iec/foservices/#/pre-login/bl-link-aadhaar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click to Proceed
                  </a>
                </>
              ) : (
                <div className="profile-success">PAN-Aadhar is Linked</div>
              )}</span></td>
              </tr>
              {/* <tr>
               <td>Click to get it linked</td>
               <td>
               <span className="profile-alert">
               <a href="https://eportal.incometax.gov.in/iec/foservices/#/pre-login/bl-link-aadhaar" target="_blank" rel="noopener noreferrer">
              https://eportal.incometax.gov.in/iec/foservices/#/pre-login/bl-link-aadhaar </a>
               </span>
               </td>
              </tr> */}
              <tr>
                <td>Mobile</td>
                <td>{customerProfile.contactDetailsMobile}
                <span className="profile-alert">{customerProfile.contactDetailsMobileAlert ?? ""}</span></td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{customerProfile.contactDetailsEmail}
                <span className="profile-alert">{customerProfile.contactDetailsEmailAlert ?? ""}</span></td>
              </tr>
              <tr>
                <td>Updated Bank Account</td>
                <td>{customerProfile.updateBankAccount}
                <span className="profile-alert">{customerProfile.updateBankAccountAlert ?? ""}</span></td>
              </tr>
              <tr>
                <td>Auto Pay Updated</td>
                <td>{customerProfile.updateAutoPay}
                <span className="profile-alert">{customerProfile.updateAutoPayAlert ?? ""}</span></td>
              </tr>
              <tr>
              <td> Mobile APP/Portal</td> 
                 <td>
                 Mobile
                <span class="icon-check">&#10004;</span> 
                &nbsp;&nbsp;&nbsp;Portal
                <span class="icon-cross">&#10008;</span> 
             </td>
          
                 {/* <td>Registered on  
                 <i className="bi bi-phone icon-red"></i>
                 <i className="bi bi-ethernet icon-red"></i>
                 </td> 
                <td>{customerProfile.registeredonPortal}
                <span className="profile-alert">{customerProfile.registeredonPortalAlert ?? ""}</span></td> */}
              </tr>
              {/* <tr>
                <td>Registered on mobile app</td>
                <td>{customerProfile.registeredonMobileApp}
                <span className="profile-alert">{customerProfile.registeredonMobileAppAlert ?? ""}</span></td>
              </tr> */}
              {/* <tr>
                <td>Language/Mode</td>
                <td>{customerProfile.languageMode}</td>
              </tr> */}
              <tr>
                <td>Preferred Language</td>
                <td>
                <select
              value={languageMode == "" ? customerProfile.languageMode : languageMode}
              onChange={handleLanguageChange}
               style={{ width: '200px', height: '38px' }}
            >
             <option value="">Select an option</option>
             <option value="bengali">Bengali</option>
             <option value="english">English</option>
             <option value="gujarati">Gujarati</option>
             <option value="hindi">Hindi</option>
             <option value="kannada">Kannada</option>
             <option value="malayalam">Malayalam</option>
             <option value="marathi">Marathi</option>
             <option value="tamil">Tamil</option>
             <option value="telugu">Telugu</option>
            </select>
          </td>
          <td>
            
          {/* <button type="submit" className='profilesave'>Save</button> */}
          
          <Button type="submit" className="save-Button-Color"
            // onClick={() => languageMode == "" ? "" :saveDropdownProfileValues("Language")} 
            onClick={() => {
              if (languageMode !== "") {
                saveDropdownProfileValues("Language");
              }
            }}
          htmlType="submit" 
            
            >Save
            </Button>
            </td>
              </tr>
              <tr>
                <td>Preferred Mode of Communication</td>
                
                <td className="ant-select-selector">
                <select
              value={communicationMode == "" ? customerProfile.communicationMode: communicationMode}
              onChange={handleCommunicationChange}
              style={{ width: '200px', height: '38px' }}
            >
              <option value="">Select an option</option>
              <option value="email">Email</option>
              <option value="telephone">Telephone</option>
              <option value="letter">Letter</option>
              <option value="sms">SMS</option>
            </select>
       </td>
          <td>
            {/* <button onClick={saveProfile} type="submit" className='profilesave'>Save</button> */}
            <Button type="submit" className="save-Button-Color" 
             onClick={() => {
              if (communicationMode !== "") {
                saveDropdownProfileValues("Communication");
              }
            }}
            htmlType="submit" 
             >Save
            </Button>
                </td>
                </tr>
                <tr>
          <td>Customer Sentiment</td>
          <td className="ant-select-selector">
            <select
              value={customerSentiment == "" ? customerProfile.customerSentiment : customerSentiment}
              onChange={handleSentimentChange}
              style={{ width: '200px', height: '38px' }}
            >
                
                <option value="select">Select an option</option>
              <option value="positive">Positive</option>
              <option value="negative">Negative</option>
              <option value="neutral">Neutral</option>
            </select>
          </td>
          <td>
          {/* <button type="submit" className='profilesave'>Save</button> */}
          <Button type="submit" className="save-Button-Color" 
             onClick={() => {
              if (customerSentiment !== "") {
                saveDropdownProfileValues("Customer");
              }
            }}
          htmlType="submit" 
            >Save
            </Button>
          </td>
        </tr>
            </tbody>
          </table>
        </div>

      </Drawer>
      <Drawer
        title="FAQ"
        placement={"right"}
        width={500}
        onClose={onClose}
        open={faqOpen}
        maskClosable={false}
        closeIcon={
          <Tooltip title="Close">
            <img src={CloseIcon} alt="" />
          </Tooltip>
        }
      >
        <div>
          <h1>Payment Link</h1>
          <p>
            <ul>
              <li>
                Now you can choose to send customers payment link over
                registered email/SMS/whatsapp.
              </li>
              <li>
                SR will be raised and Auto closed. No further action is required
                from your side for this SR.
              </li>
            </ul>
          </p>
        </div>
      </Drawer>



      <Modal
        title="Assistance"
        open={assistanceOpen}
        destroyOnClose={true}
        width={1200}
        closeIcon={false}
        footer={null}
      >

        <div>

          <div className="reuirement">
            <table className="table responsive-table assistanceTable">

              <tbody>
              {assistance?.length > 0 && (
  <ul>
    {assistance.map((item, ind) => (
        <li key={ind} className="leftalignment">
        {item.assistanceDesc}
      </li>
    ))}
  </ul>
)}
                {assistance?.length === 0 &&
                  <tr>
                    <td>

                      <div className="text-center"><span>No Data Available</span></div>
                    </td>
                  </tr>}
              </tbody>
            </table>
          </div>

          <div className="contact-details-btn">


            <Button
              type="primary"
              className="primary-btn"
              onClick={() => setAssistanceOpen(false)}
            >
              Close
            </Button>
          </div>

        </div>

      </Modal>
       
<Modal
        className="po-modal"
        title="Policy Details"
        open={policyDetailsModal}  
        destroyOnClose={true}
        width={800}
        height={500}
        keyboard={true}  
        onCancel={(e) => {
          if (e.key === 'Escape') {
            setPolicyDetailsModal(false);
          }
        }}
        closeIcon={
          <Tooltip title="Close">
            <span onClick={() => setPolicyDetailsModal(false)}>
             <img src={CloseIcon} alt=""></img>
            </span>
          </Tooltip>
        }
        footer={null}
        maskClosable={false}
      >
     
     <Form
    name="wrap"
    labelCol={{
      flex: "35%",
    }}
    labelAlign="left"
    labelWrap
    wrapperCol={{
      flex: 1,
    }}
    colon={false}
    form={form}
    // onFinish={searchData}
    autoComplete="off"
  >
    <Row gutter={[12, 12]} className="mb-10">

    <Col xs={24} sm={24} md={24} lg={24} xxl={24}> 
        <Form.Item
          name="applicationSignedDateNo"
          label="Application Signed Date"
        >
        <Input
          className="cust-input"
          //readOnly
          disabled={true}
                 />
        </Form.Item>
      </Col>

    {/* <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>
         <Form.Item
            name="riskCommencementDateNo"
            label="Risk Commencement Date"
              >
            <Input
              className="cust-input"
              //readOnly
              disabled={true}
            />
          </Form.Item>
                
          </Col> */}

          <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>
            <Form.Item
              name="maturityDate"
              label="Maturity Date" 
                >
              <Input
               className="cust-input"
                //readOnly
                disabled={true}
                  />
                </Form.Item>
              </Col>

    </Row>

    <Row gutter={[12, 12]}
    >
              <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="left-half">
                <table border className="table responsive-table assistanceTable">
                  <thead>
                    <tr>
                      <th>Rider Name</th>
                      {/* <th>RCD</th> */}
                      <th>Term</th>
                      <th>Sum Assured</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                 <tbody>
               {
                (Object.keys(moreInfo).length > 0) &&<>
                  {isRiderData?.map((item,index) => (
                    item?.longdesc && (
                          <tr  key={index}>
                             <td>{item?.longdesc}</td>
                            <td>{moreInfo?.saDetails?.pt}</td>
                            <td>{item?.sumins}</td>
                            <td>{moreInfo?.planAndStatus?.policyStatus === "IF" ? "In Force" : moreInfo?.planAndStatus?.policyStatus}</td>
                          </tr>
                    )
                        ))}
                         {isRiderData?.length === 0 && (
                          <tr>
                            <td colSpan="5">
                              <div className="text-center">
                                <span>No data available</span>
                              </div>
                            </td>
                          </tr>
                        )}
               </>}
                  </tbody>
                </table>
              </div> 
              </Col>
              </Row>
  </Form>
</Modal>

<Modal
        className="po-modal"
        // title="Policy Details"
        open={cautionModal}  
        destroyOnClose={true}
        width={800}
        height={500}
        keyboard={true}  
        onCancel={(e) => {
          if (e.key === 'Escape') {
            setCautionModal(false);
          }
        }}
        closeIcon={
          <Tooltip title="Close">
            <span onClick={() => setCautionModal(false)}>
             <img src={CloseIcon} alt=""></img>
            </span>
          </Tooltip>
        }
        footer={null}
        maskClosable={false}
      >
     
     <Form
    name="wrap"
    labelCol={{
      flex: "35%",
    }}
    labelAlign="left"
    labelWrap
    wrapperCol={{
      flex: 1,
    }}
    colon={false}
    form={form}
    // onFinish={searchData}
    autoComplete="off"
  >
    <Row gutter={[12, 12]} className="mb-10 mt-10">
      <Col className="comment-box-col" xs={24} sm={24} md={24} lg={24} xxl={24}>
        <TextArea
          rows={6}
          value={cautionComment}
          placeholder="Add Comments"
          maxLength={4000} // Set maximum length here
          onChange={(e) => setCautionComment(e.currentTarget.value)}
          style={{ width: '100%' }}
        />
      </Col>
    </Row>
    <div className="d-flex">
        <Button type="primary" className="primary-btn save-btn" size="small" onClick={cuationCommentsHandler}>
          Save Comments
        </Button>

        <Button type="primary" className="primary-btn remove-btn upload-button" size="small" onClick={removeCautionHandler}>
          Remove Policy
        </Button>
      </div>

    <Row gutter={[12, 12]}
    >
              <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="left-half">
                <table border className="table responsive-table assistanceTable">
                  <thead>
                    <tr>
                      <th>Policy No</th>
                      <th>Created By</th>
                      <th>Created On</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                 <tbody>
                     {cautionData?.length > 0 ? (
                      cautionData.map((item, ind) => (
                        <tr key={ind}>
                          <td>{item.policyNo}</td>
                          {/* <td>{item.rcd}</td> */}
                          <td>{item.createdBy}</td>
                          <td>{formatDateToDDMMYYYY(item.createdOn)}</td>
                          <td>{item.comments}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          <span>No Data Available</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div> 
              </Col>
              </Row>
  </Form>
</Modal>

<Modal
        className="po-modal"
        title="    "
        open={RupeeDetailsModal}  
        destroyOnClose={true}
        width={1200}
        height={500}
        keyboard={true}  
        onCancel={(e) => {
          if (e.key === 'Escape') {
            setRupeeDetailsModal(false);
          }
        }}
        closeIcon={
          <Tooltip title="Close">
            <span onClick={() => setRupeeDetailsModal(false)}>
             <img src={CloseIcon} alt=""></img>
            </span>
          </Tooltip>
        }
        footer={null}
        maskClosable={false}
      >
     
     <Form
    // name="wrap"
    // labelCol={{
    //   flex: "35%",
    // }}
    // labelAlign="left"
    // labelWrap
    // wrapperCol={{
    //   flex: 1,
    // }}
    // colon={false}
    // form={form}
    // onFinish={searchData}
    // autoComplete="off"
  >
    {/* <Row gutter={[12, 12]}
    > */}
    
              {/* <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}> */}
              <div className="left-half">
              <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
  <thead style={{ backgroundColor: "#b3201f", color: 'black' }}>
    <tr>
      <th colSpan="4" style={{ color: 'white',textAlign:'left' }}>Upcoming Renewal Payments</th>
    </tr>
    <tr>
      <th>Policy No</th>
      <th>Product Name</th>
      <th>Amount</th>
      <th>Next Due Date</th>
    </tr>
  </thead>
  <tbody>
    {premiumEnquiryResponse?.length > 0 ? (
      premiumEnquiryResponse.map((item, ind) => (
        <tr key={ind}>
          <td>{item?.chdrsel}</td>
          <td>{item?.ctypedes}</td>
          <td>{item?.hpayamT01}</td>
          <td>{item?.ptdate ? convertDate(item?.ptdate):null}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="5" className="text-center">
          <span>No Data Available</span>
        </td>
      </tr>
    )}
  </tbody>
</table>
              </div> 
              {/* </Col> */}
              {/* </Row> */}
              <br/> <br/>
      
           <div className="left-half">
                <table  border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead style={{ backgroundColor: "#b3201f", color: 'black' }}>
                  <tr>
              <th colSpan="4" style={{color: 'white', textAlign:'left'}}>Upcoming Payouts</th>
            </tr>
                    <tr>
                      <th>Policy No</th>
                      <th>Product Name</th>
                      <th>Amount</th>
                      <th>Next Due Date</th>
                    </tr>
                  </thead>
                 <tbody>
                     {upcomingEvents?.length > 0 ? (
                      upcomingEvents.map((item, ind) => (
                        <tr key={ind}>
                          <td>{item?.policy_No}</td>
                          <td>{item?.productName}</td>
                          <td>{item?.amount}</td>
                          <td>{convertDate(item?.eventDate)}</td>

                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          <span>No Data Available</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div> 
              
  </Form>
</Modal>
        


      <Modal
       
        open={Verifiedpopup}
        destroyOnClose={true}
        width={600}
        closeIcon={false}
        footer={null}
      >

        <div>

          <div className="reuirement">
            <table className="table responsive-table assistanceTable">

              <tbody>
              <tr>
                <td>DOB</td>
                <td> <b>{convertDate(props?.policyDetails?.sentDetailsObj?.dob) || "-"}</b></td>
              </tr>
              <tr>
                <td>Address </td>
                <td><b> {Address}</b></td>
              </tr>
              <tr>
                <td>Premium Amount</td>
                <td> <b>
                                {(data?.premiumDetails?.modelPremiumAmount && (
                                  <NumberFormat
                                    value={
                                      data?.premiumDetails?.modelPremiumAmount
                                    }
                                    decimalSeparator="."
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                  />
                                )) ||
                                  "-"}
                              </b></td>
              </tr>
              <tr>
                <td>Sum Assured</td>
                <td>      <b>
                                {(data?.saDetails?.sumAssured && (
                                  <NumberFormat
                                    value={data?.saDetails?.sumAssured}
                                    decimalSeparator="."
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                  />
                                )) ||
                                  "-"}
                              </b></td>
              </tr>
              <tr>
                <td>Nominee Name and Relationship</td>
                <td> <b> {ClientName ? ClientName : ''} {ClientName ? '&' : ''} {Relation ? relationship[Relation] : ''}</b></td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className="contact-details-btn">


            <Button
              type="primary"
              className="primary-btn"
              onClick={() => setVerifiedPopup(false)}
            >
              OK
            </Button>
          </div>

        </div>

      </Modal>


      <Modal
       
       open={ShowUplodedDocs}
       destroyOnClose={true}
       width={1200}
       closeIcon={false}
       footer={null}
     >



       <div>

         <div className="reuirement">
           <table className="table responsive-table assistanceTable">
          <thead>
            <th>Document</th>
            <th>Uploaded On</th>
            <th>Uploaded By</th>
          </thead>
           <tbody>
           {(transactionDocData?.dmsLink?.length > 0 || PosData?.dmsLink?.length > 0) &&
  (transactionDocData?.dmsLink ? (
    transactionDocData?.dmsLink.map((item, ind) => (
      <tr key={item?.id || ind}>
        <td>
          <a
            className="hyperLink"
            href="#"
            onClick={(e) => handleClick(e, item)}
          >
            {item?.indexName}
          </a>
        </td>
        <td>{item.uploadedOn ? convertDate(item.uploadedOn) : ""}</td>
        <td>{item.uploadedBy}</td>
      </tr>
    ))
  ) : (
    PosData?.dmsLink?.map((item, ind) => (
      <tr key={item?.id || ind}>
        <td>
          <a
            className="hyperLink"
            href="#"
            onClick={(e) => handleClick(e, item)}
          >
            {item?.indexName}
          </a>
        </td>
        <td>{item.uploadedOn ? convertDate(item.uploadedOn) : ""}</td>
        <td>{item.uploadedBy}</td>
      </tr>
    ))
  ))}

{[PosData, transactionDocData].map((data, index) => (
  data?.dmsLink?.length === 0 && (
    <tr key={index}>
      <td>
        <div className="text-center">
          <span>No Data Available</span>
        </div>
      </td>
    </tr>
  )
))}
              </tbody>
           </table>
         </div>

         <div className="contact-details-btn">


           <Button
             type="primary"
             className="primary-btn"
             onClick={() => setShowUplodedDocs(false)}
           >
             OK
           </Button>
         </div>

       </div>

     </Modal>

      <Modal
        title="Payout"
        open={isPayoutModal}
        destroyOnClose={true}
        width={1200}
        closeIcon={
          <Tooltip title="Close">
            <img src={CloseIcon} alt="" onClick={() => setIsPayoutModal(false)}/>
          </Tooltip>
        }
        footer={null}
      >

      <div>
        <div className="reuirement">
          {/* {isPayoutModalLoading ? <>
        <Spin />
        </> : */}
          <table className="table responsive-table assistanceTable">
            <thead>
              <tr>
                {/* <th>Ticket No</th>
                <th>Call Type</th>
                <th>Sub Type</th> */}
                <th>PolicyNo</th>
                <th>Status</th>
                <th>Payment Date</th>
                <th>Payment Amount</th>
                {/* <th>Payment Mode</th> */}
                {/* <th>Cheque/UTR No</th> */}
                <th>Mode/Reference No</th>
              </tr>
            </thead>
            <tbody>
              {payoutModalData?.length > 0 ? (
                payoutModalData.map((ele, index) => (
                  
                  <tr key={index + 1}>
                    {/* <td>{ele.reqnno}</td>
                    <td>{ele.callType}</td>
                    <td>{ele.subType}</td> */}
                    <td>{ele.CHDRNUM}</td>
                    <td>{ele.STATUS}</td>
                    <td>{formatDate(ele.AUTHDATE)}</td>
                    <td>{formatAmount(ele.PAYAMT)}</td>
                    {/* <td>{ele.paymentMode}</td> */}
                    <td onClick={() => accoutDeailHandler(ele)} className="account-details">
                      {ele.ZNEFTNO}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">
                    <div className="text-center"><span>No Data Available</span></div>
                  </td>
                </tr>
              )}

            </tbody>
          </table>
              {/* } */}
        </div>
      </div>


      </Modal>

      <Modal
        title="Account Details"
        open={isAccoundetail}
        destroyOnClose={true}
        width={600}
        height={1600}
        closeIcon={
          <Tooltip title="Close">
            <img src={CloseIcon} alt="" onClick={() => setIsAccoundetail(false)}/>
          </Tooltip>
        }
        footer={null}
      >
          <div className="reuirement">
          <table className="table responsive-table assistanceTable">
            <thead>
              <tr>
                <th>Bank Name</th>
                <td>{accountDetailsData?.BANKNAME}</td>
              </tr>
              <tr>
                <th>Branch Name</th>
                <td>{accountDetailsData?.BRANCHNAME}</td>
              </tr>
              <tr>
                <th>Bank Account No</th>
                <td>{accountDetailsData?.BANKACCOUNTNO}</td>
              </tr>
              <tr>
                <th>Bank IFSC</th>
                <td>{accountDetailsData?.BANKIFSCNO}</td>
              </tr>
              <tr>
                <th>Account Type</th>
                <td>{accountDetailsData?.BANKACCTYPE}</td>
              </tr>
            </thead>
          </table>
          </div>
      </Modal>

      <Modal
        open={isCommunicationContent}
        width={700}
        height={580}
        footer={null}
        className="view-consent"
        closeIcon={
          <Tooltip title="Close">
            <img src={CloseIcon} alt="" onClick={() => setIsCommunicationContent(false)}/>
          </Tooltip>
        }
      >
        <table className="hist-content">
          <thead className="content-wrap">
            <tr>
              <th colSpan="2"className="cust-header">
                Communication Content
              </th>
            </tr>
          </thead>
          <tbody className="content-wrap">
            <tr className="content-wrap">
              <td className="w-62"><strong>SMS</strong></td>
              <td >
                {commContent?.deliveryStatus ? commContent?.deliveryStatus : 'NA'}
              </td>
            </tr>
            <tr>
              <td><strong>Email</strong></td>
              <td>
                {commContent?.eMailSubject ? commContent?.eMailSubject : 'NA'}
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>

      {
        isOpenTicket && <TicketsOpenPopup isOpenTicket={isOpenTicket} setIsOpenTicket={setIsOpenTicket} serviceId={serviceId} handleClose={handleClose} details={details} handleTicketSubmit={handleTicketSubmit} ticketsData={isTicketsData} isLoading={isLoading} getLastOpenTicketsData={getLastOpenTicketsData} isTicketDetails={isTicketDetails}/>
      }


    </>
  );
};


const mapStateToProps = ({ policyDetails, profileReducer }) => {
  return { policyDetails, profileReducer };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updatePolicyDetails: (info) => {
      dispatch(policyDetailsObj(info));
    },
    dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);