import React, { useState, useEffect, useRef,} from "react";
import { Form, Spin, Button,Row,Col,Checkbox,message,Modal,Upload,Tooltip, } from "antd";
import DetailsForm from "../../utils/DetailsForm";
import PopupAlert from "../popupAlert";
import { policyInquiry } from "../../mainconfig";
import moment from 'moment';
import { connect, useSelector } from "react-redux";
import apiCalls from "../../api/apiCalls";






const InquiryPolicy = (props) => {
  // const { selectedCallType, selectedSubType, customerData, details, policyDetails,POSContactData , requestModeLU} = props;
  const {selectedSubType,customerData,POSContactData,setSelectedSubType,revivalForm,selectedCallType,selectedSubTypeId,SelectedSubTypeVal,details,requestModeLU,clientEnquiryData,policyDetails,uwDecisionLU} = props;
  const [form] = Form.useForm();
   const loginInfo = useSelector((state) => state);
   const [alertTitle, setAlertTitle] = useState("");
   const [alertData, setAlertData] = useState("");
   const [navigateTo, setNavigateTo] = useState("");
   const [showAlert, setShowAlert] = useState(false);
   const [isLoader,setIsLoader] = useState(false);


  // console.log("selectedSubType",selectedSubType)

  // const [callType, setCallType] = useState('Policy Inquiry');
  // const [subType, setSubType] = useState('Policy Status');
  // const [comments, setComments] = useState('');

  // const handleCallTypeChange = (event) => {
  //   setCallType(event.target.value);
  // };

  // const handleSubTypeChange = (event) => {
  //   setSubType(event.target.value);
  // };

  // const handleCommentsChange = (event) => {
  //   setComments(event.target.value);
  // };
  const convertDate = (inputDate) => {
    const formattedDate = moment(inputDate, "YYYYMMDD").format("DD/MM/YYYY");
    return formattedDate;
  };
  const getTransactionData = (values) => {
      if (selectedSubType === "policystatus"||"premiumduedate"||"premiumdueamount"||"others") {
        return [
          {
            Status: "Create",
            TagName: "UserComments",
            TagValue: values?.UserComments||"",
          },
          
        ]
      }
  }
  

  const handleSubmit = () => {
    const values = form.getFieldsValue();
     saveRequest(values);
    
  };

  const handleInputChange =(e,item)=>{
  
  }

  const saveRequest = (values) => {

    const obj = {
      CallType: props?.selectedCallType, // Required
      SubType: props?.selectedSubTypeId, // Required
      RequestSource: loginInfo?.userProfileInfo?.profileObj?.sourceId || 0, // Required
      RequestChannel:values.requestchannel, // Required
      Category: 1,
      ApplicationNo:
        details?.policyDetailsObj?.identifiers?.applicationNo ||
        customerData?.applicationNo,
      DOB: convertDate(customerData?.dob),
      PolicyNo:
        details?.policyDetailsObj?.identifiers?.policyNo ||
        customerData?.policyNo, // Required
      CustomerId: 456,
      CustRole:1,
      policyStatus:
        details?.policyDetailsObj?.planAndStatus?.policyStatus ||
        customerData?.policyStatus,
      proposerName:
        details?.policyDetailsObj?.identifiers?.po_Name ||
        customerData?.po_Name,
      plan:
        details?.policyDetailsObj?.planAndStatus?.planName ||
        customerData?.planName,
      CreatedOn: new Date(),
      CreatedByRef: loginInfo?.userProfileInfo?.profileObj?.name,
      CreatedUsrId: loginInfo?.userProfileInfo?.profileObj?.userName,
      ModifiedOn: new Date(),
      ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
      AssignedToRole: "", //POS
      AssignedByUser: 0,
      ReasonForChange: "",
      RequestDateTime: "",
      ReasonDelayed: "",
      CustSignDateTime:"",
      TransactionData: getTransactionData(values) || [],
      Uploads: [],
      // CurrentStatus:raiseRequirementOpen? "Reject":'',
      CommunicationRequest: [
        {
          SrvReqRefNo: "",
          TemplateID: "",
          CommType: 2,
          ReceipientTo:  process.env.REACT_APP_RECEIPIENT_TO ? process.env.REACT_APP_RECEIPIENT_TO : clientEnquiryData?.rinternet,
        ReceipientCC: process.env.REACT_APP_RECEIPIENT_CC ? process.env.REACT_APP_RECEIPIENT_CC : clientEnquiryData?.rinternet,
          MobileNos: "",
          ScheduledTime: new Date(),
          CommBody: "",
          Attachments: null,
        },
        {
          SrvReqRefNo: "",
          TemplateID: "",
          CommType: 1,
          ReceipientTo: "",
          ReceipientCC: "",
          MobileNos: process.env.REACT_APP_RECEIPIENT_MOBILENO ? process.env.REACT_APP_RECEIPIENT_MOBILENO : clientEnquiryData?.rmblphone,
          ScheduledTime: new Date(),
          CommBody: "",
          Attachments: null,
        },
      ],
    };
 
    let response = apiCalls.genericAPI(obj);
    response
      .then((val) => {
        if (val?.data) {
          // if(!val?.data?.srvReqRefNo){
            setAlertTitle(val?.data?.header);
            setAlertData(val?.data?.message);
            setShowAlert(true);      
       
        } else {
          message.error({
            content:
              val?.data?.responseBody?.errormessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
      })
      .catch((err) => {
      });
  };

  const renderDetailsForm = (formType) => {
    return (
      <DetailsForm
        data={policyInquiry[selectedSubType]?.[formType]}
        subType={selectedSubType}
        form={form}
        handleInputChange={handleInputChange}

      ></DetailsForm>
    );
  };

  return (
    <>
     <Form
    form={form}
    name="wrap"
    labelCol={{
      flex: "35%",
    }}
    labelAlign="center"
    labelWrap
    wrapperCol={{
      flex: 1,
    }}
    colon={false}
    onFinish={handleSubmit}
    autoComplete="off">
      
       <>
       {
        selectedSubType==="policystatus" &&  renderDetailsForm("BOE_Details")
       }
       {
        selectedSubType==="premiumduedate" &&  renderDetailsForm("BOE_Details")
       }
        {
        selectedSubType==="premiumdueamount" &&  renderDetailsForm("BOE_Details")
       }
        {/* {
        selectedSubType==="policybonddispatchdetails" &&  renderDetailsForm("BOE_Details")
       }
        {
        selectedSubType==="policystatusanddetails" &&  renderDetailsForm("BOE_Details")
       } */}
        {
        selectedSubType==="others" &&  renderDetailsForm("BOE_Details")
       }
       </>
       <div className="contact-details-btn">
       <Button type="primary"
        htmlType="submit" 
        className="primary-btn"
        >
         Submit
       </Button>{" "}
      </div>
    </Form>
      {showAlert && (
        <PopupAlert
          alertData={alertData}
          getAdvance={props.getAdvance}
          title={alertTitle}
          navigate={navigateTo}
          setShowAlert={setShowAlert}
        ></PopupAlert>
      )}
    </>
    
  );
};

export default InquiryPolicy;

