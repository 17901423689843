import './App.css';
import { useEffect,startTransition } from 'react';
import { Provider } from "react-redux";
import { store } from "./store";
import { DataProvider } from './reducers/DataContext';
import { AuthProvider } from './utils/auth';
import { MsalProvider } from "@azure/msal-react";
import { ClickProvider } from './reducers/ClickContext';
import { useNavigate } from 'react-router-dom';
import Routing from './layout/Routing';
import { profileObj } from './reducers/ProfileReducer';

function App({ instance }) {
  const navigate = useNavigate();
 // const dispatch = useDispatch();
  const SESSION_TIMEOUT = process.env.REACT_APP_SESSION_TIMEOUT;
//  const isLoggedIn = !!loginInfo?.userProfileInfo?.profileObj?.name;
  const isLoggedIn = localStorage.getItem('sessionStartTime');

  useEffect(() => {
    // Check session on mount
    checkSession();

    // Add event listeners for user actions
    window.addEventListener('click', resetSession);
    window.addEventListener('keypress', resetSession);

    // Set up interval to check session periodically
    const interval = setInterval(checkSession, 1000);

    return () => {
      // Cleanup event listeners and interval on unmount
      window.removeEventListener('click', resetSession);
      window.removeEventListener('keypress', resetSession);
      clearInterval(interval);
    };
  }, [isLoggedIn]);

  const checkSession = () => {
    const sessionStartTime = localStorage.getItem('sessionStartTime');
    if (sessionStartTime) {
      const currentTime = new Date().getTime();
      if (currentTime - parseInt(sessionStartTime) > SESSION_TIMEOUT) {
        // Session expired
        handleLogout();
      }
    }
  };

  const resetSession = () => {
    if (isLoggedIn) {
      localStorage.setItem('sessionStartTime', new Date().getTime().toString());
    }
  };

  const handleLogout = () => {
    // localStorage.removeItem('sessionStartTime');
    // navigate('/login');
    startTransition(() => {
      let user = {
        userName: '',
        password: '',
        boe: '',
        pos: '',
        role: '',
        name: ''
      }
      //dispatch(profileObj(user))
      localStorage.removeItem('token');
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem('sessionStartTime');
      navigate("/login");
    })
  };

  return (
    <MsalProvider instance={instance}>
      <AuthProvider>
        <DataProvider>
          <Provider store={store}>
            <div className="App">
              <ClickProvider> 
                <Routing />
              </ClickProvider>
            </div>
          </Provider>
        </DataProvider>
      </AuthProvider>
    </MsalProvider>
  );
}

export default App;
