// import { clientApi} from ".";
import axios from "axios";

// Create and configure the Axios instance
const AxiosInstance = () => {
	// Create an Axios instance
	const instance = axios.create();
  
	// Request interceptor
	instance.interceptors.request.use(
	  config => {
		// Do something before request is sent, e.g., add a token
		const token = localStorage.getItem('token');
		if (token) {
		  config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	  },
	  error => {
		// Do something with request error
		return Promise.reject(error);
	  }
	);

	// Response interceptor
	instance.interceptors.response.use(
	  response => {
		// Any status code within the range of 2xx will trigger this function
		return response;
	  },
	  error => {
		// Any status codes outside the range of 2xx will trigger this function
		if (error.response && error.response.status === 401) {
		  // Handle 401 error (unauthorized), e.g., redirect to login
		// window.location = '/login';
		}
		return Promise.reject(error);
	  }
	);
	return instance;
  };
  
  // Create an Axios instance using the configured AxiosInstance function
  const axiosInstance = AxiosInstance();


// Function to make the API call
const ctst = (val, condition) => {
	const apiUrl = process.env.REACT_APP_API_URL + "GetMasterData";
	const params = {
	  'code': process.env.REACT_APP_API_KEY,
	  'condition': condition ? 1 : 0
	};
	return axiosInstance.post(apiUrl, val, { params });
  };

const genericAPI=(data)=>{
	const apiUrl = process.env.REACT_APP_API_URL + 'CreateServiceRequest';
	const params = {
        'code': process.env.REACT_APP_API_KEY
    };
	 return axiosInstance.post(apiUrl, data, { params })
}

const getDmsDocumentList = (data) => {
    const apiUrl =  process.env.REACT_APP_FG_URL + "Generic/api/DMSDownloadAPI/getDMSDocumentsList";
    const config = {
        headers: {
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_FG_KEY,
        }
    };
    return axiosInstance.post(apiUrl, data, config);
};

const getLastOpenTickets = (policyNo) => {
	const apiUrl = process.env.REACT_APP_API_URL + 'GetBOELatestServiceRequests';
	const params = {
		policyNo:policyNo,
        'code': process.env.REACT_APP_API_KEY
    };
	return axiosInstance.get(apiUrl, { params })
}

const GetSearchPolicyDetails=(data)=>{
	const apiUrl = process.env.REACT_APP_API_URL2 + 'GetSearchPolicyDetails';
	const params = {
		Srvid:data,
       'code': "ebno4g2qwn9un_3no1i9x9HsSdgzku7V_uoSmfUQ927pAzFuWNl1RA=="
    };
	return axiosInstance.get(apiUrl, { params })
}

// const contactDetailsUpdate = (data) => {
// 	const apiUrl = 'https://servicerequesttestapi.azurewebsites.net/api/CreateServiceRequest';
// 	const params = {
//         'code': 'nzxd5NDpQ-vIPF-dXYQhwvNrFhKqc79_-78C8VY7gpduAzFu945Ppg=='
//     };
// 	 return axiosInstance.post(apiUrl, data, { params })
// }


const getTicketDetails = (serialNum) => {
	const apiUrl = process.env.REACT_APP_API_URL + 'GetServiceRequestForViewBySID';
	const params ={
		'code': process.env.REACT_APP_API_KEY,
		'srid':serialNum
	}
	return axiosInstance.get(apiUrl, {params})
}

const getEmailTemplate = (serRefId,tempId) => {
	const apiUrl = process.env.REACT_APP_API_URL2 + 'GetEmailTemplateAPI';
	const params ={
		'code': process.env.REACT_APP_API_KEY2,
		'SrvReqRefNo':serRefId,
		'templateId': tempId
	}
	return axiosInstance.post(apiUrl, null, {params})
}

const getPOSData=(obj)=>{
	const apiUrl = process.env.REACT_APP_API_URL + 'GetPOSListServiceRequests';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		'userId':  obj?.userId,
		'role': obj?.role
    };
	 return axiosInstance.get(apiUrl, { params })
}

const getServiceRequestSearchDetails = (data) =>{
	const apiUrl = process.env.REACT_APP_API_URL2 + 'ServiceRequestSearchDetails';
	const params ={
		'code': process.env.REACT_APP_API_KEY2,
		'fromDate':data.fromDate,
		'toDate':data.toDate,
		'policyNumber':data?.policyNumber,
		'userId':data?.userId,
		'role':data.role
	}
	return axiosInstance.get(apiUrl, { params })
} 
const getVerifyBankDedup = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2 + 'VerifyBankDedup';
	const params ={
		'code': process.env.REACT_APP_API_KEY2,
	}
	
	return axiosInstance.post(apiUrl, obj, {params})
}

const GetDocumentData=(data)=>{
	const apiUrl = process.env.REACT_APP_API_URL + 'GeneratePDFDocument';
	const params = {
		'code': process.env.REACT_APP_API_KEY,
		'srid': data.srid,
    };
	return axiosInstance.get(apiUrl, { params })
}

const getRoleBasedSearchDetails = (data) =>{
	const apiUrl = process.env.REACT_APP_API_URL + 'RoleBasedSearchDetails';
	const params ={
		'code': process.env.REACT_APP_API_KEY,
		'fromDate':data?.fromDate,
		'toDate':data?.toDate,
		'policyNumber':data?.policyNumber,
		'callType': data?.callType,
		'subType': data?.subType,
		'mode': data?.mode,
		'status':data?.status,
		'role':data?.role,
		'userId':data?.userId,
	    'ageing': data?.ageing,
		'assignedTo': data?.assignedTo
	}
	return axiosInstance.get(apiUrl, { params })
}
const getBOEUserDashboard = (data,includeCloseSR) =>{
	const apiUrl = process.env.REACT_APP_API_URL + 'GetDashboard';
	const params ={
		'code': process.env.REACT_APP_API_KEY,
		'fromDate':data?.fromDate,
		'toDate':data?.toDate,
		'policyNumber':data?.policyNumber,
		'callType': data?.callType,
		'subType': data?.subType,
		'mode': data?.mode,
		'status':data?.status,
		'role':data?.role,
		'userId':data?.userId,
	    'ageing': data?.ageing,
		'assignedTo': data?.assignedTo,
		'IncludeClosed': includeCloseSR ? 'Yes' : 'no',
		'calimType': data?.calimType,
		'ticketIDNo': data?.ticketIDNo
	}
	return axiosInstance.get(apiUrl, { params })
}
const getDashboardFollowUps = (selectedFOllowUpVal) =>{
	const apiUrl = process.env.REACT_APP_API_URL + 'GetDashboardFollowUps';
	const params ={
		'code': process.env.REACT_APP_API_KEY,
		'followup': selectedFOllowUpVal,
	}
	return axiosInstance.get(apiUrl, { params })
}
const SaveFollowUps = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL + 'SaveFollowUps';
	const params ={
		'code': process.env.REACT_APP_API_KEY,
	}
	
	return axiosInstance.post(apiUrl, obj, {params})
}
const saveRaiseEnquiry = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL + 'CreateSRInquiry';
	const params ={
		'code': process.env.REACT_APP_API_KEY,
	}
	
	return axiosInstance.post(apiUrl, obj, {params})
}

const SendComplaintCommunication = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2 + 'ComplaintCommunication';
	const params ={
		'code': process.env.REACT_APP_API_KEY2,
	}
	
	return axiosInstance.post(apiUrl, obj, {params})
}


const SaveRandomCallInfo = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL + 'SaveRandomCallInfo';
	const params ={
		'code': process.env.REACT_APP_API_KEY,
	}
	
	return axiosInstance.post(apiUrl, obj, {params})
}
const getPOSIndividualData=(serialNum)=>{

	const apiUrl = process.env.REACT_APP_API_URL + 'GetServiceRequestBySID';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		'srId': serialNum,
    };
	 return axiosInstance.get(apiUrl, { params })
}

const getRaiseRequirements=(data)=>{
	const apiUrl = process.env.REACT_APP_API_URL + 'GetRaiseRequirements';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		'calltype':data.calltype,
		'subtype':data.subtype,
		'Role':data.Role
    };
	 return axiosInstance.get(apiUrl, { params })
}

const POSActionsOnContactDetails=(data)=>{
	const apiUrl = process.env.REACT_APP_API_URL + 'POSActionsOnServReq'
	const params = {
        'code': process.env.REACT_APP_API_KEY
    };
	 return axiosInstance.post(apiUrl, data, { params })
}

const getBankDeatils=(data)=>{
	const apiUrl = process.env.REACT_APP_API_URL + 'GetBankDeatils'
	const params = {
        code: process.env.REACT_APP_API_KEY,
		policyNo: data.policyNo,
		clientId : data.clientId,
		// SerReq :  data.SerReq
    };
	 return axiosInstance.get(apiUrl, { params })
}

const getIFSCBanks = (ifscCode) => {
	const apiUrl = process.env.REACT_APP_API_URL+'IFSCBank'
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		ISFC: ifscCode?.toUpperCase(),
    };
	return axiosInstance.get(apiUrl, { params })
}
const getDocMaster=(obj)=>{
	const apiUrl = process.env.REACT_APP_API_URL + 'GetDocMaster'
	const params = {
        'code': process.env.REACT_APP_API_KEY
    };
	 return axiosInstance.post(apiUrl, obj, { params })
}

const getProcesDocLnk=(obj)=>{
	const apiUrl = process.env.REACT_APP_API_URL + 'GetProdDocLnk'
	const params = {
        'code': process.env.REACT_APP_API_KEY
    };
	 return axiosInstance.post(apiUrl, obj, { params })
}
const getProcesLink=(obj)=>{
	const apiUrl = process.env.REACT_APP_API_URL + 'GetProcesDocLnk'
	const params = {
        'code': process.env.REACT_APP_API_KEY
    };
	 return axiosInstance.post(apiUrl, obj, { params })
}

const FinanceActionsOnSerReq=(obj)=>{
	const apiUrl = process.env.REACT_APP_API_URL + 'FinanceActionsOnSerReq'
	const params = {
        'code': process.env.REACT_APP_API_KEY
    };
	 return axiosInstance.post(apiUrl, obj, { params })
}

const FinanceDashboardAPI=(obj)=>{

	const apiUrl = process.env.REACT_APP_API_URL + 'FinanceDashboard'
	const params = {
        'code': process.env.REACT_APP_API_KEY
    };
	 return axiosInstance.post(apiUrl, obj, { params })
}

const SaveEmailResponseDtls=(obj)=>{

	const apiUrl = process.env.REACT_APP_API_URL + 'SaveEmailResponseDtls'
	const params = {
        'code': process.env.REACT_APP_API_KEY
    };
	 return axiosInstance.post(apiUrl, obj, { params })
}

const UpdateEmailStatus=(obj)=>{
	const apiUrl = process.env.REACT_APP_API_URL2 + 'UpdateEmailStatus'
	const params = {
        'code': process.env.REACT_APP_API_KEY2
    };
	 return axiosInstance.post(apiUrl, obj, { params })
}

const EmailManagementDashBoardFilters=(obj)=>{
	const apiUrl = process.env.REACT_APP_API_URL2 + 'EmailManagementDashBoardFilters'
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
        'RoleId':obj?.RoleId,
		'UserName':obj?.UserName
    };
	 return axiosInstance.get(apiUrl,{ params })
}

const EmailMgAttendence=(obj)=>{
	const apiUrl = process.env.REACT_APP_API_URL2 + 'EmailMgAttendence'
	const params = {
        'code': process.env.REACT_APP_API_KEY2
    };
	 return axiosInstance.post(apiUrl,obj, { params })
}

const TATCasesForEmailMng=()=>{
	const apiUrl = process.env.REACT_APP_API_URL2 + 'TATCasesForEmailMng'
	const params = {
        'code': process.env.REACT_APP_API_KEY2
    };
	 return axiosInstance.get(apiUrl, { params })
}

const EmailAssignToUser=(obj)=>{
	const apiUrl = process.env.REACT_APP_API_URL2 + 'EmailAssignToUser'
	const params = {
        'code': process.env.REACT_APP_API_KEY2
    };
	 return axiosInstance.post(apiUrl,obj, { params })
}

const LoadCTST=(obj)=>{

	const apiUrl = process.env.REACT_APP_API_URL2 + 'LoadCTST'
	const params = {
        'code': process.env.REACT_APP_API_KEY2
    };
	 return axiosInstance.post(apiUrl, obj, { params })
}

const getIGMSComplaintsAPI = (serReqRefNo) => {
	const apiUrl = process.env.REACT_APP_IGMS_API_URL + 'GetIGMSComplaintDetails';
	const params = {
        'code': process.env.REACT_APP_IGMS_KEY,
		serReqRefNo: serReqRefNo,
		//mobileNo:mobile
    };
	return axiosInstance.get(apiUrl, { params })
}


const getDoNotDisturbAPI = (policyNumber, mobile) => {
	const apiUrl = process.env.REACT_APP_API_URL +'GetDoNotDisturbAPI';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		policy: policyNumber,
		mobileNo:mobile
    };
	return axiosInstance.get(apiUrl, { params })
}

const taxCalculationForSerReq = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'TaxCalculationForSerReq';
	const params = {
        code: process.env.REACT_APP_API_KEY2,
		//clientId: obj.clientId,
		srid:obj.SrvReqRefNo
    };
	return axiosInstance.get(apiUrl, { params })
}

const searchLocation = (pincode) => {
	const apiUrl = process.env.REACT_APP_API_URL +'SearchLocation';
	const params ={
		code: process.env.REACT_APP_API_KEY,
	}
    const obj = {
		"searchLocationBy":4,
		"postalCode":{
			"Pincode":pincode
		}
	}
	return axiosInstance.post(apiUrl, obj ,{ params })
}

const searchIGMSLocation = (pincode) => {
	const apiUrl = process.env.REACT_APP_API_URL +'SearchIGMSLocation';
	const params ={
		code: process.env.REACT_APP_API_KEY,
	}
    const obj = {
		"searchLocationBy":4,
		"postalCode":{
			"Pincode":pincode
		}
	}
	return axiosInstance.post(apiUrl, obj ,{ params })
}

// ********************************** 2222222222222222**********************************//
const GetSerReqStatus = (roleId,userId) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'GetSerReqStatus';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		 Roleid: roleId,
		 Userid: userId,
    };
	return axiosInstance.get(apiUrl, { params })
}
const GetSerReqByFilters = (callType,subType,caseStatus,policyNo,sRequestID,complaintsType,status, roleId, IGMSStatus, assignedTo) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'GetSerReqByFilters';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		Calltype: callType || 0,
		Subtype:subType||0,
		Status:caseStatus||"",
		policyNumber:policyNo || '',
		SRequestID:sRequestID || '',
		complaintsType:complaintsType || '',
		assignedTo:assignedTo || "",
		Roleid: roleId,
		IGMSStatus: IGMSStatus
    };
	return axiosInstance.get(apiUrl, { params })
}

const GetComplaintSerReqByFilters = (callType,subType,userId,role,status,isChecked,SRequestID,ageing,fromDate,toDate,PolicyNo,TokenNo, IGMSStatus) => {
	
	const apiUrl = process.env.REACT_APP_API_URL2 + 'GetComplaintsServiceRequests';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		callType: callType||0,
		subType:subType||0,
		role:role||0,
		userId:userId || 0,
		status:status,
        includeClosedComplaints:isChecked,
		SRequestID:SRequestID || '',
		ageing:ageing || '',
		fromDate:fromDate || '',
		toDate:toDate || '',
		PolicyNo:PolicyNo || '',
		TokenNo:TokenNo || '',
		IGMSStatus: IGMSStatus

    };
	return axiosInstance.get(apiUrl, { params })
}

const GetComplaintDashboardCount=(callType, userId, role) => {
	
	const apiUrl = process.env.REACT_APP_API_URL2 +'GetComplaintDashboard';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		'callType': callType,
		'userId' : userId,
		'role': role

    };
	return axiosInstance.get(apiUrl, { params })
}
const GetAgeingCount=(callType, userId, roleId) => {
	
	const apiUrl = process.env.REACT_APP_API_URL2 +'ComplaintsTicketAgeing';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		'callType': callType,
		'userId' : userId,
		'role' : roleId,

    };
	return axiosInstance.get(apiUrl, { params })
}


const GetUsersByRoleID=(roleID)=>{
	const apiUrl = process.env.REACT_APP_API_URL2 +'GetUsersByRoleID';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		roleID:roleID

    };
	return axiosInstance.get(apiUrl, { params })
}
const GetPOSExecRoles = () => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'GetPOSExecRoles';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		Roleid: 23,
    };
	return axiosInstance.get(apiUrl, { params })
}
const saveAssignToPOS = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'AssignToPOS';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
    };
	return axiosInstance.post(apiUrl, obj, { params })
}



const saveAssignToComplaintTeam=(obj)=>{
	const apiUrl = process.env.REACT_APP_API_URL2 +'AssignComplaints';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
    };
	return axiosInstance.post(apiUrl, obj, { params })
}

const AssignToGrevienceUser=(obj)=>{
	const apiUrl = process.env.REACT_APP_API_URL2 +'AssignToGrevienceUser';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
    };
	return axiosInstance.post(apiUrl, obj, { params })
}
const fileUpload = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'InsertBlob';
	const params = {
        'code': process.env.REACT_APP_API_KEY2
    };
	return axiosInstance.post(apiUrl, obj, { params })
}
const getRelationsData = (clientId) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'GetNomineeRelation';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		'clientId': clientId
    };
	return axiosInstance.get(apiUrl, { params })
}
const getEmailManagementFilter = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2+'GetEmailResponse'
	const params = {
        //'code': "Qi9AuTJ2jjwmR0yiBuVRNjquki_BGfs44ZrdFoF6m5KrAzFulLJLrg==",
		'code': process.env.REACT_APP_API_KEY2,
    };
	return axiosInstance.post(apiUrl, obj, { params })
}



const SendEmailSMTP = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2+'SendEmailSMTP'
	const params = {
        //'code': "fGboTum1tBYW4aFrgLlpmCVL-Ki298PWfC03vr9XgbqPAzFu1PWwxg==",
		'code': process.env.REACT_APP_API_KEY2,
    };
	return axiosInstance.post(apiUrl, obj, { params })
}


const GetEmailResponseDtls = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'GetEmailResponseDtls';
	const params = {
        "code":process.env.REACT_APP_API_KEY2,
    };
	return axiosInstance.post(apiUrl, obj, { params })
}


const getSendOTP = (data) => {
const apiUrl = process.env.REACT_APP_API_URL2+'OTPService'
const params = {
		'code':process.env.REACT_APP_API_KEY2,
	  };
	 return axiosInstance.post(apiUrl, data, { params })
}
const getServiceRequestCount = (PolicyNo,callType,subType) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'GetServiceRequestCount';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		'PolicyNo': PolicyNo,
		'callType': callType,
		'subType': subType
    };
	return axiosInstance.get(apiUrl, { params })
}
const getPaymentReprocessing = (PolicyNo,callType,subType) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'PaymentReprocessing';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		'PolicyNo': PolicyNo,
		'callType': callType,
		'subType': subType
    };
	return axiosInstance.get(apiUrl, { params })
}

// const getSendOTP = (data) => {
// 	const apiUrl = 'https://commonservice.azurewebsites.net/api/OTPService?code=y3BxlW2EwU_02Pve3tEdXudjcU9vZQ5DGfLz9w72OvZZAzFuq264Gg==';
// 	const headers = {
// 		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY,
// 	  };
// 	 return axiosInstance.post(apiUrl, data, { headers:headers,mode: 'cors' })
// }
const savePaymentLink = (obj) => {
	const apiUrl = 'https://servicerequestfg.azurewebsites.net/api/CreateServiceRequest?code=8mwgijI-vaJqSNBvMklnE8wiYiHueCdXah1vqDckALp9AzFucT2MBg==';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };

	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })
}

const getOFACDetailsApi = (obj) => {
	const apiUrl = 'https://lfagentapigw-rsg.azure-api.net/POSMicroservice/PolicyServicing/api/Ofac/GetOFACDetails';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY_OFAC 
	  };

	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })
}


const PPCSave = (data) => {
	const apiUrl = 'https://premiumpaidcretificateservice.azurewebsites.net/api/PremiumPaidCertificate';
	const params = {
        'code': 'y9VBbTtGOVEvLPJzO82DDtCCxvOJSO1fBDuoEL9u50-TAzFuXu78Mw=='
    };
	 return axiosInstance.post(apiUrl, data, { params })
}


const getPlanFund = (data) => {
	const apiUrl = process.env.REACT_APP_API_URL +'GetPlanFundMst';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
    };
	return axiosInstance.post(apiUrl, data, { params })
}
const getAssistanceDetails = (calltype,subtype,roleId ) => {
	const apiUrl = process.env.REACT_APP_API_URL +'GetAssistanceDetails';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		calltype:calltype,
		subtype:subtype,
		RoleID: roleId
    };
	return axiosInstance.get(apiUrl, { params })
}

const getUserProfile = (policyNo, ClientID, loggedinUser) => {
	const apiUrl = process.env.REACT_APP_API_URL +'GetProfile';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		policyNo: policyNo,
		ClientID:ClientID,
		LOGGEDINUSER: loggedinUser
    };
	return axiosInstance.post(apiUrl, null, { params })
}

const getUserProfilePreferences = (CreatedBy, ClientID, TagName, TagValue) => {
	const apiUrl = process.env.REACT_APP_API_URL +'AddProfilePreferences';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		CreatedBy: CreatedBy,
		ClientID:ClientID,
		TagValue :TagValue,
		TagName:TagName
    };
	return axiosInstance.post(apiUrl, null, { params })
}

const TransectionPayouts = (obj ) => {
	const apiUrl = process.env.REACT_APP_API_URL +'TransectionPayouts';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		  ...obj
    };
	return axiosInstance.get(apiUrl, { params })
}

const GetListOfServiceRequestForMaturity = (obj ) => {
	const apiUrl = process.env.REACT_APP_API_URL +'GetListOfServiceRequestForMaturity';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
    };
	return axiosInstance.get(apiUrl, { params })
}

const GetServiceRequestForMaturity = (obj ) => {
	const apiUrl = process.env.REACT_APP_API_URL +'GetServiceRequestForMaturity';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		'srId':obj
    };
	return axiosInstance.get(apiUrl, { params })
}

// const getPolicyOpenServiceRequests = (obj ) => {
// 	const apiUrl = "https://fglicservicerequestapi.azurewebsites.net/api/GetPolicyOpenServiceRequests";
// 	const params = {
//         'code': process.env.REACT_APP_API_KEY,
// 		'PolicyNo':obj
//     };
// 	return axiosInstance.get(apiUrl, { params })
// }

const getPolicyOpenServiceRequests = (obj ) => {
	const apiUrl = process.env.REACT_APP_API_URL +'GetPolicyOpenServiceRequests';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		'PolicyNo':obj
    };
	return axiosInstance.get(apiUrl, { params })
}

const getIGMSMastersData = (obj ) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'GetIGMSMasters';
	//const apiUrl = 'http://localhost:7071/api/GetIGMSMasters';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		'Category':obj
    };
	return axiosInstance.get(apiUrl, { params })
}

const saveRegisterComplaintAPI = (obj ) => {
	const apiUrl = process.env.REACT_APP_IGMS_API_URL + 'RegisterComplaint';
	const objRegister = {'Complaint': obj}
	const params = {
	  'code':  process.env.REACT_APP_IGMS_SAVE_KEY,
    };

	return axiosInstance.post(apiUrl, objRegister,{ params })
}


const updateRegisterComplaintAPI = (obj ) => {
	const apiUrl = process.env.REACT_APP_IGMS_API_URL + 'UpdateComplaint';
	const objUpdate= {'Complaint': obj}
	const params = {
        'code': process.env.REACT_APP_IGMS_UPDATE_KEY,
    };
	return axiosInstance.post(apiUrl,objUpdate, { params })
}


const UploadExcelFileAPI = ( formdata, obj) => {
	const apiUrl = process.env.REACT_APP_API_URL +'UploadExcelFileAPI';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		  ...obj
    };
	return axiosInstance.post(apiUrl,formdata, { params })
}



// ********************************** FG SERVER CALLS **********************************//
const getClientEnquiry = (obj,empID) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'Generic/api/Generic/GetClientEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
			"requestHeader": {
			  "source": "POS",
			  "carrierCode": "2",
			  "branch": "Baroda Branch Office",
			  "userId": empID,
			  "userRole": "10",
			  "partnerId": "MSPOS",
			  "processId": "POS",
			  "monthEndExtension": "N",
			  "monthendDate": "12/10/2023"
			},
			"requestBody": {
			  "clientNumber": obj.clientNumber 
			}
		  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getCheckPANdetails = (panNo) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/PAN/getCheckPANdetails';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = { 
			"requestHeader": {  
				"source": "POS",
				"policyNo": "00013374",
				"applicationNo": "",
				"dob": process.env.REACT_APP_DOB
			  },
			  "requestBody": {
			   "panNumber": panNo?.toUpperCase()
			  }
		  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getAgentEnquiry = (agnetNo, empID) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/Agent/GetAgentEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = { 
			"requestHeader": { 
				"source": "POS",
				"carrierCode": "2",
				"branch": "PRA",
				"userId": empID,
				"userRole": "10",
				"partnerId": "MSPOS",
				"processId": "POS",
				"monthendExtension": "N",
				"monthendDate": "18/10/2023"
			},
			"requestBody": {
				"agntsel": agnetNo
			}
		  }
	
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}
const getNomineeCreation = (policyNo) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'/PolicyServicing/api/Nominee/GetNomineeCreation';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = { 
			"requestHeader": {
			  "source": "POS",
				  "carrierCode": "2",
				  "Branch": "PRA",
				  "userId": "website",
				  "userRole": "10",
				  "partnerId": "MSPOS",
				  "processId": "POS",
				  "monthendExtension": "N",
				  "monthendDate": process.env.REACT_APP_MONTHEND_DATE
			},
			"requestBody": {
				"bnycD1": "",
				"bnypC1": "100",
				"bnyrlN1": "WI",
				"bnyseL1": "50008879",
				"effectiveDate1": "20230831",
				"bnycD2": "",
				"bnypC2": "",
				"bnyrlN2": "",
				"bnyseL2": "",
				"effectiveDate2": "",
				"bnycD3": "",
				"bnypC3": "",
				"bnyrlN3": "",
				"bnyseL3": "",
				"effectiveDate3": "",
				"bnycD4": "",
				"bnypC4": "",
				"bnyrlN4": "",
				"bnyseL4": "",
				"effectiveDate4": "",
				"bnycD5": "",
				"bnypC5": "",
				"bnyrlN5": "",
				"bnyseL5": "",
				"effectiveDate5": "",
				"bnycD6": "",
				"bnypC6": "",
				"bnyrlN6": "",
				"bnyseL6": "",
				"effectiveDate6": "",
				"bnycD7": "",
				"bnypC7": "",
				"bnyrlN7": "",
				"bnyseL7": "",
				"effectiveDate7": "",
				"bnycD8": "",
				"bnypC8": "",
				"bnyrlN8": "",
				"bnyseL8": "",
				"effectiveDate8": "",
				"bnycD9": "",
				"bnypC9": "",
				"bnyrlN9": "",
				"bnyseL9": "",
				"effectiveDate9": "",
				"bnycD10": "",
				"bnypC10": "",
				"bnyrlN10": "",
				"bnyseL10": "",
				"effectiveDate10": "",
				"policyNumber": policyNo
			  }
		  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getNomineeEnquiry = (policyNo, userId) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/Nominee/GetNomineeEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = { 
		"requestHeader": {
			"source": "POS",
				"carrierCode": "2",
				"Branch": "PRA",
				"userId": userId,
				"userRole": "10",
				"partnerId": "MSPOS",
				"processId": "POS",
				"monthendExtension": "N",
				"monthendDate": process.env.REACT_APP_MONTHEND_DATE
		  },
		  "requestBody": {
			"policyNumber": policyNo
		  }
		  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getExistPANNumber = (clientNo, empID) => {
	const apiUrl =  process.env.REACT_APP_FG_URL2 +'LifeAsiaApi/AML/api/AML/Enquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY2
	  };
	  const data = {
		"requestHeader": {
		   "source": "POS",
			  "carrierCode": "2",
			  "Branch": "KAN",
			  "userId": empID,
			  "userRole": "10",
			  "partnerId": "MSPOS",
			  "processId": "POS",
			  "monthendExtension": "N",
			  "monthendDate": process.env.REACT_APP_MONTHEND_DATE
		},
		"requestBody": {
		  "CltID": clientNo
		}
	  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getAnnuityEnquiry = (policyNo) => {
	const apiUrl =  process.env.REACT_APP_FG_URL2 +'Claims/api/Claims/GetAnnuityEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
		"source": "POS",
		"policyNo": policyNo
		}
		}
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getSearchData = (data) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'Generic/api/SearchAPI/GetSearchAPI';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY,
		'Access-Control-Allow-Origin': '*'
	  };

	 return axiosInstance.post(apiUrl, data, { headers:headers,mode: 'cors' })
}


const getPolicyEnquiry = (obj,empID) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/PolicyEnquiry/GetPolicyEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
			"requestHeader": {
			  "source": "POS",
			  "carrierCode": "2",
			  "branch": "Baroda Branch Office",
			  "userId": empID,
			  "userRole": "10",
			  "partnerId": "MSPOS",
			  "processId": "POS",
			  "monthEndExtension": "N",
			  "monthendDate": "12/10/2023"
			},
			"requestBody": {
			  "PolicyNumber": obj 
			}
		  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}



const getTokenData =  (PolicyNo) => {
	const apiUrl = process.env.REACT_APP_TOKEN_API
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FREELOOK_KEY,
		"x-token-key":process.env.REACT_APP_TOKEN_ID
	  };
	  const data ={
		token: PolicyNo
		}
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const GetPolicyDetailsBySrID =  (srCode) => {
	const apiUrl = process.env.REACT_APP_API_URL +'GetPolicyDetailsBySrID';
	const params = {
        'code': process.env.REACT_APP_API_KEY,
		'srid': srCode,
    };
	return axiosInstance.get(apiUrl, { params })
}

const getHeaderParameters = (obj) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'Generic/api/HeaderAPI/GetHeaderParameters';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
			"source": "POS",
			"policyNo": obj?.policyNo,
			"applicationNo": obj?.applicationNo,
			"dob":  obj?.dob
		}
	}
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}


const getComplaintAction = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'ComplaintAction';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		'clientId':'blobs_extension'
    };
	return axiosInstance.post(apiUrl, obj, { params })
}

const getSendAction = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'SendAction';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		'clientId':'blobs_extension'
    };
	return axiosInstance.post(apiUrl, obj, { params })
}

const getPremiumEnquiryData = (obj) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/PremiumEnquiry/GetPremiumEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })
} 

const surrenderEnquiryData = (obj) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'Surrender/api/Surrender/GetSurrenderEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })
} 

const loanQuotationn = (obj) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/Loan/LoanQuotation';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })
} 

const getPartialWithdrawalEnquiry = (obj) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'Surrender/api/PartialWithdrawalEnquiry/GetPartialWithdrawalEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })
} 

const getMandatetagEnquiry = (policyNumber, empID) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/MandateTag/GetMandatetagEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
			 "source": "POS",
			 "carrierCode": "2",
			 "branch": "PRA",
			 "userId": empID,
			 "userRole": "10",
			 "partnerId": "MSPOS",
			 "processId": "POS",
			 "monthendExtension": "N",
			 "monthendDate": "18/10/2023"
		 },
	   "requestBody": {
		 "chdrsel": policyNumber
	   }
	 }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getMandateData = (poClientID,mandreff,applicationNo) => {
	const apiUrl = process.env.REACT_APP_FG_URL2 +'LifeAsiaApi/Payment/api/Mandate/Enquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY2
	  };
	  const data = {
		"requestHeader": {
			"source": "AAAA",
			"carrierCode": "2",
			"branch": "Baroda Branch Office",
			"userId": "F1142259",
			"userRole": "10",
			"partnerId": "1142259",
			"processId": "",
			"monthendExtension": "",
			"monthendDate": ""
		},
		"requestBody": {
			"clntnum": poClientID,
			"mandreff": mandreff,
			"applicationNo": applicationNo
		}
	}
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getMandateReject = (poClientID,mandreff,applicationNo) => {
	const apiUrl = process.env.REACT_APP_FG_URL2 +'LifeAsiaApi/Payment/api/Mandate/Reject';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY2
	  };
	  const data = {
		"requestHeader": {
		  "source": "AAAA",
		  "carrierCode": "2",
		  "branch": "Baroda Branch Office",
		  "userId": "F1142259",
		  "userRole": "10",
		  "partnerId": "1142259",
		  "processId": "",
		  "monthendExtension": "",
		  "monthendDate": ""
		},
		"requestBody": {
		  "clntnum": poClientID, 
		  "mandreff": mandreff,
		  "dteaprov": "",
		  "bankacckey": "12345",
		  "bankkey": "497002006",
		  "detlsumm": "N",
		  "effdate": "20230908",
		  "facthous": "04",
		  "mandamt": "10450.0000",
		  "mandstat": "10",
		  "timesuse": "999",
		  "zddday": "",
		  "bkcardnum": "",
		  "dtefrej": process.env.REACT_APP_DOB2, // DATE OF 1ST DDA REJECT Madhuri (PA team) will provide the input for logic of this field
		  "dtefsbm": process.env.REACT_APP_DOB2, //date of 1st DDA Submission
		  "dtesrej": process.env.REACT_APP_DOB2,//Date Of 2nd DDA Reject  
		  "dtessbm": process.env.REACT_APP_DOB2,  //Date Of 2nd  DDA Submission
		  "freason": "", 
		  "sreason": "",
		  "applicationNo": applicationNo
		}
	  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getPayoutDetailsEnquiry =  (policyNo) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'FetchPaymentDetails';
	const params = {
		'code': process.env.REACT_APP_API_KEY2,
		'policyNo': policyNo
	};
	return axiosInstance.get(apiUrl, { params })
}

const bankaccverification = (obj) => {
	const apiUrl =   process.env.REACT_APP_FG_URL +'Generic/api/BankDetails/GetBankaccountverification';
	const headers = {
		"Ocp-Apim-Subscription-Key": process.env.REACT_APP_FG_KEY
	  };
	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })
} 

const LoanEnquiry = (obj) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/LoanENQ/LoanEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })
} 

const LoanStatement = (obj) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/Loan/LoanStatement ';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })
} 

const GetAssigneeEnquiry = (obj) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/Assignee/GetAssigneeEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })
} 

const GetFundValue = (obj) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/FundSwitch/GetFundValue';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })
} 

const getEmailDedupeAPI=(obj)=>{
	const apiUrl = process.env.REACT_APP_FG_URL +'Generic/api/SearchAPI/GetSearchAPI'
	const headers = {
        	"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY,
		'Access-Control-Allow-Origin': '*'
    };
	 return axiosInstance.post(apiUrl, obj, { headers: headers, mode: 'cors' })

}

const getAllowableModeChangeOptionFetch = (obj, empID) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/AllowableModeChangeOptionFetch/GetAllowableModeChangeOptionFetch';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
			"requestHeader": {
			"source": "POS",
			"carrierCode": "2",
			"Branch": "PRA",
			"userId": empID,
			"userRole": "10",
			"partnerId": "MSPOS",
			"processId": "POS",
			"monthendExtension": "N",
			"monthendDate": process.env.REACT_APP_MONTHEND_DATE
			},
			"requestBody": {
			"cnttype": obj?.planCodeNo
			}
		}
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getBillingFrequencyChangeQuotation = (obj, empID) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/BillingFrequency/GetBillingFrequencyChangeQuotation';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
		"source": "POS",
		"carrierCode": "2",
		"Branch": "PRA",
		"userId": empID,
		"userRole": "10",
		"partnerId": "MSPOS",
		"processId": "POS",
		"monthendExtension": "N",
		"monthendDate": process.env.REACT_APP_MONTHEND_DATE
		},
		"requestBody": {
		"policyNumber": obj?.policyNumber,  
		"frequency": obj?.mode
		}
	}
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const GetPartSurrenderEnquiry =  (obj) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'/Surrender/api/PartSurrender/GetPartSurrenderEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	 return axiosInstance.post(apiUrl, obj, { headers: headers,mode: 'cors' })
}

const GetPaymentDetails =  (applicationNo) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'Generic/api/Payment/GetPaymentDetails';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
		  "source": "",
		  "policyNo": "",
		  "applicationNo": applicationNo,
		  "dob": ""
		},
		"requestBody": {
		}
	  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

// const CKYC = (obj) => {
// 	const apiUrl = process.env.REACT_APP_FG_URL2 + 'CKYC/ckyc/test/v1/search ';
// 	const headers = {
// 		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
// 	  };
// 	  const data = {
// 		"idType": "PAN",
// 		"idValue": "DQEFT1234H",
// 		"name": null,
// 		"dob": null,
// 		"gender": null,
// 		"consent": "Y",
// 		"getMultipleRecord": "N",
// 		"clientData": {
// 			"caseId": null
// 		}
// 	}
// 	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
// }

const GetCurrentFundAllocation =  (policyNo) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/CurrentFundAllocation/GetCurrentFundAllocation';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
		  "source": "POS",
		  "policyNo": policyNo,
		  "applicationNo": "",
		  "dob": ""
		}
	  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const GetCertificateOfExistenceEnquiry =  (PolicyNo, empID) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/COE/GetCertificateOfExistenceEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
			  "source": "POS",
			  "carrierCode": "2",
			  "branch": "PRA",
			  "userId": empID,
			  "userRole": "10",
			  "partnerId": "MSPOS",
			  "processId": "POS",
			  "monthendExtension": "N",
			  "monthendDate": "29-02-2024"
		},
		"requestBody": {
		  "PolicyNo": PolicyNo
		}
	  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const GetSBfrequencyEnquiry =  (PolicyNo, empID) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/COE/GetSBfrequencyEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
			  "source": "POS",
			  "carrierCode": "2",
			  "branch": "PRA",
			  "userId": empID,
			  "userRole": "10",
			  "partnerId": "MSPOS",
			  "processId": "POS",
			  "monthendExtension": "N",
			  "monthendDate": "29-02-2024"
		},
		"requestBody": {
		  "action": "C",
		  "policyNo": PolicyNo,
		  "ttmprcno": ""
		}
	  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const GetListOfRolesByUserId =  (userId, userName) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'GetRolesOfUser';
	const params = {
        'code': process.env.REACT_APP_API_KEY2,
		'userId': userId,
		'userName': userName
    };
	return axiosInstance.get(apiUrl, { params })
}

const GetTransacions =  (PolicyNo) => {
	const apiUrl = process.env.REACT_APP_GET_TRANSACTION_API_URL + "ewa/transacions";
	const headers = {
		"EZYBREQHDR_KEY": "Y6SsmdsDEx",
       "EZYBREQHDR_CODE": "Y98SsmdsDymSLcih"
	  };
	  const data ={
		"CHDRNUM": PolicyNo,
		"REQTYPE":"A"
		}
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getProdConfigDropdown = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL +'GetProdConfig';
	const params ={
		'code': process.env.REACT_APP_API_KEY,
	}
	
	return axiosInstance.post(apiUrl, obj, {params})
}
const GetTDSInfo = (policyNo, clientID) => {
	const apiUrl = process.env.REACT_APP_API_URL +'GetTDSInfo';
	const params ={
		'code': process.env.REACT_APP_API_KEY,
	};
	const data ={
		  "PolicyNo": policyNo,
         "ClientID": clientID
		}
	
	return axiosInstance.post(apiUrl, data, {params})
}

const getGCPPolicyDetails =  (PolicyNo) => {
 const apiUrl =  process.env.REACT_APP_FG_URL2 + "policydetails";
	const headers = {
		"Ocp-Apim-Subscription-Key": process.env.REACT_APP_FG_KEY
	  };
	  const data ={
		PolicyNo: PolicyNo
		}
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const getFreeLookDetailsApi = (PolicyNo) => {
  const apiUrl = process.env.REACT_APP_FREELOOKAPI
  
  // Retrieve the token dynamically, e.g., from localStorage or state
  const token = localStorage.getItem('tokenGenrate'); // Example: Replace this as needed

  const headers = {
	"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FREELOOK_KEY,
    "Authorization": `Bearer ${token}`, // Add Bearer token here
  };

  const data = {
    PolicyNo: PolicyNo,
  };

  return axios.post(apiUrl, data, { headers: headers, mode: 'cors' });
};




const GETUNPROCEESEDUNITS = (policyNo) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/UNPROCEESEDUNITS/GETUNPROCEESEDUNITS';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
		  "source": "POS",
		  "policyNo": policyNo,
		  "applicationNo": "",
		  "dob": ""
		}
	  }
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const GetMobileDedupeAPI = (mobileNo) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'Generic/api/Dedupe/GetMobileDedupeAPI';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"mobileNo": mobileNo
	}
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}
const GetEmailDedupeAPI = (emailAddress) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'Generic/api/Dedupe/GetEmailDedupeAPI';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"emailAddress": emailAddress
	}
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
}

const GetReceiptEnquiryForPolicy = (policyNo, empID) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/Receipt/GetReceiptEnquiryForPolicy';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
			"source": "POS",
			"carrierCode": "2",
			"branch": "PRA",
			"userId": empID,
			"userRole": "10",
			"partnerId": "MSPOS",
			"processId": "POS",
			"monthendExtension": "N",
			"monthendDate": "09/12/2023"
		},
		"requestBody": {
			"policyNo": policyNo
		}
	};
	
	 return axiosInstance.post(apiUrl, data, { headers: headers,mode: 'cors' })
};

const SendFalconideMail = (obj) => {

	const apiUrl = process.env.REACT_APP_API_URL4 +'SendFalconideMail';

    const params = {
		'code': process.env.REACT_APP_API_KEY4,
	}
	
	  return axiosInstance.post(apiUrl,obj, {mode: 'cors', params : params})
	 
	}
const GetHistoricalCTST = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL +'GetHistoricalCTST';
	const params = {
		'code': process.env.REACT_APP_API_KEY3,
	}
	  const data = {
		"FromDate": obj.FromDate,
		"ToDate": obj.ToDate,
		"PolicyNo": obj?.PolicyNo
	};
	
	 return axiosInstance.post(apiUrl, data, {params : params})
}

const GetCreateJournal = (data1) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'PolicyServicing/api/Journal/JVCreationBO';
	const headers = {
		"Ocp-Apim-Subscription-Key": process.env.REACT_APP_FG_KEY,
	}
	  const data = {
		"requestHeader": {
		"source": "POS",
		"carrierCode": "2",
		"branch": "KAN",
		"userId": "F1132057",
		"userRole": "10",
		"partnerId": "POS",
		"processId": "",
		"monthendExtension": "N",
		"monthendDate": "N"
		},
		"requestBody": {
		"policyNo": data1?.payload?.identifiers?.policyNo,
		"chdrtype": data1?.payload?.planAndStatus?.planCode,
		"register": "KAN",
		"rfcode": "CN",
		"rfnum": data1?.payload?.salesDetails?.agentCode,
		"srcebus": "ST",
		"dissectionDetails": [
		{
		"bankCode": "10",
		"amount": "22468",
		"entity": data1?.payload?.identifiers?.policyNo,
		"origccy": "INR",
		"sacscode": "LP",
		"sacstypw": "S",
		"scrate": "1",
		"trandesc": ""
		},
		{
		"bankCode": "10",
		"amount": "209000",
		"entity": data1?.payload?.identifiers?.policyNo,
		"origccy": "INR",
		"sacscode": "LP",
		"sacstypw": "S",
		"scrate": "1",
		"trandesc": ""
		}
		],
		"zrsndesc": ""
		}};
	
	 return axiosInstance.post(apiUrl, data, {headers : headers})
}
const GetClientLevelInfo = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL +'ClientLevelInfo';
	const params ={
		'code': process.env.REACT_APP_API_KEY,
	};
	const data ={
		  "PolicyNo": obj?.PolicyNo,
         "ClientID": null,
		  Action: obj.Action,
		  EffectiveDate: obj.EffectiveDate,
		  CustomerRef:null,
		  _HeaderPayload: {
			  loggedinUser: obj._HeaderPayload.loggedinUser
		  }
		}
	
	return axiosInstance.post(apiUrl, data, {params})
}

const GetPolicyClientEnquiry = (obj) => {
	const apiUrl = process.env.REACT_APP_FG_URL +'/PolicyServicing/api/PolicyClientEnquiry/GetPolicyClientEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key":process.env.REACT_APP_FG_KEY
	  };
	const data = {
		"requestHeader": {
		"source": "POS",
		"carrierCode": "2",
		"branch": "PRA",
		"userId": "F1132057",
		"userRole": "10",
		"partnerId": "MSPOS",
		"processId": "POS",
		"monthendExtension": "N",
		"monthendDate": "18/10/2023"
		},
		"requestbody": {
		"policyNo": obj?.policyNo,
		"effectiveDate": obj?.effectiveDate,
		"action": "A"
		}
	}

	return axiosInstance.post(apiUrl, data, {headers})
}
const GetClaimsPrimaryAssessmentEnquiry = (empID,policyNo) => {
	const apiUrl = process.env.REACT_APP_FG_URL2 +'Claims/api/ClaimsPrimaryAssessment/GetClaimsPrimaryAssessmentEnquiry';
	const headers = {
		"Ocp-Apim-Subscription-Key": process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
		      "source": "POS",
			  "carrierCode": "2",
			  "Branch": "PRA",
			  "userId": empID,
			  "userRole": "10",
			  "partnerId": "MSPOS",
			  "processId": "POS",
			  "monthendExtension": "N",
			  "monthendDate": "09/12/2023"
		},
		"requestBody": {
		  "policyNo": policyNo
		}
	  }
	  
	 return axiosInstance.post(apiUrl, data, {headers : headers})
}
const GetUWFollowups = (policyNo) => {
	const apiUrl = process.env.REACT_APP_FG_URL2 +'Claims/api/Claims/GetUWFollowups';
	const headers = {
		"Ocp-Apim-Subscription-Key": process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
		  "source": "POS",
		  "policyNo": policyNo,
		  "applicationNo": "",
		  "dob": ""
		}
	  }
	 return axiosInstance.post(apiUrl, data, {headers : headers})
}
const GetAuditTrailDetails = (policyNo) => {
	const apiUrl = process.env.REACT_APP_FG_URL2 +'POSMicroservice/Generic/api/Generic/GetAuditTrailDetails';
	const headers = {
		"Ocp-Apim-Subscription-Key": process.env.REACT_APP_FG_KEY
	  };
	  const data = {
		"requestHeader": {
			"source": "POS",
			"policyNo": policyNo,
			"applicationNo": "",
			"dob": ""
		  }
		
	  }
	 return axiosInstance.post(apiUrl, data, {headers : headers})
}

const GetFetchCautionList = (obj1) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'FetchCautionList';

	  const params ={
		'code': process.env.REACT_APP_API_KEY2,
	};
	  const data = {
		"PolicyRef": 0,
		"LA_PolicyNo": obj1?.policyNo,
		"FG_ApplNo": obj1?.applicationNo,
		"CautionLstStatus": 1
	  }
	  
	 return axiosInstance.post(apiUrl, data, {params})
}

const SaveCautionComments = (obj3) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'SaveCautionList';

	const params ={
	  'code': process.env.REACT_APP_API_KEY2,
  };
	
   return axiosInstance.post(apiUrl, obj3, {params})
}

const CloseCautionComments = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2 +'CloseCautionList';

	const params ={
	  'code': process.env.REACT_APP_API_KEY2,
  };
	
   return axiosInstance.post(apiUrl, obj, {params})
}

const GetIGMSComplaintDescription = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL2 + 'GetIGMSComplaintDescription';
	const params = {
		'code': process.env.REACT_APP_API_KEY2,
		"ComplaintType":obj?.selectedOption,
    };
	return axiosInstance.get(apiUrl, { params })
}

const GetHistCommunications = (obj) => {
	const apiUrl = process.env.REACT_APP_API_URL + 'GetHistCommu';
	const params = {
		'code': process.env.REACT_APP_API_KEY
    };
	const data = {
		"LA_PolicyNo": obj?.PolicyNo
	  }
	  return axiosInstance.post(apiUrl, data, { params })
}
const GetClaimsCommentsInfo = (srvReqID,referralViewComments,userName) => {
	const apiUrl = process.env.REACT_APP_API_URL + 'GetClaimsCommentsInfo';
	const params = {
		'code': process.env.REACT_APP_API_KEY5
    };
	const data = {
		"SrvReqID": srvReqID,
		"Comments": referralViewComments,
		"CreatedBy": userName,
		"CreatedDate": new Date()
	  }
	  return axiosInstance.post(apiUrl, data, { params })
}
const GetClaimsViewCommentsInfo = (srvReqID) => {
	const apiUrl = process.env.REACT_APP_API_URL + 'GetClaimsCommentsInfo';
	const params = {
		'code': process.env.REACT_APP_API_KEY5,
		"SrvReqID": srvReqID
    };
	  return axiosInstance.get(apiUrl, { params })
}


const objects = {UpdateEmailStatus,LoadCTST, EmailManagementDashBoardFilters, EmailAssignToUser,EmailMgAttendence, TATCasesForEmailMng,
		UploadExcelFileAPI, getAssistanceDetails, getUserProfile,TransectionPayouts,  GetCurrentFundAllocation, GetPartSurrenderEnquiry, 
		searchLocation, getPlanFund, taxCalculationForSerReq, getEmailDedupeAPI, SaveEmailResponseDtls,SendEmailSMTP, GetEmailResponseDtls, 
		FinanceActionsOnSerReq,FinanceDashboardAPI, GetFundValue, LoanStatement,GetAssigneeEnquiry, LoanEnquiry, GetSerReqStatus, bankaccverification, 
		getPartialWithdrawalEnquiry, loanQuotationn, surrenderEnquiryData, getBankDeatils, genericAPI, getClientEnquiry, 
		POSActionsOnContactDetails, getRaiseRequirements, getPOSIndividualData, getPOSData, getSearchData,getHeaderParameters,
		getPremiumEnquiryData,savePaymentLink, ctst,getLastOpenTickets,getSendOTP,PPCSave,GetSerReqByFilters,
		GetPOSExecRoles,saveAssignToPOS,getIFSCBanks,fileUpload,getCheckPANdetails,getAgentEnquiry,getNomineeCreation,
		getNomineeEnquiry,getExistPANNumber,getRelationsData,getProcesDocLnk,getProcesLink,getDocMaster,getEmailManagementFilter,
		getAllowableModeChangeOptionFetch,getBillingFrequencyChangeQuotation,getDoNotDisturbAPI,getMandatetagEnquiry,getMandateReject,
		getServiceRequestCount,GetPaymentDetails,getPaymentReprocessing,getComplaintAction,getSendAction,getMandateData,
		GetListOfRolesByUserId,getDmsDocumentList,getTicketDetails,getEmailTemplate,getServiceRequestSearchDetails,GetCertificateOfExistenceEnquiry, GetSBfrequencyEnquiry,
		GetTransacions,getGCPPolicyDetails,GetListOfServiceRequestForMaturity,GetServiceRequestForMaturity,getPolicyOpenServiceRequests,getAnnuityEnquiry,GetPolicyDetailsBySrID,
		GETUNPROCEESEDUNITS,GetSearchPolicyDetails,GetComplaintSerReqByFilters,GetUsersByRoleID,saveAssignToComplaintTeam,AssignToGrevienceUser,getRoleBasedSearchDetails,getPayoutDetailsEnquiry,GetDocumentData,GetMobileDedupeAPI,
		GetEmailDedupeAPI, getVerifyBankDedup, getBOEUserDashboard,getDashboardFollowUps, SaveFollowUps, SaveRandomCallInfo, saveRaiseEnquiry, getProdConfigDropdown, getUserProfilePreferences, getIGMSMastersData, getIGMSComplaintsAPI, 
		saveRegisterComplaintAPI, updateRegisterComplaintAPI, GetReceiptEnquiryForPolicy, SendComplaintCommunication, GetTDSInfo,getOFACDetailsApi, GetHistoricalCTST, GetCreateJournal,getFreeLookDetailsApi,getTokenData,GetClientLevelInfo,getPolicyEnquiry,

		GetClaimsPrimaryAssessmentEnquiry,GetComplaintDashboardCount, GetFetchCautionList, SaveCautionComments, GetPolicyClientEnquiry, CloseCautionComments, GetIGMSComplaintDescription, SendFalconideMail, GetUWFollowups, GetHistCommunications,GetAuditTrailDetails,GetClaimsCommentsInfo,
		GetClaimsViewCommentsInfo,GetAgeingCount, searchIGMSLocation
	};


export default objects;