import React, { useEffect, useState,useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import { SearchOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { Bar } from "react-chartjs-2";
import { Card, Checkbox,Switch,Flex, Progress, Col, DatePicker , Form, Row, Space, Statistic, Table,message,Spin,Select ,  Button,Input} from "antd";
import apiCalls from '../api/apiCalls';
import { useNavigate, useParams,useLocation } from "react-router-dom";
import CloseIcon from "../assets/images/close-icon.png";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EmailManagement = () => {


   const { userId } = useParams();
   const {state}=useLocation();
   const loggedUser = useSelector(state => state?.userProfileInfo?.profileObj);
  const [form] = Form.useForm();
  const [showTotalPages, setShowTotalpages] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filtersData, setFiltersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  // const [emailSource, setEmailSource] = useState([]);
  
  const [checkedCards, setCheckedCards] = useState({});
  const [masterData,setMasterData] = useState([]);
  const [callTypeLU,setCallTypeLU] = useState([]);
  let PONE = [0,0,0];
  let PTWO= [0,0,0];
  let PTHREE= [0,0,0];
  const selectedCategory = {
    'Q':'','R':'', 'C':''
  };

  const PFOUR =[0,0,0];

  const [selectedCallType,setSelectedCallType] = useState("");
  const [selectedSubTypeId,setSelectedSubTypeId] = useState(null);
  const [selectedSubType,setSelectedSubType] = useState(null);
  const [subTypeLU, setSubTypeLU] = useState(null);
  const isInitialMasterMount = useRef(true);
  const isInitialMount = useRef(true);
  const navigate = useNavigate();
  const [data, setData] = useState(); 
  const [NLPResponseChecked, setNLPResponseChecked] = useState(false);
  const [Addressedto, setAddressedto] = useState(false);
  const dateFormat = "DD/MM/YYYY";

  const [OpenCasesWithinTAT, setOpenCasesWithinTAT]= useState(0);
  const [OpenCasesOutsideTAT, setOpenCasesOutsideTAT]= useState(0);
  const [ClosedCasesWithinTAT, setClosedCasesWithinTAT]= useState(0);
  const [ClosedCasesOutsideTAT, setClosedCasesOutsideTAT]= useState(0);
  const [UnAttendedMailsWithinTAT, setUnAttendedMailsWithinTAT] = useState(0);
  const [UnAttendedMailsOutsideTAT, setUnAttendedMailsOutsideTAT] = useState(0);


  const [UserOpenCasesWithinTAT, setUserOpenCasesWithinTAT]= useState();
  const [UserOpenCasesOnTAT, setUserOpenCasesOnTAT]= useState();
  const [UserOpenCasesOutsideTAT, setUserOpenCasesOutsideTAT]= useState();
  const [UserPendingCasesWithinTAT, setUserPendingCasesWithinTAT]= useState();
  const [UserPendingCasesOnTAT, setUserPendingCasesOnTAT]= useState();
  const [UserPendingCasesOutsideTAT, setUserPendingCasesOutsideTAT]= useState();


  const [UserClosedCasesWithinTAT, setUserClosedCasesWithinTAT]= useState();
  const [UserClosedCasesOnTAT, setUserClosedCasesOnTAT]= useState();
  const [UserClosedCasesOutsideTAT, setUserClosedCasesOutsideTAT]= useState();
  const [isNoData,setIsNoData]=useState('');


  
  


  const [OnTAT, setOnTAT]= useState();

  const [EmailManagementDashBoardData, setEmailManagementDashBoardData] =useState([]);

const emailSource = [
  { value: '1', label: 'Internal' },
  { value: '2', label: 'External' },
  { value: '3', label: 'Other Domain' },
    { value: '-1000', label: 'None' },

]
  
  // const data = {
  //   labels: ["24hrs", "24 hrs to 36 hrs", ">36 hrs"],
  //   datasets: [
  //     {
  //       label: "p1",
  //       data: PONE,
  //       backgroundColor: "#b3201f",
  //       borderColor: "black",
  //       borderWidth: 1,
  //     },
  //     {
  //       label: "p2",
  //       data: PTWO,
  //       backgroundColor: "yellow",
  //       borderColor: "black",
  //       borderWidth: 1,
  //     },
  //     {
  //       label: "p3",
  //       data: PTHREE,
  //       backgroundColor: "#6495ED",
  //       borderColor: "black",
  //       borderWidth: 1,
  //     },
  //   ],
  // };


  const columns = [
    {
      title: "Email Reference",
      dataIndex: "urn",
      sorter: (a, b) => a.from.length - b.from.length,
    sortDirections: ["descend", "ascend"],
      render: (_, record) => (
                <Space size="middle">
        <a className="text-color" onClick={() => handleAction(record)}>{record.urn}</a>
                </Space>
              ),
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      sorter: (a, b) => a.from.length - b.from.length,
    sortDirections: ["descend", "ascend"],
    },
    {
      title: "Policy No",
      dataIndex: "policyNo",
      key: "policyNo",
   
    },
    {
      title: "Received Date",
      dataIndex: "receivedDateTime",
      showSorterTooltip: false,
      key: "receivedDateTime",
      render: (receivedDateTime) => (receivedDateTime ? convertDate(receivedDateTime) : ''),
      sorter: (a, b) => a.from.length - b.from.length,
    sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email Ageing",
      dataIndex: "emailAgeing",
      
      showSorterTooltip: false,
      sorter: (a, b) => a.from.length - b.from.length,
    sortDirections: ["descend", "ascend"],
    },



    // {
    //   title: "Assigned To",
    //   dataIndex: "assignedTo",
    //   key: "assignedTo",
    //   sorter: (a, b) => a.from.length - b.from.length,
    // sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   sorter: (a, b) => a.from.length - b.from.length,
    // sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "Repeat Count",
    //   dataIndex: "repeatCount",
    //   sorter: (a, b) => a.from.length - b.from.length,
    // sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "Addresses To Multiple Email IDs",
    //   dataIndex: "addressedToMultipleIDs",
    //   key: "addressedToMultipleIDs",
    //   render: (addressedToMultipleIDs) => (addressedToMultipleIDs ? 'True' : 'False'),
    //   sorter: (a, b) => a.from.length - b.from.length,
    // sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "Merge Email Y/N",
    //   dataIndex: "mergedMail",
    //   render: (mergedMail) => (mergedMail ? 'Y' : 'N'),
    //   sorter: (a, b) => a.from.length - b.from.length,
    // sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "Registered Flag",
    //   dataIndex: "RegdMailID",
    //   render: (RegdMailID) => (RegdMailID ? 'Registered' : 'UnRegistered'),
    //   sorter: (a, b) => a.from.length - b.from.length,
    // sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "Junk / Spam Flag",
    //   dataIndex: "IsSpamEMS",
    //   render: (isSpamEMS) => (isSpamEMS ? 'Spam' : 'Ham'),
    //   sorter: (a, b) => a.from.length - b.from.length,
    // sortDirections: ["descend", "ascend"],
    // },

    // {
    //   title: "Life / Non-Life Flag",
    //   dataIndex: "isLifeNLP",
    //   render: (isLifeNLP) => (isLifeNLP ? 'Life' : 'Non-Life'),
    //   key:'isLifeNLP',
    //   sorter: (a, b) => a.from.length - b.from.length,
    // sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "NLP Spam",
    //   dataIndex: "isSpamNLP",
    //   render: (isSpamNLP) => (isSpamNLP ? 'True' : 'False'),
    //   key:'isSpamNLP',
    //   sorter: (a, b) => a.from.length - b.from.length,
    // sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "NLP LOB",
    //   dataIndex: "",
    //   sorter: (a, b) => a.from.length - b.from.length,
    // sortDirections: ["descend", "ascend"],
    // },
    
  ];

  
    // const clearAllFilters = () => {
    //   form.resetFields();
    // };

  const options = {
  };
  // const handleChange = (value,index) => {
  //   
  //   if (checkedList.includes(value)) {
  //     setCheckedList([]);
  //   } else {
  //     setCheckedList([value]);
  //   }
  // };
  useEffect(()=>{

    getCTST();
    handleSubmit()
    // if (!isInitialMasterMount.current) {
    // getCTST();
    // }
    // else {
    //   // Set isInitialMount to false after the initial render
    //   isInitialMasterMount.current = false;
    // }
  },[])

  useEffect(() => {
    EmailManagementDashBoardFilters()
    // if (!isInitialMount.current) {
    //   getEmailManagementFilter(checkedCards);
    // } else {
   
    //   isInitialMount.current = false;
    // }
  }, [checkedCards]); 



  
  const EmailManagementDashBoardFilters = () => {
    setIsLoading(true);
    let obj = {
      'RoleId':'20',
      'UserName': loggedUser?.userName === "emailuser" ?  "EmailUser1" : loggedUser?.userName
        }
    let response = apiCalls.EmailManagementDashBoardFilters(obj);
    response
    .then((val) => {
      if (val?.data?.emailMags[0]) {   
       let closedCases = state?.isUser?state?.item?.tatCases?.closedCases: val?.data.emailMags[0]?.tatCases?.closedCases;
       let openCases = state?.isUser?state?.item?.tatCases?.openCases: val?.data.emailMags[0].tatCases.openCases;
       let pendingCases=state?.isUser?state?.item?.tatCases?.pendingCases:val?.data.emailMags[0].tatCases.pendingCases
        if(openCases){
          openCases?.forEach((ele)=>{
            if(ele.category === "WithinTAT"){
              setUserOpenCasesWithinTAT(ele)
            }else if(ele.category=== "OnTAT"){
              setUserOpenCasesOnTAT(ele)
            }else if(ele.category=== "OutsideTAT"){
              setUserOpenCasesOutsideTAT(ele)
            } 
          })
        }

        if(closedCases){
          closedCases?.forEach((ele)=>{
            if(ele.category === "WithinTAT"){
              setUserClosedCasesWithinTAT(ele)
            }else if(ele.category=== "OnTAT"){
              setUserClosedCasesOnTAT(ele)
            }else if(ele.category=== "OutsideTAT"){
              setUserClosedCasesOutsideTAT(ele)
            } 
          })
        }
        if(pendingCases){
          pendingCases?.forEach((ele)=>{
            if(ele.category === "WithinTAT"){
              setUserPendingCasesWithinTAT(ele)
            }else if(ele.category=== "OnTAT"){
              setUserPendingCasesOnTAT(ele)
            }else if(ele.category=== "OutsideTAT"){
              setUserPendingCasesOutsideTAT(ele)
            } 
          })
        }

       
        setIsLoading(false);
      } else {
        setIsNoData("nodata");
        setIsLoading(false);
        message.error({
          content:
            val?.data?.responseBody?.errormessage ||
            "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
      }
    })
    .catch((err) => {
      // setIsLoader(false);
    });
  }



  const handleAction=(item)=>{
    navigate(`/emailmanagementview/${item?.emailResponseId}`, { state: item });
  }

  const restAll = () => {
    form.resetFields();
    handleSubmit()
  }

  const convertDate = (inputDate) => {
    const formattedDate = moment(inputDate).format("MM-DD-YYYY");
    return formattedDate;
  };


  const handleSubmit = (values) => {
    let cat = Object.values(selectedCategory).filter((value) => {return value !== '' })
    let obj={
        "ReceivedFromDt":values?.FormDate ? convertDate(new Date(values?.FormDate)) : '',
        "ReceivedToDt":values?.ToDate ? convertDate(new Date(values?.ToDate)) : '',
        "PolicyNo":values?.policyNo ? values?.policyNo : "",
        "SenderMailID":values?.policyNo ? values?.policyNo : "",
        "AssignedTo": userId ? userId : (loggedUser?.userName === "emailuser" ?  "EmailUser1" : loggedUser?.userName),
        // "emailSource":values?.emailSource ? values?.emailSource?.split('').map(Number):'',
        // "IsNLPRespGen":values?.IsNLPRespGen ? values?.IsNLPRespGen  :'',
        // "AddressedToMultipleIDs": values?.AddressedToMultipleIDs ? values?.AddressedToMultipleIDs  :false,
        // "CallType":values?.CallType ? values.CallType:'',
        // "SubType":values?.SubType ? values.SubType:'',
        // "Category":cat
        // "Category":values?.Category ?   values?.Category?.split('').map(Number):'',
    

    }



    let response = apiCalls.getEmailManagementFilter(obj);
    response
      .then((val) => {
        if (val?.data) {
          if(Array.isArray(val?.data?.emailSummary)){
            val?.data?.emailSummary.forEach((ele)=>{
              if(ele.emailTat=== "OpenCasesWithinTAT"){
                setOpenCasesWithinTAT(ele.tatCount)
              }else if(ele.emailTat=== "OpenCasesOutsideTAT"){
                setOpenCasesOutsideTAT(ele.tatCount)
              }else if(ele.emailTat=== "ClosedCasesWithinTAT"){
                setClosedCasesWithinTAT(ele.tatCount)
              }else if(ele.emailTat=== "ClosedCasesOutsideTAT"){
                setClosedCasesOutsideTAT(ele.tatCount)
              }else if(ele.emailTat=== "OnTAT"){
                setOnTAT(ele.tatPercentage.toFixed(1))
              }
              else if(ele.emailTat=== "UnAttendedMailsWithinTAT"){
                setUnAttendedMailsWithinTAT(ele.tatCount)
              }else if(ele.emailTat=== "UnAttendedMailsOutsideTAT"){
                setUnAttendedMailsOutsideTAT(ele.tatCount)
              }

            })
          }
          if(Array.isArray(val?.data?.graphDataSets)){
            val?.data?.graphDataSets.forEach((ele)=>{
                       if(ele.label === "p1"){
                        PONE = ele.data
                       }else if(ele.label === "p2"){
                        PTWO = ele.data
                       }else if(ele.label === "p3"){
                        PTHREE = ele.data
                       }
            })
          }
           if(Array.isArray(val?.data?.emailClassify) && val?.data?.emailClassify.length>0 ){
          
            setFiltersData(val?.data?.emailClassify);
           }else{
          
            setFiltersData([]);
           }

           setData ({
            labels: ["24hrs", "24 hrs to 36 hrs", ">36 hrs"],
            datasets: [
              {
                label: "p1",
                data: PONE,
                backgroundColor: "#b3201f",
                borderColor: "black",
                borderWidth: 1,
              },
              {
                label: "p2",
                data: PTWO,
                backgroundColor: "yellow",
                borderColor: "black",
                borderWidth: 1,
              },
              {
                label: "p3",
                data: PTHREE,
                backgroundColor: "#6495ED",
                borderColor: "black",
                borderWidth: 1,
              },
              {
                label: "p4",
                data: PFOUR,
                backgroundColor: "#00831a",
                borderColor: "green",
                borderWidth: 1,
              },
            ],
          });
        
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error({
            content:
              val?.data?.responseBody?.errormessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });

  }

    // Define a reusable function for data transformation
const transformData = (data, key) => {
  const filteredData = data?.filter((ele) => ele.key === key);
  return filteredData[0]?.value?.map((item) => ({
    ...item,
    label: item.mstDesc,
    value: item.mstID,
  }));
};


const filterOption = (input, option) =>
(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const onSearch = (e) =>{
}

  const getCTST=async() => {
    
    setIsLoading(true);
    let obj =
    {
      "MasterRequest": [
          "CALL_TYP","SUB_TYP"
      ]
  }
    let response =await apiCalls.ctst(obj);
    if(Array.isArray(response?.data)){
      setMasterData(response.data);
      // Use the function for each set of data
      const transformedData = transformData(response.data, "CALL_TYP");
      setCallTypeLU(transformedData);
      setIsLoading(false);
    }
   else {
      setIsLoading(false);
      message.destroy()
      message.error({
        content: "Something went to wrong!",
        className: "custom-msg",
        duration: 2,
      });
    }
  }

  const subTypeDropdown =async (value,subType,allData)=>{
    let SUB_TYP = masterData?.length>0 ? masterData?.filter((ele) => ele.key === "SUB_TYP") : allData?.filter((ele) => ele.key === "SUB_TYP");
    let data = SUB_TYP[0]?.value?.filter((ele) => ele?.mstParentID === value);
    let transformedData = data?.map((item) => ({
      ...item,
      label: item.mstDesc,
      value: item.mstID
    }));
    setSubTypeLU(transformedData);
  }

  const handleCallTypeChange = (value) => {
      setSelectedCallType(value);
      form.setFieldsValue({subType: null})
      setSubTypeLU(null);
      setSelectedSubType(null);
      subTypeDropdown(value);
    };
    const handleSubTypeChange = (value,getSubLU) => {
      setSelectedSubTypeId(value);
      let subTypeData = subTypeLU?.length>0 ? subTypeLU : getSubLU;
      subTypeData?.map((key, index) => {
        if(key.mstID===value){
          const modifiedDesc = key.mstDesc?.replace(/[^\w]/g, "").toLowerCase();
          setSelectedSubType(modifiedDesc);
        }
      });
    };

  const handleChange = (item, index, categoryTitle) => {
    
    const updatedCheckedCards = { ...checkedCards };

    if (!updatedCheckedCards[categoryTitle]) {
      updatedCheckedCards[categoryTitle] = [];
    }

    if (updatedCheckedCards[categoryTitle].includes(item)) {
      // Uncheck the checkbox if it is already checked
      updatedCheckedCards[categoryTitle] = [];
    } else {
      // Check the clicked checkbox and uncheck others in the same category
      updatedCheckedCards[categoryTitle] = [item];

    }

    setCheckedCards(updatedCheckedCards);
  };

  const clearAllFilters = (categoryTitle) => {
    setCheckedCards((prevCheckedCards) => ({
      ...prevCheckedCards,
      [categoryTitle]: [],
    }));

  };


  const onChangeCategory = (e, type) => {
    if (type === 'Q') selectedCategory.Q = e.target.checked ? 1 : '';
    else if (type === 'R') selectedCategory.R = e.target.checked ? 2 : '';
    else if (type === 'C') selectedCategory.C = e.target.checked ? 3 : '';
  };
  
  // const getEmailManagementFilter =(selectedCheckBox)=>{
    
  //   setIsLoading(true);
  //      let obj={

  //       "emailSource":'',
  //       "ReceivedFromDt":'',
  //       "ReceivedToDt": '',
  //       "IsNLPRespGen":'',
  //       "AddressedToMultipleIDs": false,
  //       "CallType":'',
  //       "SubType":'',
  //       "Category":'',


        
  //      }
  //   let response = apiCalls.getEmailManagementFilter(obj);
  //   response
  //     .then((val) => {
  //       if (val?.data) {
   
  //         if(Array.isArray(val?.data?.emailSummary)){
  //           val?.data?.emailSummary.forEach((ele)=>{
  //             if(ele.summaryDesc=== "NEW_CNT"){
  //               setNEW_CNT(ele.countOfMails)
  //             }else if(ele.summaryDesc=== "PENDING_CNT"){
  //               setPENDING_CNT(ele.countOfMails)
  //             }else if(ele.summaryDesc=== "RESOLVED_CNT"){
  //               setRESOLVED_CNT(ele.countOfMails)
  //             }else if(ele.summaryDesc=== "FOLLOWUP_CNT"){
  //               setFOLLOWUP_CNT(ele.countOfMails)
  //             }

  //           })
  //         }
  //         if(Array.isArray(val?.data?.graphDataSets)){
  //           val?.data?.graphDataSets.forEach((ele)=>{
  //                      if(ele.label === "p1"){
  //                       PONE = ele.data
  //                      }else if(ele.label === "p2"){
  //                       PTWO = ele.data
  //                      }else if(ele.label === "p3"){
  //                       PTHREE = ele.data
  //                      }
  //           })
  //         }
   

  //      setData ({
  //       labels: ["24hrs", "24 hrs to 36 hrs", ">36 hrs"],
  //       datasets: [
  //         {
  //           label: "p1",
  //           data: PONE,
  //           backgroundColor: "#b3201f",
  //           borderColor: "black",
  //           borderWidth: 1,
  //         },
  //         {
  //           label: "p2",
  //           data: PTWO,
  //           backgroundColor: "yellow",
  //           borderColor: "black",
  //           borderWidth: 1,
  //         },
  //         {
  //           label: "p3",
  //           data: PTHREE,
  //           backgroundColor: "#6495ED",
  //           borderColor: "black",
  //           borderWidth: 1,
  //         },
  //         {
  //           label: "p4",
  //           data: PFOUR,
  //           backgroundColor: "#00831a",
  //           borderColor: "green",
  //           borderWidth: 1,
  //         },
  //       ],
  //     });


  //         setFiltersData(val?.data?.emailClassify);
  //         setIsLoading(false);
  //       } else {
  //         setIsLoading(false);
  //         message.error({
  //           content:
  //             val?.data?.responseBody?.errormessage ||
  //             "Something went wrong please try again!",
  //           className: "custom-msg",
  //           duration: 2,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //     });
  // }
 
const sumAll = (values) => {
  return values.reduce((acc, current) => acc + current, 0)
}

  const clearValues = (fieldName) => {

    form.resetFields([fieldName]);
    if(fieldName === 'ReceivedFromDt'){
      form.resetFields(['ReceivedToDt']);
    }
    // form.setFieldsValue({
    //   [fieldName]: {
    //     value: undefined, // Use undefined to clear the selection
    //   },
    // });
  }
  return (
    <>
      <div className="email-container">


    
         

  

      <Row gutter={[16, 16]}>
      
   
    

  
      <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
        <Spin spinning={isLoading} >
          {/* <h5 style={{ textAlign: "center", marginTop: "10px" }}>Email Ageing</h5> */}
          <div className="m-20">
          <Row gutter={[24, 24]}>


          {/* <Col xs={12} sm={12} md={12} lg={12} xxl={12}>

<table className="table table-bodered">
    <tbody>
      <tr >
       <td>Open Cases Within TAT</td>
       <td> <b>{OpenCasesWithinTAT} </b></td>
      </tr>
      <tr>
        <td> Closed Cases Within TAT</td>
        <td><b>{ClosedCasesWithinTAT} </b> </td>
      </tr>
      <tr>
        <td> Open Cases Outside TAT </td>
        <td><b> {OpenCasesOutsideTAT} </b></td>
      </tr>
      <tr>
        <td>Closed Cases Outside TAT </td>
        <td> <b> {ClosedCasesOutsideTAT}</b></td>
      </tr>
      <tr>
        <td>On TAT</td>
        <td><b>{OnTAT}</b></td>
      </tr>
     
     </tbody>
</table>

   </Col> */}

   
          <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
          <Card  className="mb-16">
  <Form
    name="wrap"
    labelCol={{
      flex: "35%",
    }}
    labelAlign="left"
    labelWrap
    wrapperCol={{
      flex: 1,
    }}
    colon={false}
    form={form}
    onFinish={handleSubmit}
    autoComplete="off"
  >
      <Row gutter={[16, 16]}>
                <Col xs={12} sm={12} md={12} lg={8} xxl={8}>
                <div>
                {
                    isNoData!=="nodata" ?  
                    <table className="table table-bodered">
                 
                    <thead>
                      <tr >
                      <th>UnAttended Mails  </th>
                      <th colSpan={1}>  {UnAttendedMailsWithinTAT + UnAttendedMailsOutsideTAT }  </th>
                      {/* <th>%</th> */}
                      </tr>
                     
               
                    </thead>
                    <tbody>
                      <tr>
                      <th>Pending SR Creation</th>
                      <th></th>
                      {/* <th></th> */}
                      {/* <th colSpan={1}>  {UserClosedCasesWithinTAT?.count + UserClosedCasesOnTAT?.count + UserClosedCasesOutsideTAT?.count }  </th>
                      <th>%</th> */}
                      </tr>
                      {/* <tr>
                        <td> Category  </td>
                        <td>Count</td>
                        <td>%</td>
                      </tr> */}
  
  
                      <tr>
                        <td>Within TAT</td>
                        <td>{UnAttendedMailsWithinTAT}</td>
                        {/* <td>{UserOpenCasesWithinTAT?.percentage}</td> */}
                      </tr>
  
                      {/* <tr>
                        <td>On TAT</td>
                        <td>{UserOpenCasesOnTAT?.count}</td>
                        <td>{UserOpenCasesOnTAT?.percentage}</td>
                      </tr> */}
  
                      <tr>
                        <td>Outside TAT</td>
                        <td>{UnAttendedMailsOutsideTAT}</td>
                        {/* <td>{UserOpenCasesOutsideTAT?.percentage}</td> */}
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{UnAttendedMailsWithinTAT + UnAttendedMailsOutsideTAT }</td>
                      </tr>
                      
  
                    </tbody>
                  </table> :<div className="text-center">No Data Found</div>
                  }
               
              </div>

                   </Col>

                  <Col xs={12} sm={12} md={12} lg={8} xxl={8}>
                  <div>
                  {
                    isNoData!=="nodata" ?   <table className="table table-bodered">
               
                    <thead>
                    <tr>
                    <th colspan="1">Attended Mails</th>
                    {/* <th></th> */}
                    <th> {(OpenCasesWithinTAT + OpenCasesOutsideTAT) + (ClosedCasesWithinTAT + ClosedCasesOutsideTAT)}</th>
                    {/* <th> {UserClosedCasesWithinTAT?.count + UserClosedCasesOutsideTAT?.count }</th> */}
                    </tr>
                      <tr>
                      <th>Open</th>
                      <th></th>
                      {/* <th></th> */}
                      {/* <th colSpan={1}>  {UserClosedCasesWithinTAT?.count + UserClosedCasesOnTAT?.count + UserClosedCasesOutsideTAT?.count }  </th>
                      <th>%</th> */}
                      </tr>
  
                    </thead>
                    <tbody>
                      <tr>
                        <td>Within TAT</td>
                        <td>{OpenCasesWithinTAT}</td>
                        {/* <td>{UserClosedCasesWithinTAT?.percentage}</td> */}
                      </tr>
                      {/* <tr>
                        <td>On TAT</td>
                        <td>{UserClosedCasesOnTAT?.count}</td>
                        <td>{UserClosedCasesOnTAT?.percentage}</td>
                      </tr> */}
                      <tr>
                        <td>Outside TAT</td>
                        <td>{OpenCasesOutsideTAT}</td>
                        {/* <td>{UserClosedCasesOutsideTAT?.percentage}</td> */}
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{OpenCasesWithinTAT + OpenCasesOutsideTAT } </td>
                      </tr>
  
                      
  
                    </tbody>
                  </table> :<div className="text-center">No Data Found</div>}
              
              </div>
              </Col>

              <Col xs={12} sm={12} md={12} lg={8} xxl={8}>
                  <div>

                  {
                    isNoData!=="nodata" ?   <table className="table table-bodered">
               
                    <thead>
                    <tr>
                    <th colspan="1" style={{height: "37px"}}></th>
                    <th></th>
                    {/* <th> {UserClosedCasesWithinTAT?.count + UserClosedCasesOutsideTAT?.count + UserClosedCasesWithinTAT?.count + UserClosedCasesOutsideTAT?.count }</th> */}
                    </tr>
                      <tr>
                      <th>Closed</th>
                      <th></th>
                      {/* <th></th> */}
                      {/* <th colSpan={1}>  {UserClosedCasesWithinTAT?.count + UserClosedCasesOnTAT?.count + UserClosedCasesOutsideTAT?.count }  </th>
                      <th>%</th> */}
                      </tr>
  
                    </thead>
                    <tbody>
                      <tr>
                        <td>Within TAT</td>
                        <td>{ClosedCasesWithinTAT}</td>
                        {/* <td>{UserClosedCasesWithinTAT?.percentage}</td> */}
                      </tr>
                      {/* <tr>
                        <td>On TAT</td>
                        <td>{UserClosedCasesOnTAT?.count}</td>
                        <td>{UserClosedCasesOnTAT?.percentage}</td>
                      </tr> */}
                      <tr>
                        <td>Outside TAT</td>
                        <td>{ClosedCasesOutsideTAT}</td>
                        {/* <td>{UserClosedCasesOutsideTAT?.percentage}</td> */}
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{ClosedCasesWithinTAT + ClosedCasesOutsideTAT } </td>
                      </tr>
  
                      
  
                    </tbody>
                  </table> :<div className="text-center">No Data Found</div>}
              
              </div>
              </Col>


              {/* <Col xs={12} sm={12} md={12} lg={8} xxl={8}>
                <div>

                {
                    isNoData!=="nodata" ?  <table className="table table-bodered">
                 
                    <thead>
                      <tr >
                      <th>Pending Tickets  </th>
                      <th colSpan={1}>  {UserPendingCasesWithinTAT?.count + UserPendingCasesOnTAT?.count + UserPendingCasesOutsideTAT?.count }  </th>
                      <th>%</th>
                      </tr>
                     
               
                    </thead>
                    <tbody>
                      <tr>
                        <td>Within TAT</td>
                        <td>{UserPendingCasesWithinTAT?.count}</td>
                        <td>{UserPendingCasesWithinTAT?.percentage}</td>
                      </tr>
                      <tr>
                        <td>Outside TAT</td>
                        <td>{UserPendingCasesOutsideTAT?.count}</td>
                        <td>{UserPendingCasesOutsideTAT?.percentage}</td>
                      </tr>
                      
  
                    </tbody>
                  </table> :<div className="text-center">No Data Found</div>
                  }
               
              </div>

                   </Col> */}

           
              </Row>
    <Row gutter={[24, 24]} className="mb-16 mt-5">
      {/* <Col xs={24} sm={12} md={12} lg={12} xxl={12}> */}
      <Col xs={24} sm={6} md={6} lg={6} xxl={6}>
      
        <div>
          <Form.Item
            label={
              <span>
                From Date
                <sup>*</sup>
              </span>
            }
            name="FormDate"
            className="inputs-label mb-0"
            rules={[
              {
                required: true,
                message: "Select From Date",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              className="cust-input"
              format={dateFormat}
            />
          </Form.Item>
        </div>
      </Col>
      {/* <Col xs={24} sm={12} md={12} lg={12} xxl={12}> */}
      <Col xs={24} sm={6} md={6} lg={6} xxl={6}>
        <div>
          <Form.Item
            label={
              <span>
                To Date
                <sup>*</sup>
              </span>
            }
            name="ToDate"
            className="inputs-label mb-0"
            rules={[
              {
                required:true,
                message: "Select To Date",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              className="cust-input"
              format={dateFormat}
            />
          </Form.Item>
        </div>
      </Col>

      <Col xs={24} sm={6} md={6} lg={6} xxl={6}>
        <div>
          <Form.Item
            label={
              <span>
                Policy No / Email ID
                <sup>*</sup>
              </span>
            }
            name="policyNo"
            className="inputs-label mb-0"
            // rules={[
            //   {
            //     required: true,
            //     message: "Select To Date",
            //   },
            // ]}
          >
            <Input
              placeholder="Policy No / Email ID"  
                    />
          </Form.Item>
        </div>
      </Col>

      {/* <Col xs={24} sm={12} md={12} lg={12} xxl={12}> */}
      <Col xs={24} sm={12} md={6} lg={6} xxl={8}>
      <Button style={{display:'inline-block'}}
            type="primary"
            className="primary-btn "
            htmlType="submit"
            // Call the searchData function when the button is clicked
          >
            Search
          </Button>
        {/* <div>
          <Form.Item
            label={
              <span>
                To Date
                <sup>*</sup>
              </span>
            }
            name="ToDate"
            className="inputs-label mb-0"
            rules={[
              {
                required: true,
                message: "Select To Date",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              className="cust-input"
              format={dateFormat}
            />
          </Form.Item>
        </div> */}
      </Col>

      {/* <div style={{textAlign:'center', width:'100%'}}> 
       <br/>     <br/>
          <Button style={{display:'inline-block'}}
            type="primary"
            className="primary-btn "
            htmlType="submit"
            // Call the searchData function when the button is clicked
          >
            Search
          </Button>
     
        </div> */}
    </Row>
  </Form>
</Card>
</Col>
                </Row>
              


              </div>
          <div className="table-container table-responsive email-managedashboard">
            <Table
              columns={columns}
              dataSource={filtersData}
              x={true}
              pagination={{
                pageSize: 7,
                defaultPageSize: 7,
                total: { showTotalPages },
              }}
            />
          </div>
          </Spin>
        </Col>


        {/* <Col xs={24} sm={24} md={6} lg={6} xxl={6}>
        <div className="filters-right">
          {" "}
          <Form
          form={form}
          name="wrap"
          labelCol={{
            flex: "35%",
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
          onFinish={handleSubmit}
          autoComplete="off"
        >
      
            <div className="email-filters">



<Space direction="horizontal" style={{ marginBottom: "0px" }}>
              <Card
                      style={{ width: 350 }}
                      hoverable={true}
                      className="mb-10 pt-10"
                    >

      
                   <div style={{ display: 'flex', alignItems: 'center' }}>
                       <div className="w-95">
                       <p> <a style={{ textDecoration: "underline !important" }}  onClick={() => restAll()}>Reset All </a></p>
                  </div>
          
                  <Button
                      type="primary"
                      htmlType="submit"
                      className="primary-btn advance-btn"
                     
                    >
                     Search <SearchOutlined />
                    </Button>
         
                   </div>
          

                    </Card>
                    </Space>



                    <Card
                      style={{ width: 350 }}
                      hoverable={true}
                      className="mb-10 pt-10"
                    >

<Form.Item label="Email Source" name="emailSource" className="inputs-label mb-0">

  
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div className="w-95">
      <Select
        showSearch
        onSearch={onSearch}
        className="cust-input calltype-select"
        maxLength={100}
        placeholder="Select Email Source"
        options={emailSource}
        name="emailSource" 
        onChange={(value) => {
          form.setFieldsValue({ emailSource: value });
        }}
      ></Select>
    </div>
    <div className="">
      <img className="close-icon" src={CloseIcon} alt="" onClick={() => clearValues('emailSource')}></img>
    </div>
  </div>
</Form.Item>

                    </Card>
                 
                    <Card style={{ width: 350 }} hoverable={true} className="mb-10 pt-10">
                    <Form.Item label="Received On"   name="ReceivedToDt" className="inputs-label mb-0" style={{ display: "none" }}></Form.Item>
  <Form.Item label="Received On"   name="ReceivedFromDt" className="inputs-label mb-0">
    <div style={{ display: 'flex', alignItems: 'center' }}>
    <div className="w-95">
      <DatePicker   onChange={(value) => {
          form.setFieldsValue({ ReceivedFromDt: value });
        }} name="ReceivedFromDt" style={{ marginRight: 8 }} />
      <DatePicker    onChange={(value) => {
          form.setFieldsValue({ ReceivedToDt: value });
        }} name="ReceivedToDt" style={{ marginRight: 8 }} />
      
    </div>
    <div> 
                <img className="close-icon" src={CloseIcon} onClick={() => clearValues('ReceivedFromDt')} alt=""></img></div>
                   </div>
 
  </Form.Item>
</Card>


                    <Card
                      style={{ width: 350 }}
                      hoverable={true}
                      className="mb-10 pt-10"
                    >

                     <Form.Item
                   label="NLP Response"
                  name="IsNLPRespGen"
                  className="inputs-label mb-0"
                >
                   <div style={{ display: 'flex', alignItems: 'center' }}>
                   <div className="w-95">
            

    <Switch
                      checked={NLPResponseChecked}
                      onChange={ (checked) => {setNLPResponseChecked(checked); form.setFieldsValue({ IsNLPRespGen: NLPResponseChecked })}}
                      checkedChildren="Generated"
                      unCheckedChildren="Ignore"
                    />


    </div> 
    <div> 
                <img className="close-icon" src={CloseIcon} onClick={() => clearValues('IsNLPRespGen')} alt=""></img></div>
                   </div>
                </Form.Item>

                    </Card>



                    <Card style={{ width: 350 }} hoverable={true} className="mb-10 pt-10">
                      <Form.Item label="Q / R / C" name="Category" className="inputs-label mb-0">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="w-95">
                           <span title="Query"> Q <Checkbox 
                                  onChange={(event)=> onChangeCategory(event, 'Q')}

                           label="Query"  name="query" className="inputs-label mb-0 mr-15 pr-2" /></span>
                           <span title="Request">  R<Checkbox  onChange={(event)=> onChangeCategory(event, 'R')} label="Request" name="request" className="inputs-label mb-0 mr-15 pr-2" /></span>
                           <span title="Complaint"> C<Checkbox  onChange={(event)=> onChangeCategory(event, 'C')} label="Complaint" name="complaint" className="inputs-label mb-0 mr-15 pr-2" /></span>
                          </div>
                          <div>
                            <img className="close-icon" onClick={() => clearValues('Category')} src={CloseIcon} alt="" />
                          </div>
                        </div>
                      </Form.Item>
                    </Card>




                    <Card
                      style={{ width: 350 }}
                      hoverable={true}
                      className="mb-10 pt-10"
                    >

                     <Form.Item
                   label="Addressed to"
                  name="AddressedToMultipleIDs"
                  className="inputs-label mb-0"
                >
                   <div style={{ display: 'flex', alignItems: 'center' }}>
                 <div className="w-95">
       
    
    <Switch
                      checked={Addressedto}
                      onChange={ (checked) => {setAddressedto(checked); form.setFieldsValue({ AddressedToMultipleIDs: Addressedto })}}
                      checkedChildren="Multiple"
                      unCheckedChildren="Ignore"
                    />
    </div> 
    <div>
                <img className="close-icon" src={CloseIcon} onClick={() => clearValues('AddressedToMultipleIDs')}  alt=""></img></div> 
                   </div>
                </Form.Item>

                    </Card>
                 






                    <Card
                      style={{ width: 350 }}
                      hoverable={true}
                      className="mb-10 pt-10"
                    >
    <Form.Item
                   label="Call Type"
                  name="CallType"
                  className="inputs-label mb-16"
                >
                   <div style={{ display: 'flex', alignItems: 'center' }}>
                 <div className="w-95"> 
                  <Select
                    showSearch
                    onSearch={onSearch}
                    className="cust-input calltype-select"
                    maxLength={100}
                    placeholder="Select Call Type"
                    options={ callTypeLU}
                    filterOption={filterOption}
                 

                    onChange={(value) => {handleCallTypeChange(value);
                      form.setFieldsValue({ CallType: value });
                    }}
                  ></Select>
            </div>
            <div>
                <img className="close-icon" onClick={() => clearValues('CallType')} src={CloseIcon} alt=""></img></div> 
                
            </div>
            </Form.Item>
            <Form.Item
                   label="Sub Type"
                  name="SubType"
                  className="inputs-label mb-16"
                >
                   <div style={{ display: 'flex', alignItems: 'center' }}>
                 <div className="w-95"> 
                  <Select
                    showSearch
                    onSearch={onSearch}
                    className="cust-input calltype-select"
                    maxLength={100}
                    placeholder="Select Sub Type"
                    options={subTypeLU}
                    filterOption={filterOption}
                 

                    
                    onChange={(value) => {handleSubTypeChange(value);
                      form.setFieldsValue({ SubType: value });
                    }}
                  ></Select>
                  </div>
                  <div>
                <img className="close-icon" onClick={() => clearValues('SubType')} src={CloseIcon} alt=""></img></div> 
                
        
                  </div>
                </Form.Item>

                    </Card>

                    <Card
                      style={{ width: 350 }}
                      hoverable={true}
                      className="mb-10 pt-10"
                    >

                     <Form.Item
                   label="Assigned To"
                  name="AssignedTo"
                  className="inputs-label mb-0"
                >
                   <div style={{ display: 'flex', alignItems: 'center' }}>
                       <div className="w-95">
                  <Select
                    showSearch
                    onSearch={onSearch}
                    className="cust-input calltype-select"
                    maxLength={100}
                    placeholder="Select Call Type"
                    options={ emailSource}
                    filterOption={filterOption}
                    onChange={(e) => handleCallTypeChange(e)}
                  ></Select>
                  </div>
                  <div className="">
                   <img className="close-icon" onClick={() => clearValues('AssignedTo')} src={CloseIcon} alt=""></img>
                   </div>
                   </div>
                </Form.Item>

                    </Card>




            </div>
          </Form>
        </div>
        </Col> */}
        </Row>
      </div>
    </>
  );
};

export default EmailManagement;